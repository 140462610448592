import { IConversationEvent } from 'app/shared/model/conversation-event.model';
import { IConversationSummary } from 'app/shared/model/conversation-summary.model';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import Axios from 'axios';

const botNamesMap = new Map([
  [1, 'content/images/general-questions-icon.svg'],
  [2, 'content/images/medical-questions-icon.svg'],
  [3, 'content/images/quality-of-life-icon.svg'],
  [4, 'content/images/act-questions-icon.svg'],
  [5, 'content/images/Diabetes.svg'],
  [6, 'content/images/Diabetes.svg'],
  [7, 'content/images/Diabetes.svg'],
  [8, 'content/images/Diabetes.svg'],
  [9, 'content/images/Diabetes.svg'],
  [10, 'content/images/Diabetes.svg'],
  [11, 'content/images/Asthma.svg'],
  [12, 'content/images/Asthma.svg'],
  [13, 'content/images/Asthma.svg'],
  [14, 'content/images/Asthma.svg'],
  [15, 'content/images/Asthma.svg'],
  [16, 'content/images/Asthma.svg']
  // [17, 'content/images/Vaccination.svg'],
  // [18, 'content/images/Vaccination.svg'],
  // [19, 'content/images/Vaccination.svg'],
  // [20, 'content/images/Vaccination.svg']
]);

export const ACTION_TYPES = {
  CHATBOT_RESET: 'chatbotReducer/CHATBOT_RESET',
  CHATBOT_GET_BOTS: 'chatbotReducer/CHATBOT_GET_BOTS',
  CHATBOT_GET_CONVERSATION_HISTORY: 'chatbotReducer/CHATBOT_GET_CONVERSATION',
  CHATBOT_SEND_MESSAGE: 'chatbotReducer/CHATBOT_SEND_MESSAGE',
  SELECT_CHATBOT: 'chatbotReducer/SELECT_CHATBOT'
};

interface QuickReply {
  replyId: number;
  replyType: string;
  quickReply: string;
}

interface NextConversationMessage {
  conversationId: number;
  conversationType: string;
  messageId: number;
  messageText: string;
  messageType: string;
  appointmentId: number;
  quickReplies: QuickReply[];
}

export type ChatbotState = {
  selectedBot: IConversationSummary;

  bots: IConversationSummary[];
  conversationHistory: IConversationEvent[];
  currentMessage: IConversationEvent;

  loading: boolean;
  errorMessage: string;
  updateSuccess: boolean;
};

const initialState = {
  selectedBot: {},
  bots: [],
  conversationHistory: [],
  currentMessage: {},
  loading: false,
  errorMessage: null,
  updateSuccess: false
};

export default (state: ChatbotState = initialState, action): ChatbotState => {
  switch (action.type) {
    case ACTION_TYPES.CHATBOT_RESET:
      return {
        ...state,
        bots: [],
        currentMessage: null,
        selectedBot: null,
        errorMessage: null,
        updateSuccess: false
      };

    case ACTION_TYPES.SELECT_CHATBOT:
      return {
        ...state,
        selectedBot: action.payload
      };
    case REQUEST(ACTION_TYPES.CHATBOT_SEND_MESSAGE):
    case REQUEST(ACTION_TYPES.CHATBOT_GET_CONVERSATION_HISTORY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case ACTION_TYPES.CHATBOT_GET_BOTS:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        updateSuccess: true,
        bots: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CHATBOT_SEND_MESSAGE):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        updateSuccess: true,
        currentMessage: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CHATBOT_GET_CONVERSATION_HISTORY):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        updateSuccess: true,
        conversationHistory: action.payload.data
      };
    case FAILURE(ACTION_TYPES.CHATBOT_SEND_MESSAGE):
    case FAILURE(ACTION_TYPES.CHATBOT_GET_BOTS):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        errorMessage: action.payload.data
      };
    default:
      return state;
  }
};

export const reset = () => ({
  type: ACTION_TYPES.CHATBOT_RESET
});

export const updateSelectedBot = (selectedBot: IConversationSummary) => dispatch => {
  dispatch({
    type: ACTION_TYPES.SELECT_CHATBOT,
    payload: selectedBot
  });
};

export const getBotsWithName = () => async () => {
  const requestUrl = `/api/patient/conversations-summary`;
  const botsList = await Axios.get<IConversationSummary[]>(`${requestUrl}`);

  botsList.data.forEach((bot: IConversationSummary) => (bot.svgIcon = botNamesMap.get(bot.conversationTypeId)));

  return botsList;
};

export const getBots = () => async dispatch => {
  const botsList = await dispatch(getBotsWithName());

  await dispatch({
    type: ACTION_TYPES.CHATBOT_GET_BOTS,
    payload: botsList
  });
};

export const getConversation = (conversationId: number) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.CHATBOT_GET_CONVERSATION_HISTORY,
    payload: Axios.get<IConversationEvent[]>(`/api/patient/conversation-events/${conversationId}`)
  });
};

export const sendMessage = (messageEvent: IConversationEvent) => async dispatch => {
  await dispatch({
    type: ACTION_TYPES.CHATBOT_SEND_MESSAGE,
    payload: Axios.post<IConversationEvent>('/api/patient/message', messageEvent)
  });
};
