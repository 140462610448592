export interface IInternalApiLogs {
  id?: number;
  apiName?: string;
  url?: string;
  request?: string;
  responses?: string;
  error?: string;
  status?: string;
  source?: string;
}

export const defaultValue: Readonly<IInternalApiLogs> = {};
