import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getAllEntites as getClinics } from 'app/entities/clinic/clinic.reducer';
import { getEntity, updateEntity, createEntity, reset } from './healthcare-professional.reducer';
import { mapIdList } from 'app/shared/util/entity-utils';
import { LanguageCode } from '../../shared/languages/language-options';

export interface IHealthcareProfessionalUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const HealthcareProfessionalUpdate = (props: IHealthcareProfessionalUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  
  const { healthcareProfessionalEntity, clinics, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/healthcare-professional' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getClinics();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  
  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...healthcareProfessionalEntity,
        ...values,
        clinics: mapIdList(values.clinics)
      };
  
      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="4" sm={8}>
          <h2 id="diga1App.healthcareProfessional.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "diga1App.healthcareProfessional.home.createLabel"
              : "diga1App.healthcareProfessional.home.editLabel"}
            >
              Create or edit a healthcareProfessional
              </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="4" sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <AvForm model={isNew ? {} : healthcareProfessionalEntity} onSubmit={saveEntity}>
                <AvGroup>
                  <Label id="salutationLabel" for="healthcare-professional-salutation">
                    <Translate contentKey="diga1App.healthcareProfessional.salutation">Salutation</Translate>
                  </Label>
                  <AvField
                    id="healthcare-professional-salutation"
                    type="select"
                    className="form-control"
                    name="salutation"
                    value={(!isNew && healthcareProfessionalEntity.salutation) || translate('diga1App.salutation.mr')}
                    validate={{
                      maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) }
                    }}
                  >
                    <option value={translate('diga1App.salutation.mr')}>{translate('diga1App.salutation.mr')}</option>
                    <option value={translate('diga1App.salutation.ms')}>{translate('diga1App.salutation.ms')}</option>
                  </AvField>
                </AvGroup>
                <AvGroup>
                  <Label id="titleLabel" for="healthcare-professional-title">
                    <Translate contentKey="diga1App.healthcareProfessional.title">Title</Translate>
                  </Label>
                  <AvField
                    id="healthcare-professional-title"
                    type="text"
                    name="title"
                    validate={{
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="firstNameLabel" for="healthcare-professional-firstName">
                    <Translate contentKey="diga1App.healthcareProfessional.firstName">First Name</Translate>
                  </Label>
                  <AvField
                    id="healthcare-professional-firstName"
                    type="text"
                    name="firstName"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="lastNameLabel" for="healthcare-professional-lastName">
                    <Translate contentKey="diga1App.healthcareProfessional.lastName">Last Name</Translate>
                  </Label>
                  <AvField
                    id="healthcare-professional-lastName"
                    type="text"
                    name="lastName"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="genderLabel" for="healthcare-professional-gender">
                    <Translate contentKey="diga1App.healthcareProfessional.gender">Gender</Translate>
                  </Label>
                  <AvInput
                    id="healthcare-professional-gender"
                    type="select"
                    className="form-control"
                    name="gender"
                    value={(!isNew && healthcareProfessionalEntity.gender) || translate('diga1App.gender.male')}
                  >
                    <option value={translate('diga1App.gender.male')}>{translate('diga1App.gender.male')}</option>
                    <option value={translate('diga1App.gender.female')}>{translate('diga1App.gender.female')}</option>
                    <option value={translate('diga1App.gender.other')}>{translate('diga1App.gender.other')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="langKeyLabel" for="healthcare-professional-langKey">
                    <Translate contentKey="diga1App.healthcareProfessional.langKey">Lang Key</Translate>
                  </Label>
                  <AvInput
                    id="healthcare-professional-langKey"
                    type="select"
                    name="langKey"
                    value={(!isNew && healthcareProfessionalEntity.langKey) || LanguageCode.GERMAN}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                      maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                    }}
                  >
                    <option value={LanguageCode.GERMAN}>{translate('global.form.languageKey.german')}</option>
                    <option value={LanguageCode.ENGLISH}>{translate('global.form.languageKey.english')}</option>
                    <option value={LanguageCode.TURKISH}>{translate('global.form.languageKey.turkish')}</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="emailLabel" for="healthcare-professional-email">
                    <Translate contentKey="diga1App.healthcareProfessional.email">Email</Translate>
                  </Label>
                  <AvField
                    id="healthcare-professional-email"
                    type="email"
                    name="email"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="mobileNumberLabel" for="healthcare-professional-mobileNumber">
                    <Translate contentKey="diga1App.healthcareProfessional.mobileNumber">Mobile Number</Translate>
                  </Label>

                  <AvField
                    id="healthcare-professional-mobileNumber"
                    type="text"
                    placeholder="Contry Code and mobile number"
                    name="mobileNumber"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      pattern: {
                        value: '^([0-9]{2,})$',
                        errorMessage: translate('global.messages.validate.mobileNumber.invalidPattern')
                      },
                      maxLength: { value: 20, errorMessage: translate('global.messages.validate.mobileNumber.maxlength', { max: 20 }) }
                    }}
                  />

                </AvGroup>
                <AvGroup>
                  <Label id="registrationNumberLabel" for="healthcare-professional-registrationNumber">
                    <Translate contentKey="diga1App.healthcareProfessional.registrationNumber">Registration Number</Translate>
                  </Label>
                  <AvField
                    id="healthcare-professional-registrationNumber"
                    type="text"
                    name="registrationNumber"
                    validate={{
                      required: { value: true, errorMessage: translate('global.messages.validate.registrationNumber.required') },
                      pattern: {
                        value: '^([0-9]{1,9})$',
                        errorMessage: translate('global.messages.validate.registrationNumber.invalid')
                      },
                      minlength: { value: 9, errorMessage: translate('global.messages.validate.registrationNumber.minlength') },
                      maxLength: { value: 9, errorMessage: translate('global.messages.validate.registrationNumber.maxlength') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="verificationStatusLabel" for="healthcare-professional-verificationStatus">
                    <Translate contentKey="diga1App.healthcareProfessional.verificationStatus">Verification Status</Translate>
                  </Label>
                  <AvField
                    id="healthcare-professional-verificationStatus"
                    type="select"
                    name="verificationStatus"
                    value={(!isNew && healthcareProfessionalEntity.verificationStatus) || translate('diga1App.verification.notVerified')}
                  >
                    <option value={translate('diga1App.verification.verified')}>{translate('diga1App.verification.verified')}</option>
                    <option value={translate('diga1App.verification.notVerified')}>{translate('diga1App.verification.notVerified')}</option>
                  </AvField>
                </AvGroup>
                <AvGroup>
                  <Label for="healthcare-professional-clinic">
                    <Translate contentKey="diga1App.healthcareProfessional.clinic">Clinic</Translate>
                  </Label>
                  <AvInput
                    id="healthcare-professional-clinic"
                    type="select"
                    multiple
                    className="form-control"
                    name="clinics"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                    }}
                    value={healthcareProfessionalEntity.clinics && healthcareProfessionalEntity.clinics.map(e => e.id)}
                  >
                    <option value="" key="0" />
                    {clinics
                      ? clinics.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/healthcare-professional" replace className="rounded" color="success" >
                  <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              &nbsp;
                <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clinics: storeState.clinic.entities,
  healthcareProfessionalEntity: storeState.healthcareProfessional.entity,
  loading: storeState.healthcareProfessional.loading,
  updating: storeState.healthcareProfessional.updating,
  updateSuccess: storeState.healthcareProfessional.updateSuccess
});

const mapDispatchToProps = {
  getClinics,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareProfessionalUpdate);
