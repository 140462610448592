import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { generatePDF, PDFFILES } from 'app/pages/medical-assistant-dashboard/shard/pdfUtils';

const accountMenuItemsAuthenticated = (
  <>
    <MenuItem icon="lock" to="/account/password">
      <Translate contentKey="global.menu.account.password">Password</Translate>
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout">
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuItem>
  </>
);

const medicalAssistantMenuItemsAuthenticated = (
  <>
    <MenuItem icon="wrench" to="/medical-assitant/edit-personal-details">
      <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </MenuItem>
    <MenuItem icon="lock" to="/account/password">
      <Translate contentKey="global.menu.account.password">Password</Translate>
    </MenuItem>
    <MenuItem className="user-guide" icon="info-circle" to="/ma-dashboard" onClick={() => generatePDF(PDFFILES.USER_GUIDE_DE)}>
      <Translate contentKey="global.menu.account.userGuide">User Guide</Translate>
    </MenuItem>
    <MenuItem icon="sign-out-alt" to="/logout">
      <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </MenuItem>
  </>
);

const accountMenuItems = (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login">
      <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </MenuItem>
  </>
);

export const AccountMenu = ({ isAuthenticated = false, isMFA }) => (
  <NavDropdown name={translate('global.menu.account.main')} id="account-menu">
    {isAuthenticated && !isMFA
      ? accountMenuItemsAuthenticated
      : isAuthenticated && isMFA
      ? medicalAssistantMenuItemsAuthenticated
      : accountMenuItems}
  </NavDropdown>
);

export default AccountMenu;
