import React, { useEffect, useState } from 'react';
import { IAppointment } from 'app/shared/model/appointment.model';
import { updateSelectedAppointment, updateSelectedPatient } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import { APPOINTMENT_CONFIRMATION } from '../appointment-constants';
import { deleteEntity } from 'app/entities/appointment/appointment.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate, Translate } from 'react-jhipster';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

interface PatientAppointmentDetailsProps extends DispatchProps, StateProps {
    patientAppointmentDetails: IAppointment;
    isShowDate: boolean;
    handleRefreshAppointment(): void;
}

const PatientAppointmentRow = (props: PatientAppointmentDetailsProps): any => {
    const appointment = props.patientAppointmentDetails;
    const [isToOpenDeleteModal, setOpenModal] = useState(false);
    const history = useHistory();


    const handleClose = () => {
        setOpenModal(false);
        props.handleRefreshAppointment();
    };

    useEffect(() => {
        if (props.updateSuccess) {
            handleClose();
        }
    }, [props.updateSuccess]);

    const confirmDelete = () => {
        props.deleteEntity(appointment.id);

    };

    const updateAppointment = () => {
        props.updateSelectedAppointment(appointment)
        history.push(`/ma-dashboard/MFA-Patient-Appointment-Change`)
    }

    return (
        <tr style={{ textAlign: "center" }}>
            <td>
                {`${appointment.patient.lastName}`}
            </td>
            <td>
                {`${appointment.patient.firstName}`}
            </td>
            <td>
                {`${appointment.healthcareProfessional.firstName} ${appointment.healthcareProfessional.lastName}`}
            </td>
            <td>
                {new Date(appointment.date).toLocaleDateString(props.currentLocale, { timeZone: 'Europe/Berlin', year: 'numeric', month: '2-digit', day: '2-digit' })}
            </td>
            <td>
                {`${moment.tz(moment(appointment.date), 'Europe/Berlin').format('HH:mm')}`}
            </td>
            <td style={{
                color: appointment.confirmed === APPOINTMENT_CONFIRMATION.CONFIRMED ?
                    'green' :
                    appointment.confirmed === APPOINTMENT_CONFIRMATION.DECLINED ?
                        'red' : '#e6b800'

            }}>
                {`${appointment.confirmed === APPOINTMENT_CONFIRMATION.UNCONFIRMED ?
                    translate('diga1App.appointment.unconfirmed') : appointment.confirmed === APPOINTMENT_CONFIRMATION.DECLINED ?
                        translate('diga1App.appointment.decline') : translate('diga1App.appointment.confirmed')}`}
            </td>
            <td>
                <a onClick={() => updateAppointment()}
                    style={{ textDecoration: 'underline', marginRight: '0.5rem' }}>
                    <Translate contentKey="entity.action.edit">Edit</Translate>
                </a>
                <a onClick={() => { setOpenModal(true) }}
                    style={{ textDecoration: 'underline' }}>
                    <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                    </span>
                </a>
            </td>
            {isToOpenDeleteModal && (
                <Modal isOpen toggle={handleClose}>
                    <ModalHeader toggle={handleClose}>
                        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
                    </ModalHeader>
                    <ModalBody id="diga1App.appointment.delete.question">
                        <Translate contentKey="diga1App.appointment.delete.question" interpolate={{ id: appointment.id }}>
                            Are you sure you want to delete this Appointment?
              </Translate>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setOpenModal(false)}>
                            <FontAwesomeIcon icon="ban" />
                &nbsp;
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>
                        <Button id="jhi-confirm-delete-appointment" color="danger" onClick={confirmDelete}>
                            <FontAwesomeIcon icon="trash" />
                &nbsp;
                <Translate contentKey="entity.action.delete">Delete</Translate>
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </tr>

    );
};

const mapStateToProps = (storeState: IRootState) => ({
    currentLocale: storeState.locale.currentLocale,
    updateSuccess: storeState.appointment.updateSuccess
});

const mapDispatchToProps = {
    updateSelectedAppointment,
    updateSelectedPatient,
    deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientAppointmentRow);

