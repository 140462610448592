import './home.scss';

import React from 'react';
import { HomeHeaderRow } from './HomeHeaderRow';

export const Contact = () => {
  return <HomeHeaderRow isContact />;
};

export default Contact;
