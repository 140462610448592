import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getClinics } from 'app/entities/clinic/clinic.reducer';
import { getEntity, updateEntity, createEntity, reset } from './self-registration.reducer';
import { mapIdList } from 'app/shared/util/entity-utils';
import { LanguageCode } from 'app/shared/languages/language-options';

export interface ISelfRegistrationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const SelfRegistrationUpdate = (props: ISelfRegistrationUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { selfRegistrationEntity, clinics, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/self-registration' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getClinics();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...selfRegistrationEntity,
        ...values,
        clinics: mapIdList(values.clinics),
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="diga1App.selfRegistration.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "diga1App.selfRegistration.home.createLabel"
              : "diga1App.selfRegistration.home.editLabel"}
            >
              Create or edit a Patient
              </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="4" sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : selfRegistrationEntity} onSubmit={saveEntity}>
              <AvGroup>
                <Label id="firstNameLabel" for="self-registration-firstName">
                  <Translate contentKey="diga1App.selfRegistration.firstName">First Name</Translate>
                </Label>
                <AvField
                  id="self-registration-firstName"
                  type="text"
                  name="firstName"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastNameLabel" for="self-registration-lastName">
                  <Translate contentKey="diga1App.selfRegistration.lastName">Last Name</Translate>
                </Label>
                <AvField
                  id="self-registration-lastName"
                  type="text"
                  name="lastName"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="genderLabel" for="self-registration-gender">
                  <Translate contentKey="diga1App.selfRegistration.gender">Gender</Translate>
                </Label>
                <AvInput
                  id="self-registration-gender"
                  type="select"
                  className="form-control"
                  name="gender"
                  value={(!isNew && selfRegistrationEntity.gender) || translate('diga1App.gender.male')}
                >
                  <option value={translate('diga1App.gender.male')}>{translate('diga1App.gender.male')}</option>
                  <option value={translate('diga1App.gender.female')}>{translate('diga1App.gender.female')}</option>
                  <option value={translate('diga1App.gender.other')}>{translate('diga1App.gender.other')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="emailLabel" for="self-registration-email">
                  <Translate contentKey="diga1App.selfRegistration.email">Email</Translate>
                </Label>
                <AvField
                  id="self-registration-email"
                  type="email"
                  name="email"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="mobileNumberLabel" for="self-registration-mobileNumber">
                  <Translate contentKey="diga1App.selfRegistration.mobileNumber">Mobile Number</Translate>
                </Label>
                <AvField
                  id="self-registration-mobileNumber"
                  type="text"
                  name="mobileNumber"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    pattern: {
                      value: '^([0-9]{2,})$',
                      errorMessage: translate('global.messages.validate.mobileNumber.invalidPattern')
                    },
                    maxLength: { value: 20, errorMessage: translate('global.messages.validate.mobileNumber.maxlength', { max: 20 }) }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="langKeyLabel" for="self-registration-langKey">
                  <Translate contentKey="diga1App.selfRegistration.langKey">Lang Key</Translate>
                </Label>
                <AvInput
                  id="self-registration-langKey"
                  type="select"
                  name="langKey"
                  value={(!isNew && selfRegistrationEntity.langKey) || LanguageCode.GERMAN}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                    maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                  }}
                >
                  <option value={LanguageCode.GERMAN}>{translate('global.form.languageKey.german')}</option>
                  <option value={LanguageCode.ENGLISH}>{translate('global.form.languageKey.english')}</option>
                  <option value={LanguageCode.TURKISH}>{translate('global.form.languageKey.turkish')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="birthdateLabel" for="self-registration-birthdate">
                  <Translate contentKey="diga1App.selfRegistration.birthdate">Birthdate</Translate>
                </Label>
                <AvField
                  id="self-registration-birthdate"
                  type="date"
                  className="form-control"
                  name="birthdate"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    dateRange: {
                      start: { value: -130, units: 'years', },
                      end: { value: -18, units: 'years', },
                      errorMessage: translate('global.messages.validate.birthdate.invalidAge')
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="reasonLabel" for="self-registration-reason">
                  <Translate contentKey="diga1App.selfRegistration.reason">Reason</Translate>
                </Label>
                <AvField id="self-registration-reason" type="text" name="reason" />
              </AvGroup>
              <AvGroup>
                <Label for="self-registration-clinic">
                  <Translate contentKey="diga1App.selfRegistration.clinic">Clinic</Translate>
                </Label>
                <AvInput
                  id="self-registration-clinic"
                  type="select"
                  multiple
                  className="form-control"
                  name="clinics"
                  value={selfRegistrationEntity.clinics && selfRegistrationEntity.clinics.map(e => e.id)}
                >
                  <option value="" key="0" />
                  {clinics
                    ? clinics.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/self-registration" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clinics: storeState.clinic.entities,
  selfRegistrationEntity: storeState.selfRegistration.entity,
  loading: storeState.selfRegistration.loading,
  updating: storeState.selfRegistration.updating,
  updateSuccess: storeState.selfRegistration.updateSuccess,
});

const mapDispatchToProps = {
  getClinics,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SelfRegistrationUpdate);
