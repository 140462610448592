import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TaskCatalogTr from './task-catalog-tr';
import TaskCatalogTrDetail from './task-catalog-tr-detail';
import TaskCatalogTrUpdate from './task-catalog-tr-update';
import TaskCatalogTrDeleteDialog from './task-catalog-tr-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TaskCatalogTrUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TaskCatalogTrUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TaskCatalogTrDetail} />
      <ErrorBoundaryRoute path={match.url} component={TaskCatalogTr} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={TaskCatalogTrDeleteDialog} />
  </>
);

export default Routes;
