import React from 'react';
import './ma-dashboard-button.css';
import { connect } from 'react-redux';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { IRootState } from 'app/shared/reducers';
import { MA_DASH_PAGE, URL_PREFIX, URL_SUFFIX } from '../../ma-dashboard-layout/pages-list';
import { NavDropdown } from '../../../../shared/layout/menus/menu-components';
import { Translate } from 'react-jhipster';
import { Nav, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom';

interface SideBarLinkBoxProps {
    svg: any;
    text: string;
}
export interface ISideBarLinkBoxProps extends StateProps, DispatchProps, SideBarLinkBoxProps { }

const MaDashboardButton = (props: ISideBarLinkBoxProps) => {
    return (
        <Row>
            <Col>
            <Link to={URL_PREFIX.MA_DASHBOARD + URL_SUFFIX.ADD_NEW_PATIENT}
                style={{ textDecoration: 'none' }}>
                <div id='ma-dashboard-button-link' className="ma-dashboard-button-box ma-dashboard-button-box-hover">
                    <img src={"content/images/add-new-patient.svg"} alt='icon' className="center"></img>
                    <p><Translate contentKey={MA_DASH_PAGE.NEW_PATIENT} /></p>
                </div>
            </Link>
            </Col>
            <Col>
            <Link to={URL_PREFIX.MA_DASHBOARD + URL_SUFFIX.ADD_EXISTING_PATIENT}
                style={{ textDecoration: 'none' }}>
                <div id='ma-dashboard-button-link' className="ma-dashboard-button-box ma-dashboard-button-box-hover">
                    <img src={"content/images/new-appointment.svg"} alt='icon' className="center"></img>
                    <p> <Translate contentKey={MA_DASH_PAGE.EXISTING_PATIENT} /></p>
                </div>
            </Link>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ maDashboardReducer }: IRootState) => {
    return {
    };
};


const mapDispatchToProps = {
    // callToReset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MaDashboardButton);
