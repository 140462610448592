import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './clinic.reducer';
import { IClinic } from 'app/shared/model/clinic.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IClinicDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ClinicDetail = (props: IClinicDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { clinicEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2>
            <Translate contentKey="diga1App.clinic.detail.title">Clinic</Translate> [<b>{clinicEntity.id}</b>]
      </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="name">
              <Translate contentKey="diga1App.clinic.name">Name</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.name}</dd>
          <dt>
            <span id="street">
              <Translate contentKey="diga1App.clinic.street">Street</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.street}</dd>
          <dt>
            <span id="houseNumber">
              <Translate contentKey="diga1App.clinic.houseNumber">House Number</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.houseNumber}</dd>
          <dt>
            <span id="city">
              <Translate contentKey="diga1App.clinic.city">City</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.city}</dd>
          <dt>
            <span id="zipCode">
              <Translate contentKey="diga1App.clinic.zipCode">Zip Code</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.zipCode}</dd>
          <dt>
            <span id="registrationNumber">
              <Translate contentKey="diga1App.clinic.registrationNumber">Registration Number</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.registrationNumber}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="diga1App.clinic.email">Email</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.email}</dd>
          <dt>
            <span id="phoneNumber">
              <Translate contentKey="diga1App.clinic.phoneNumber">Phone Number</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.phoneNumber}</dd>
          <dt>
            <span id="preferredCommunicationChannel">
              <Translate contentKey="diga1App.clinic.preferredCommunicationChannel">Preferred Communication Channel</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.preferredCommunicationChannel}</dd>
          <dt>
            <span id="mainContactEmail">
              <Translate contentKey="diga1App.clinic.mainContactEmail">Main Contact Email</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.mainContactEmail}</dd>
          <dt>
            <span id="verificationStatus">
              <Translate contentKey="diga1App.clinic.verificationStatus">Verification Status</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.verificationStatus}</dd>
          <dt>
            <span id="urlUuid">
              <Translate contentKey="diga1App.clinic.urlUuid">Url Uuid</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.urlUuid}</dd>
          <dt>
            <span id="authorization">
              <Translate contentKey="diga1App.clinic.authorization">Authorization</Translate>
            </span>
          </dt>
          <dd>{clinicEntity.authorization}</dd>
          <dt>
                <Translate contentKey="diga1App.clinic.medicalAssistant">Medical Assistant</Translate>
              </dt>
              <dd>
                {clinicEntity.medicalAssistants
                  ? clinicEntity.medicalAssistants.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.id}</a>
                      {i === clinicEntity.medicalAssistants.length - 1 ? '' : ', '}
                    </span>
                  ))
                  : null}
              </dd>
              <dt>
                <Translate contentKey="diga1App.clinic.healthcareProfessional">Healthcare Professional</Translate>
              </dt>
              <dd>
                {clinicEntity.healthcareProfessionals
                  ? clinicEntity.healthcareProfessionals.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.id}</a>
                      {i === clinicEntity.healthcareProfessionals.length - 1 ? '' : ', '}
                    </span>
                  ))
                  : null}
              </dd>
              <dt>
                <Translate contentKey="diga1App.clinic.patient">Patient</Translate>
              </dt>
              <dd>
                {clinicEntity.patients
                  ? clinicEntity.patients.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.id}</a>
                      {i === clinicEntity.patients.length - 1 ? '' : ', '}
                    </span>
                  ))
                  : null}
              </dd>
        </dl>
        <Button tag={Link} to="/clinic" replace className="rounded" color="success" >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/clinic/${clinicEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({clinic}: IRootState) => ({
          clinicEntity: clinic.entity,
});

const mapDispatchToProps = {getEntity};

type StateProps = ReturnType<typeof mapStateToProps>;
        type DispatchProps = typeof mapDispatchToProps;

        export default connect(mapStateToProps, mapDispatchToProps)(ClinicDetail);
