import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, getEntitiesWithFilters } from './user-device-info.reducer';
import { IUserDeviceInfo } from 'app/shared/model/user-device-info.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import SearchInputText from 'app/shared/input-text/search-input-text/search-input-text';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IUserDeviceInfoProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const UserDeviceInfo = (props: IUserDeviceInfoProps) => {
  const [filters, setFilters] = useState({
    userId: '',
    deviceId: '',
    token: '',
  });  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    const query = Object.keys(filters)
      .filter(k => filters[k] !== '')
      .map(k => `${k}.equals=${filters[k]}`)
      .join('&');
    props.getEntitiesWithFilters(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      query
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  useEffect(() => {
    sortEntities();
  }, [filters.userId, filters.deviceId, filters.token]); 

  const { userDeviceInfoList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="user-device-info-heading">
        <Translate contentKey="diga1App.userDeviceInfo.home.title">User Device Infos</Translate>
        <Link to={`${match.url}/new`} className="btn btn-outline-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.userDeviceInfo.home.createLabel">Create new User Device Info</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        {
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('userId')}>
                  <Translate contentKey="diga1App.userDeviceInfo.userId">User Id</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        userId: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('deviceId')}>
                  <Translate contentKey="diga1App.userDeviceInfo.deviceId">Device Id</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        deviceId: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('token')}>
                  <Translate contentKey="diga1App.userDeviceInfo.token">Token</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        token: text
                      });
                    }}
                    column
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!loading && userDeviceInfoList && userDeviceInfoList.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <div className="alert alert-warning">
                      <Translate contentKey="diga1App.userDeviceInfo.home.notFound">No User Device Infos found</Translate>
                    </div>
                  </td>
                </tr>
              )}
              {userDeviceInfoList.map((userDeviceInfo, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${userDeviceInfo.id}`} color="link" size="sm">
                      {userDeviceInfo.id}
                    </Button>
                  </td>
                  <td>{userDeviceInfo.userId}</td>
                  <td>{userDeviceInfo.deviceId}</td>
                  <td>{userDeviceInfo.token}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${userDeviceInfo.id}`} outline className="mr-1" color="success" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${userDeviceInfo.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      {props.isAdmin && (
                      <Button
                        tag={Link}
                        to={`${match.url}/${userDeviceInfo.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
      </div>
      {props.totalItems ? (
        <div className={userDeviceInfoList && userDeviceInfoList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ authentication, userDeviceInfo }: IRootState) => ({
  userDeviceInfoList: userDeviceInfo.entities,
  loading: userDeviceInfo.loading,
  totalItems: userDeviceInfo.totalItems,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN])
});

const mapDispatchToProps = {
  getEntities,
  getEntitiesWithFilters
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserDeviceInfo);
