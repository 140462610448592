import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './conversation-summary.reducer';
import { IConversationSummary } from 'app/shared/model/conversation-summary.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IConversationSummaryDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const ConversationSummaryDetail = (props: IConversationSummaryDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { conversationSummaryEntity } = props;
  return (
    <Row>
      <Col>
        <Row className="justify-content-center">
          <Col md={4} sm={8}>
            <h2>
              <Translate contentKey="diga1App.conversationSummary.detail.title">ConversationSummary</Translate> [
          <b>{conversationSummaryEntity.id}</b>]
        </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} sm={8} className="info-box">
            <dl className="jh-entity-details">
              <dt>
                <span id="entityId">
                  <Translate contentKey="diga1App.conversationSummary.entityId">Entity Id</Translate>
                </span>
              </dt>
              <dd>{conversationSummaryEntity.entityId}</dd>
              <dt>
                <span id="conversationTypeId">
                  <Translate contentKey="diga1App.conversationSummary.conversationTypeId">Conversation Type Id</Translate>
                </span>
              </dt>
              <dd>{conversationSummaryEntity.conversationTypeId}</dd>
              <dt>
                <span id="conversationType">
                  <Translate contentKey="diga1App.conversationSummary.conversationType">Conversation Type</Translate>
                </span>
              </dt>
              <dd>{conversationSummaryEntity.conversationType}</dd>
              <dt>
                <span id="appointmentId">
                  <Translate contentKey="diga1App.conversationSummary.appointmentId">Appointment Id</Translate>
                </span>
              </dt>
              <dd>{conversationSummaryEntity.appointmentId}</dd>
              <dt>
                <span id="startTime">
                  <Translate contentKey="diga1App.conversationSummary.startTime">Start Time</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={conversationSummaryEntity.startTime} type="date" format={APP_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="endTime">
                  <Translate contentKey="diga1App.conversationSummary.endTime">End Time</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={conversationSummaryEntity.endTime} type="date" format={APP_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="currentMessageId">
                  <Translate contentKey="diga1App.conversationSummary.currentMessageId">Current Message Id</Translate>
                </span>
              </dt>
              <dd>{conversationSummaryEntity.currentMessageId}</dd>
              <dt>
                <span id="requiredSince">
                  <Translate contentKey="diga1App.conversationSummary.requiredSince">Required Since</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={conversationSummaryEntity.requiredSince} type="date" format={APP_LOCAL_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="status">
                  <Translate contentKey="diga1App.conversationSummary.status">Status</Translate>
                </span>
              </dt>
              <dd>{conversationSummaryEntity.status}</dd>
            </dl>
            <Button tag={Link} to="/conversation-summary" replaceclassName="rounded" color="success" >
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
        &nbsp;
        <Button tag={Link} to={`/conversation-summary/${conversationSummaryEntity.id}/edit`} replace className="rounded" color="danger">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ conversationSummary }: IRootState) => ({
  conversationSummaryEntity: conversationSummary.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationSummaryDetail);
