import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConversationCreation from './conversation-creation';
import ConversationCreationDetail from './conversation-creation-detail';
import ConversationCreationUpdate from './conversation-creation-update';
import ConversationCreationDeleteDialog from './conversation-creation-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ConversationCreationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ConversationCreationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ConversationCreationDetail} />
      <ErrorBoundaryRoute path={match.url} component={ConversationCreation} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ConversationCreationDeleteDialog} />
  </>
);

export default Routes;
