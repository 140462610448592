import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './external-app-info.reducer';
import { IExternalAppInfo } from 'app/shared/model/external-app-info.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IExternalAppInfoDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ExternalAppInfoDetail = (props: IExternalAppInfoDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { externalAppInfoEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
        <h2>
          <Translate contentKey="diga1App.externalAppInfo.detail.title">ExternalAppInfo</Translate> [<b>{externalAppInfoEntity.id}</b>]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="internalEntityId">
              <Translate contentKey="diga1App.externalAppInfo.internalEntityId">Internal Entity Id</Translate>
            </span>
          </dt>
          <dd>{externalAppInfoEntity.internalEntityId}</dd>
          <dt>
            <span id="internalEntityType">
              <Translate contentKey="diga1App.externalAppInfo.internalEntityType">Internal Entity Type</Translate>
            </span>
          </dt>
          <dd>{externalAppInfoEntity.internalEntityType}</dd>
          <dt>
            <span id="externalPatientId">
              <Translate contentKey="diga1App.externalAppInfo.externalPatientId">External Patient Id</Translate>
            </span>
          </dt>
          <dd>{externalAppInfoEntity.externalPatientId}</dd>
          <dt>
            <span id="externalClinicId">
              <Translate contentKey="diga1App.externalAppInfo.externalClinicId">External Clinic Id</Translate>
            </span>
          </dt>
          <dd>{externalAppInfoEntity.externalClinicId}</dd>
          <dt>
            <span id="externalPhycianId">
              <Translate contentKey="diga1App.externalAppInfo.externalPhycianId">External Phycian Id</Translate>
            </span>
          </dt>
          <dd>{externalAppInfoEntity.externalPhycianId}</dd>
          <dt>
            <span id="externalAppName">
              <Translate contentKey="diga1App.externalAppInfo.externalAppName">External App Name</Translate>
            </span>
          </dt>
          <dd>{externalAppInfoEntity.externalAppName}</dd>
        </dl>
        <Button tag={Link} to="/external-app-info" replace className="rounded" color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/external-app-info/${externalAppInfoEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ externalAppInfo }: IRootState) => ({
  externalAppInfoEntity: externalAppInfo.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExternalAppInfoDetail);
