import { Moment } from 'moment';

export interface ICatalogNotification {
  id?: number;
  type?: number;
  date?: string;
  entityType?: number;
  entityId?: number;
  status?: string;
  userId?: number;
}

export const defaultValue: Readonly<ICatalogNotification> = {};
