export interface INotificationTemplate {
  id?: number;
  name?: string;
  description?: string;
  headerDe?: string;
  contentDe?: string;
  headerEn?: string;
  contentEn?: string;
  headerTr?: string;
  contentTr?: string;
}

export const defaultValue: Readonly<INotificationTemplate> = {};
