import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './conversation-notification.reducer';
import { IConversationNotification } from 'app/shared/model/conversation-notification.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IConversationNotificationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ConversationNotificationDetail = (props: IConversationNotificationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { conversationNotificationEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
        <h2>
          <Translate contentKey="diga1App.conversationNotification.detail.title">ConversationNotification</Translate> [
          <b>{conversationNotificationEntity.id}</b>]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="channel">
              <Translate contentKey="diga1App.conversationNotification.channel">Channel</Translate>
            </span>
          </dt>
          <dd>{conversationNotificationEntity.channel}</dd>
          <dt>
            <span id="starstAfter">
              <Translate contentKey="diga1App.conversationNotification.starstAfter">Starst After</Translate>
            </span>
          </dt>
          <dd>{conversationNotificationEntity.startAfter}</dd>
          <dt>
            <span id="startTime">
              <Translate contentKey="diga1App.conversationNotification.startTime">Start Time</Translate>
            </span>
          </dt>
          <dd>{conversationNotificationEntity.startTime}</dd>
          <dt>
            <span id="recurrence">
              <Translate contentKey="diga1App.conversationNotification.recurrence">Recurrence</Translate>
            </span>
          </dt>
          <dd>{conversationNotificationEntity.recurrence}</dd>
          <dt>
            <span id="occurrences">
              <Translate contentKey="diga1App.conversationNotification.occurrences">Occurrences</Translate>
            </span>
          </dt>
          <dd>{conversationNotificationEntity.occurrences}</dd>
        </dl>
        <Button tag={Link} to="/conversation-notification" replace className="rounded" color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/conversation-notification/${conversationNotificationEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ conversationNotification }: IRootState) => ({
  conversationNotificationEntity: conversationNotification.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationNotificationDetail);
