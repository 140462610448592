import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import PatientAppointmentDetailsRow from './ma-appointment-patient-row';
import { IRootState } from 'app/shared/reducers';
import { getAppointmentsByQuery } from '../ma-dashboard.reducer';
import { Translate } from 'react-jhipster';
import '../ma-pages.scss';
import { useHistory } from 'react-router-dom';

interface IPatientAppointmentsTable extends StateProps, DispatchProps { }

const PatientAppointmentsTable = (props: IPatientAppointmentsTable) => {
    const history = useHistory();

    useEffect(() => {
        if (props.selectedClinicId !== -1 && props.selectPatient?.id) {
            props.getAppointmentsByQuery(`clinicId.equals=${props.selectedClinicId}&patientId.equals=${props.selectPatient.id}&page=0&size=50`);
        } else {
            history.push('/ma-dashboard/Patients')
        }
    }, [props.selectedClinicId]);

    const handleRefreshAppointment = () => {
        props.getAppointmentsByQuery(`clinicId.equals=${props.selectedClinicId}&patientId.equals=${props.selectPatient.id}&page=0&size=50`);
    }
    return (
        <div>
            {props.maSelectedClinicAppointments && props.maSelectedClinicAppointments.length > 0
                ?
                <Table responsive>
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th>
                                <Translate contentKey="diga1App.patient.lastName">Last Name</Translate>
                            </th>
                            <th>
                                <Translate contentKey="diga1App.patient.firstName">First Name</Translate>
                            </th>
                            <th>
                                <Translate contentKey="diga1App.patient.doctor">Doctor</Translate>
                            </th>
                            <th>
                                <Translate contentKey="diga1App.appointment.date">Appointment Date</Translate>
                            </th>
                            <th>
                                <Translate contentKey="diga1App.appointment.time">Appointment Time</Translate>
                            </th>
                            <th>
                                <Translate contentKey="diga1App.appointment.status">Status</Translate>
                            </th>
                            <th>
                                <Translate contentKey="diga1App.appointment.change">Change Appointment</Translate>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {props.maSelectedClinicAppointments
                            .map((appointment) =>
                                <PatientAppointmentDetailsRow key={appointment.id} isShowDate={false} patientAppointmentDetails={appointment} handleRefreshAppointment={() => handleRefreshAppointment}></PatientAppointmentDetailsRow>)
                        }
                    </tbody>
                </Table >

                :
                (<div><Translate contentKey="diga1App.appointment.maDashboard.notFound">No appointments found</Translate></div>)
            }
        </div>);

};

const mapStateToProps = (storeState: IRootState) => ({
    selectedDate: storeState.maDashboardReducer.selectedDate,
    selectedClinicId: storeState.medicalAssistant.maClinicId,
    maSelectedClinicAppointments: storeState.maDashboardReducer.maSelectedClinicAppointments,
    currentLocale: storeState.locale.currentLocale,
    selectPatient: storeState.maDashboardReducer.selectedPatient
});

const mapDispatchToProps = {
    getAppointmentsByQuery
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientAppointmentsTable);