import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Container, Col } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import MedicalAssistantHome from './medical-assistant-home';
import { PageNavSideBar } from './page-nav-side-bar';
import { getEntityByUser } from 'app/entities/medical-assistant/medical-assistant.reducer';
import MedicalAssistantCalendar from './medical-assistant-calendar';
import { IRootState } from 'app/shared/reducers';

export interface IMedicalAssistantDashboard extends StateProps, DispatchProps, RouteComponentProps<{}> { }

const MedicalAssistantDashboard = (props: IMedicalAssistantDashboard) => {

    // useEffect(() => {
    //     props.getEntityByUser();

    // }, []);

    return (
        <Container fluid>
            <Row>
                <Col xs={12} sm={2} lg={2} className='sidenav'>
                    <PageNavSideBar />
                </Col>

                <Col sm={ 2 } lg={ 7 } className='text-left'>
                    <MedicalAssistantHome />
                </Col>
                {!props.hideCalendar && (
                <Col sm={5} lg={3} className='text-left'>
                    <MedicalAssistantCalendar />
                </Col>
                )}
            </Row>
        </Container>

    );
};

const mapStateToProps = (storeState: IRootState) => ({
    hideCalendar: storeState.maDashboardReducer.hideCalendar
});

const mapDispatchToProps = {
    getEntityByUser
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantDashboard);

