import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './conversation-event.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';

export interface IConversationEventUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const ConversationEventUpdate = (props: IConversationEventUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { conversationEventEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/conversation-event' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.messageTimestamp = convertDateTimeToServer(values.messageTimestamp);
    values.replyTimestamp = convertDateTimeToServer(values.replyTimestamp);

    if (errors.length === 0) {
      const entity = {
        ...conversationEventEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="diga1App.conversationEvent.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "diga1App.conversationEvent.home.createLabel"
              : "diga1App.conversationEvent.home.editLabel"}
            >
              Create or edit a ConversationEvent</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <AvForm model={isNew ? {} : conversationEventEntity} onSubmit={saveEntity}>
                <AvGroup>
                  <Label id="conversationIdLabel" for="conversation-event-conversationId">
                    <Translate contentKey="diga1App.conversationEvent.conversationId">Conversation Id</Translate>
                  </Label>
                  <AvField
                    id="conversation-event-conversationId"
                    type="string"
                    className="form-control"
                    name="conversationId"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="messageIdLabel" for="conversation-event-messageId">
                    <Translate contentKey="diga1App.conversationEvent.messageId">Message Id</Translate>
                  </Label>
                  <AvField
                    id="conversation-event-messageId"
                    type="string"
                    className="form-control"
                    name="messageId"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="messageTextLabel" for="conversation-event-messageText">
                    <Translate contentKey="diga1App.conversationEvent.messageText">Message Text</Translate>
                  </Label>
                  <AvField
                    id="conversation-event-messageText"
                    type="text"
                    name="messageText"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 1000, errorMessage: translate('entity.validation.maxlength', { max: 1000 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="replyIdLabel" for="conversation-event-replyId">
                    <Translate contentKey="diga1App.conversationEvent.replyId">Reply Id</Translate>
                  </Label>
                  <AvField id="conversation-event-replyId" type="string" className="form-control" name="replyId" />
                </AvGroup>
                <AvGroup>
                  <Label id="replyTextLabel" for="conversation-event-replyText">
                    <Translate contentKey="diga1App.conversationEvent.replyText">Reply Text</Translate>
                  </Label>
                  <AvField
                    id="conversation-event-replyText"
                    type="text"
                    name="replyText"
                    validate={{
                      maxLength: { value: 250, errorMessage: translate('entity.validation.maxlength', { max: 250 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="messageTimestampLabel" for="conversation-event-messageTimestamp">
                    <Translate contentKey="diga1App.conversationEvent.messageTimestamp">Message Timestamp</Translate>
                  </Label>
                  <AvInput
                    id="conversation-event-messageTimestamp"
                    type="datetime-local"
                    className="form-control"
                    name="messageTimestamp"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.conversationEventEntity.messageTimestamp)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="replyTimestampLabel" for="conversation-event-replyTimestamp">
                    <Translate contentKey="diga1App.conversationEvent.replyTimestamp">Reply Timestamp</Translate>
                  </Label>
                  <AvInput
                    id="conversation-event-replyTimestamp"
                    type="datetime-local"
                    className="form-control"
                    name="replyTimestamp"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.conversationEventEntity.replyTimestamp)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="conversationCategoryLabel" for="conversation-event-conversationCategory">
                    <Translate contentKey="diga1App.conversationEvent.conversationCategory">Conversation Category</Translate>
                  </Label>
                  <AvField
                    id="conversation-event-conversationCategory"
                    type="text"
                    name="conversationCategory"
                    validate={{
                      maxLength: { value: 100, errorMessage: translate('entity.validation.maxlength', { max: 100 }) },
                    }}
                  />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/conversation-event" replace className="rounded" color="success" >
                  <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              &nbsp;
                <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  conversationEventEntity: storeState.conversationEvent.entity,
  loading: storeState.conversationEvent.loading,
  updating: storeState.conversationEvent.updating,
  updateSuccess: storeState.conversationEvent.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationEventUpdate);
