import Axios from 'axios';
import { translate } from 'react-jhipster';

export const createDownloadLink = url => {
  const a: any = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) {
    a.href = url;
  } else {
    a.onclick = () => {
      window.open(url);
    };
  }
  a.click();
  document.body.removeChild(a);
};

export const generatePDF = fileName => {
  const requestUrl = `/api/general/retrieveDocument/${fileName}`;
  Axios.get(`${requestUrl}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf'
    }
  })
    .then(response => {
      if (response.data) {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        createDownloadLink(fileURL);
      } else {
        alert(translate('error.file.could.not.extract'));
      }
    })
    .catch(err => {
      alert(translate('error.file.could.not.extract'));
    });
};

export enum PDFFILES {
  HEALTH_LITERACY = 'health_literacy',
  STUDY_RESULTS = 'Study_Results',
  INSTALLATION_GUIDE_EN = 'patient_welcome_installation_guide_en',
  INSTALLATION_GUIDE_DE = 'patient_welcome_installation_guide_de',
  INSTALLATION_GUIDE_TR = 'patient_welcome_installation_guide_tr',
  COVID = '16-COVID-19',
  SALZARM = 'bei-bluthochdruck-salzarm-essen-237e8339afe685f3a76d2d425bba7acfeae4658f',
  STUDY_PARTICIPATION_DE = 'study_participation_de',
  DATA_PROTECTION_EN = 'data-protection-website-en',
  DATA_PROTECTION_DE = 'data-protection-website-de',
  DATA_PROTECTION_TR = 'data-protection-website-tr',
  DATA_PROTECTION_DIGA1_EN = 'data-protection-diga1-en',
  DATA_PROTECTION_DIGA1_DE = 'data-protection-diga1-de',
  DATA_PROTECTION_DIGA1_TR = 'data-protection-diga1-tr',
  USER_GUIDE_EN = 'MFA_User_Guide',
  USER_GUIDE_DE = 'MFA_User_Guide'
}

export const privacyPolicyDataPageFiles = {
  en: PDFFILES.DATA_PROTECTION_EN,
  de: PDFFILES.DATA_PROTECTION_DE,
  tr: PDFFILES.DATA_PROTECTION_TR
};

export const privacyPolicyDataDigaPageFiles = {
  en: PDFFILES.DATA_PROTECTION_DIGA1_EN,
  de: PDFFILES.DATA_PROTECTION_DIGA1_DE,
  tr: PDFFILES.DATA_PROTECTION_DIGA1_TR
};
