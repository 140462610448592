import { Moment } from 'moment';

export interface IDocuments {
  id?: number;
  userId?: number;
  fileName?: string;
  fileContentType?: string;
  file?: any;
  uploadedDate?: string;
}

export const defaultValue: Readonly<IDocuments> = {};
