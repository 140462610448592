import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, IPaginationBaseState } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPatient, defaultValue } from 'app/shared/model/patient.model';
import { IAppointment } from 'app/shared/model/appointment.model';
import { createAppointmentEntity } from '../appointment/appointment.reducer';

export const ACTION_TYPES = {
  FETCH_PATIENT_LIST: 'patient/FETCH_PATIENT_LIST',
  FETCH_PATIENT: 'patient/FETCH_PATIENT',
  CREATE_PATIENT: 'patient/CREATE_PATIENT',
  UPDATE_PATIENT: 'patient/UPDATE_PATIENT',
  DELETE_PATIENT: 'patient/DELETE_PATIENT',
  RESET: 'patient/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPatient>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type PatientState = Readonly<typeof initialState>;

// Reducer

export default (state: PatientState = initialState, action): PatientState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PATIENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_PATIENT):
    case REQUEST(ACTION_TYPES.UPDATE_PATIENT):
    case REQUEST(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PATIENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENT):
    case FAILURE(ACTION_TYPES.CREATE_PATIENT):
    case FAILURE(ACTION_TYPES.UPDATE_PATIENT):
    case FAILURE(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENT_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_PATIENT):
    case SUCCESS(ACTION_TYPES.UPDATE_PATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_PATIENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/patients';

// Actions

export const getEntitiesWithFilters = (page?, size?, sort?, filters = '') => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;

  if (filters) {
    requestUrl += `&${filters}`;
  }
  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get<IPatient>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntities: ICrudGetAllAction<IPatient> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get<IPatient>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntitiesNoSizeLimit: ICrudGetAllAction<IPatient> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get<IPatient>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IPatient> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT,
    payload: axios.get<IPatient>(requestUrl)
  };
};

export const getPatientsByAccountId: ICrudGetAction<IPatient> = id => {
  return {
    type: ACTION_TYPES.FETCH_PATIENT,
    payload: axios.get<IPatient>('api/patients/get-by-account-id', { params: { id } })
  };
};

export const getEntitiesByQuery = query => {
  const requestUrl = `/api/patients?sort=id,desc&${query}`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get<IPatient>(`${requestUrl}`)
  };
};

export const getEntityByAuth = () => {
  const requestUrl = `/api/patient`;
  return {
    type: ACTION_TYPES.FETCH_PATIENT,
    payload: axios.get<IPatient>(requestUrl)
  };
};

export const getPatientsByCriteria = searchValue => {
  let requestUrl = searchValue ? `${apiUrl}` : `${apiUrl}/criteria?`;

  if (searchValue !== undefined) requestUrl = requestUrl + `${`/criteria?search.contains=${searchValue}`}`;

  return {
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: axios.get<IPatient>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const createEntity: ICrudPutAction<IPatient> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PATIENT,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const createPatientWithAppointment = createPatientWithAppointmentRequest => async dispatch => {
  createPatientWithAppointmentRequest.patient = cleanEntity(createPatientWithAppointmentRequest.patient);
  createPatientWithAppointmentRequest.appointment = cleanEntity(createPatientWithAppointmentRequest.appointment);

  return await dispatch({
    type: ACTION_TYPES.CREATE_PATIENT,
    payload: axios.post('api/patientWithAppointment', createPatientWithAppointmentRequest)
  });
};

export const updateEntity: ICrudPutAction<IPatient> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PATIENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity = (id: number, pagination: IPaginationBaseState) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PATIENT,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities(pagination.activePage - 1, pagination.itemsPerPage, pagination.sort));
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
