import { translate } from 'react-jhipster';

export enum MA_DASH_PAGE {
  HOME = 'maDashboard.pages.home',
  PATIENTS_LIST = 'maDashboard.pages.patients',
  SELF_REGISTERATION = 'maDashboard.pages.selfRegistration',
  DOCTORS_LIST = 'maDashboard.pages.doctors',
  APPS_LIST = 'maDashboard.pages.apps',
  SUPPORT_PAGE = 'maDashboard.pages.supportPage',
  NEW_PATIENT = 'maDashboard.pages.newPatient',
  EXISTING_PATIENT = 'maDashboard.pages.existingPatient',
  CHANGE_APPOINTMENT = 'maDashboard.pages.changeAppointment',
  CHANGE_PATIENT = 'maDashboard.pages.changePatient',
  NEW_APPOINTMENT = 'maDashboard.pages.newAppointment'
}

export enum URL_SUFFIX {
  HOME = 'Home',
  PATIENTS_LIST = 'Patients',
  SELF_REGISTRATION = 'Self-Registration',
  DOCTORS_LIST = 'Doctors',
  SUPPORT_PAGE = 'Support',
  ADD_NEW_PATIENT = 'Add-New-Patient',
  ADD_EXISTING_PATIENT = 'Appointment-Existing-Patient',
  CHANGE_APPOINTMENT = 'Change-Appointment',
  CHANGE_PATIENT = 'Change-Patient',
  ADD_NEW_PATIENT_SR = 'Add-New-Patient-SR'
}

export enum URL_PREFIX {
  MA_DASHBOARD = '/ma-dashboard/'
}
