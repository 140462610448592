import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getIcdCodes } from 'app/entities/icd-code/icd-code.reducer';
import { getClinicsByCriteria } from 'app/entities/clinic/clinic.reducer'

import { getDoctorsByCriteria } from 'app/entities/healthcare-professional/healthcare-professional.reducer';
import { getPatientsByCriteria } from 'app/entities/patient/patient.reducer';
import { getEntity, updateEntity, createEntity, reset } from './appointment.reducer';
import { getEntitiesByRealm as getIcdCodesByApp } from 'app/entities/icd-code/icd-code.reducer';
import { REALM } from 'app/shared/constants/realm.model';
import patient from '../patient/patient';

const NOW = new Date().toJSON().split('T')[0];
const MAX_TIME_SINCE_TODAY = 10;

export interface IAppointmentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const AppointmentUpdate = (props: IAppointmentUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [apppintmentDate, setDate] = useState('')
  const [apppintmentTime, setTime] = useState('')

  const { appointmentEntity, clinics, healthcareProfessionals, patients, loading, updating } = props;

  const [selectedPatient, setSelectedPatient] = useState(appointmentEntity?.patient?.id ? appointmentEntity?.patient?.id : patients[0]?.id)

  const handleClose = () => {
    props.history.push('/appointment' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getClinicsByCriteria(undefined);
    props.getDoctorsByCriteria(undefined);
    props.getPatientsByCriteria(undefined);
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    const dateappointment = moment(appointmentEntity.date, 'YYYY-MM-DDLT').format('YYYY-MM-DD')

    const timeappointment = moment.tz(moment(appointmentEntity.date), 'Europe/Berlin').format('HH:mm')
    setDate(dateappointment);
    setTime(timeappointment);
  }, [props.appointmentEntity])

  useEffect(() => {
    if (!isNew && appointmentEntity?.patient?.realm === REALM.DIGA1)
      props.getIcdCodesByApp(REALM.DIGA1_APP);
    if (!isNew && appointmentEntity?.patient?.realm === REALM.VELARIA)
      props.getIcdCodesByApp(REALM.VELARIA_APP);
  }, [appointmentEntity?.patient?.realm]);

  useEffect(() => {
    if (isNew && patients[0]?.realm === REALM.DIGA1)
      props.getIcdCodesByApp(REALM.DIGA1_APP);
    if (isNew && patients[0]?.realm === REALM.VELARIA)
      props.getIcdCodesByApp(REALM.VELARIA_APP);
  }, [patients])

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const momentObj = moment(values.date + values.time, 'YYYY-MM-DDLT');
      values.date = moment.tz(momentObj.format('YYYY-MM-DDTHH:mm:ss'), 'Europe/Berlin');
      values.patient.id = Number(values.patient.id)

      if (values.icdCode !== undefined) {
        const icdCode = { id: values.icdCode }
        values.icdCode = icdCode
      }

      if (values.patient.id !== undefined && !isNew) {
        values.patient = { id: values.patient.id }
      } else if (values.patient.id !== undefined && isNew) {
        values.patient = patients.find(_patient => _patient.id === values.patient.id)
      }

      const entity = {
        ...appointmentEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const getMaxDateForAppointment = () => {
    const fiveYearsAgoFromNow = new Date();
    fiveYearsAgoFromNow.setFullYear(fiveYearsAgoFromNow.getFullYear() + MAX_TIME_SINCE_TODAY);

    return fiveYearsAgoFromNow.toJSON().split('T')[0];
  };

  const getIcdCodesByAppOptions = () => {
    return props.icdCodes
      ? props.icdCodes.map(otherEntity => (
        <option value={otherEntity?.id} key={otherEntity?.id}>
          {`${otherEntity?.code}  -   ${otherEntity?.description}`}
        </option>
      )).reverse()
      : ''
  }

  const getIcdCodesByPatientRealm = (patientId) => {
    const patientien = patients.find(_patient => _patient.id.toString() === patientId)

    setSelectedPatient(patientien?.id)

    if (_.isEqual(patientien?.realm, REALM.DIGA1))
      props.getIcdCodesByApp(REALM.DIGA1_APP);
    if (_.isEqual(patientien?.realm, REALM.VELARIA))
      props.getIcdCodesByApp(REALM.VELARIA_APP);
  }

  const defaultValues = isNew ? {} : {
    ...appointmentEntity,
    icdCode: appointmentEntity.icdCode
  }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}  >
          <h2 id="diga1App.appointment.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "diga1App.appointment.home.createLabel"
              : "diga1App.appointment.home.editLabel"}
            >
              Create or edit a Appointment</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={defaultValues} onSubmit={saveEntity}>
              <AvGroup>
                <Label id="titleLabel" for="appointment-title">
                  <Translate contentKey="diga1App.appointment.title">Title</Translate>
                </Label>
                <AvField id="appointment-title" type="text" name="title" />
              </AvGroup>
              <AvGroup>
                <Label id="dateLabel" for="appointment-date">
                  <Translate contentKey="diga1App.appointment.date">Date</Translate>
                </Label>
                <AvField
                  id="appointment-date"
                  type="date"
                  className="form-control"
                  name="date"
                  value={apppintmentDate}
                  min={NOW}
                  max={getMaxDateForAppointment()}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="timeLabel" for="appointment-time">
                  <Translate contentKey="diga1App.appointment.time">Time</Translate>
                </Label>
                <AvField
                  id="appointment-time"
                  type="text"
                  name="time"
                  value={apppintmentTime}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 5 },
                    pattern: {
                      value: '^(20|21|22|23|[01]\\d|\\d)((:[0-5]\\d){1})$',
                      errorMessage: translate('global.messages.validate.appointment.invalidTime'),
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="confirmedLabel" for="appointment-confirmed">
                  <Translate contentKey="diga1App.appointment.confirmed">Confirmed</Translate>
                </Label>
                <AvInput
                  id="appointment-confirmed"
                  type="select"
                  className="form-control"
                  name="confirmed"
                  value={!isNew && appointmentEntity.confirmed === translate('diga1App.confirmation.confirmed') ? translate('diga1App.confirmation.confirmed') : translate('diga1App.confirmation.notConfirmed')}
                >
                  <option value={translate('diga1App.confirmation.confirmed')}>{translate('diga1App.confirmation.confirmed')}</option>
                  <option value={translate('diga1App.confirmation.notConfirmed')}>{translate('diga1App.confirmation.notConfirmed')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="appointment-clinic">
                  <Translate contentKey="diga1App.appointment.clinic">Clinic</Translate>
                </Label>
                <AvInput
                  id="appointment-clinic"
                  type="select"
                  className="form-control"
                  name="clinic.id"
                  value={clinics[0] && clinics[0].id}
                  required
                >
                  {clinics
                    ? clinics.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                    : null}
                </AvInput>
                <AvFeedback>
                  <Translate contentKey="entity.validation.required">This field is required.</Translate>
                </AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="appointment-healthcareProfessional">
                  <Translate contentKey="diga1App.appointment.healthcareProfessional">Healthcare Professional</Translate>
                </Label>
                <AvInput
                  id="appointment-healthcareProfessional"
                  type="select"
                  className="form-control"
                  name="healthcareProfessional.id"
                  value={healthcareProfessionals[0] && healthcareProfessionals[0].id}
                  required
                >
                  {healthcareProfessionals
                    ? healthcareProfessionals.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.email}
                      </option>
                    ))
                    : null}
                </AvInput>
                <AvFeedback>
                  <Translate contentKey="entity.validation.required">This field is required.</Translate>
                </AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="appointment-patient">
                  <Translate contentKey="diga1App.appointment.patient">Patient</Translate>
                </Label>
                <AvInput
                  id="appointment-patient"
                  type="select"
                  className="form-control"
                  name="patient.id"
                  value={appointmentEntity?.patient?.id ? appointmentEntity?.patient?.id : patients[0]?.id}
                  onChange={(e) => getIcdCodesByPatientRealm(e.target.value)}
                  required
                >
                  {patients
                    ? patients.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.email}
                      </option>
                    ))
                    : null}
                </AvInput>
                <AvFeedback>
                  <Translate contentKey="entity.validation.required">This field is required.</Translate>
                </AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="appointment-icdCode">
                  <Translate contentKey="diga1App.appointment.icdCode">Icd Code</Translate>
                </Label>
                <AvInput id="appointment-icdCode" type="select" className="form-control" name="icdCode"
                  value={props.appointmentEntity?.icdCode?.id ? props.appointmentEntity?.icdCode?.id : props.icdCodes[props.icdCodes.length - 1]?.id}>
                  {getIcdCodesByAppOptions()}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/appointment" replace className="rounded" color="success" >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clinics: storeState.clinic.entities,
  healthcareProfessionals: storeState.healthcareProfessional.entities,
  patients: storeState.patient.entities,
  icdCodes: storeState.icdCode.entitiesByApp,
  appointmentEntity: storeState.appointment.entity,
  loading: storeState.appointment.loading,
  updating: storeState.appointment.updating,
  updateSuccess: storeState.appointment.updateSuccess
});

const mapDispatchToProps = {
  getClinicsByCriteria,
  getDoctorsByCriteria,
  getPatientsByCriteria,
  getIcdCodes,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getIcdCodesByApp
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentUpdate);