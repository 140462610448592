import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { INotificationSettings, defaultValue } from 'app/shared/model/notification-settings.model';

export const ACTION_TYPES = {
  FETCH_NOTIFICATIONSETTINGS_LIST: 'notificationSettings/FETCH_NOTIFICATIONSETTINGS_LIST',
  FETCH_TEMPLATE_OPTIONS: 'notificationSettings/FETCH_TEMPLATE_OPTIONS',
  FETCH_NOTIFICATIONSETTINGS: 'notificationSettings/FETCH_NOTIFICATIONSETTINGS',
  CREATE_NOTIFICATIONSETTINGS: 'notificationSettings/CREATE_NOTIFICATIONSETTINGS',
  UPDATE_NOTIFICATIONSETTINGS: 'notificationSettings/UPDATE_NOTIFICATIONSETTINGS',
  DELETE_NOTIFICATIONSETTINGS: 'notificationSettings/DELETE_NOTIFICATIONSETTINGS',
  RESET: 'notificationSettings/RESET',
  SET_NOTIFICATION_RULES: 'notificationSettings/SET_NOTIFICATION_RULES'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<INotificationSettings>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  scoreCalculationTypes: [],
  chatbots: [],
  notificationRules: {}
};

export type NotificationSettingsState = Readonly<typeof initialState>;

// Reducer

export default (state: NotificationSettingsState = initialState, action): NotificationSettingsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATIONSETTINGS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATIONSETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_NOTIFICATIONSETTINGS):
    case REQUEST(ACTION_TYPES.UPDATE_NOTIFICATIONSETTINGS):
    case REQUEST(ACTION_TYPES.DELETE_NOTIFICATIONSETTINGS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATIONSETTINGS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATIONSETTINGS):
    case FAILURE(ACTION_TYPES.CREATE_NOTIFICATIONSETTINGS):
    case FAILURE(ACTION_TYPES.UPDATE_NOTIFICATIONSETTINGS):
    case FAILURE(ACTION_TYPES.DELETE_NOTIFICATIONSETTINGS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONSETTINGS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONSETTINGS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_NOTIFICATIONSETTINGS):
    case SUCCESS(ACTION_TYPES.UPDATE_NOTIFICATIONSETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_NOTIFICATIONSETTINGS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.FETCH_TEMPLATE_OPTIONS:
      return {
        ...state,
        [action.payload.key]: action.payload.result
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.SET_NOTIFICATION_RULES:
      return {
        ...state,
        notificationRules: action.payload.data
      };
    default:
      return state;
  }
};

const apiUrl = 'api/notification-settings';
const apiUrlTemplate = 'api/notification-templates';

export const typeOfNotifications = {
  chatbotReminder: 'Chatbot reminder notification setting',
  actScoreCalculation: 'Low ACT score calculation notification setting',
  catScoreCalculation: 'High CAT score calculation notification setting'
};

export const rulesKeys = {
  [typeOfNotifications.actScoreCalculation]: 'scoreCalculationThresholdNotificationRuleList',
  [typeOfNotifications.catScoreCalculation]: 'scoreCalculationThresholdNotificationRuleList',
  [typeOfNotifications.chatbotReminder]: 'chatbotReminderNotificationRulesList'
};

export const getExistingRule = (data, key) => {
  let existingRules: any = {};
  try {
    existingRules = data?.rule ? JSON.parse(data?.rule) : {};
  } catch (error) {
    console.log(error);
  }
  return existingRules?.[key] ?? [];
};

// Actions

export const setNotificationRules = data => ({
  type: ACTION_TYPES.SET_NOTIFICATION_RULES,
  payload: { data }
});

export const getTemplateEntitiesForOptions = (name, key) => async dispatch => {
  const requestUrl = `${apiUrlTemplate}${`?page=${0}&size=${50}&name.contains=${name}`}`;

  const result = await axios.get<INotificationSettings>(`${requestUrl}&cacheBuster=${new Date().getTime()}`);

  return dispatch({
    type: ACTION_TYPES.FETCH_TEMPLATE_OPTIONS,
    payload: {
      key,
      result: result?.data
    }
  });
};

export const getEntitiesWithFilters = (page?, size?, sort?, filters = '') => async dispatch => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;

  if (filters) {
    requestUrl += `&${filters}`;
  }

  const result = await axios.get<INotificationSettings[]>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`);
  const rules = {};

  result?.data?.forEach(d => {
    rules[d.name] = getExistingRule(d, rulesKeys?.[d.name]);
  });

  dispatch(setNotificationRules(rules));

  return dispatch({
    type: SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONSETTINGS_LIST),
    payload: result
  });
};
export const getEntities: ICrudGetAllAction<INotificationSettings> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATIONSETTINGS_LIST,
    payload: axios.get<INotificationSettings>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<INotificationSettings> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATIONSETTINGS,
    payload: axios.get<INotificationSettings>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<INotificationSettings> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_NOTIFICATIONSETTINGS,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<INotificationSettings> = (entity: any) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_NOTIFICATIONSETTINGS,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });

  if (entity.fromConfig) {
    dispatch(getEntitiesWithFilters());
  }
  return result;
};

export const deleteEntity: ICrudDeleteAction<INotificationSettings> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_NOTIFICATIONSETTINGS,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
