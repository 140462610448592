import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './icd-code.reducer';
import { IIcdCode } from 'app/shared/model/icd-code.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IIcdCodeDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const IcdCodeDetail = (props: IIcdCodeDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { icdCodeEntity } = props;
  return (
    <Row>
      <Col>
        <Row className="justify-content-center">
          <Col md={4} sm={8}>
            <h2>
              <Translate contentKey="diga1App.icdCode.detail.title">IcdCode</Translate> [<b>{icdCodeEntity.id}</b>]
          </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} sm={8} className="info-box">
            <dl className="jh-entity-details">
              <dt>
                <span id="code">
                  <Translate contentKey="diga1App.icdCode.code">Code</Translate>
                </span>
              </dt>
              <dd>{icdCodeEntity.code}</dd>
              <dt>
                <span id="descriptionDe">
                  <Translate contentKey="diga1App.icdCode.descriptionDe">Description De</Translate>
                </span>
              </dt>
              <dd>{icdCodeEntity.descriptionDe}</dd>
              <dt>
                <span id="displayTextDe">
                  <Translate contentKey="diga1App.icdCode.displayTextDe">Display Text De</Translate>
                </span>
              </dt>
              <dd>{icdCodeEntity.displayTextDe}</dd>
              <dt>
                <span id="descriptionEn">
                  <Translate contentKey="diga1App.icdCode.descriptionEn">Description En</Translate>
                </span>
              </dt>
              <dd>{icdCodeEntity.descriptionEn}</dd>
              <dt>
                <span id="displayTextEn">
                  <Translate contentKey="diga1App.icdCode.displayTextEn">Display Text En</Translate>
                </span>
              </dt>
              <dd>{icdCodeEntity.displayTextEn}</dd>
              <dt>
                <span id="descriptionTr">
                  <Translate contentKey="diga1App.icdCode.descriptionTr">Description Tr</Translate>
                </span>
              </dt>
              <dd>{icdCodeEntity.descriptionTr}</dd>
              <dt>
                <span id="displayTextTr">
                  <Translate contentKey="diga1App.icdCode.displayTextTr">Display Text Tr</Translate>
                </span>
              </dt>
              <dd>{icdCodeEntity.displayTextTr}</dd>
              <dt>
            <span id="realm">
              <Translate contentKey="diga1App.icdCode.realm">Realm</Translate>
            </span>
          </dt>
          <dd>{icdCodeEntity.realm}</dd>
            </dl>
            <Button tag={Link} to="/icd-code" replace className="rounded" color="success" >
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
        &nbsp;
        <Button tag={Link} to={`/icd-code/${icdCodeEntity.id}/edit`} replace className="rounded" color="danger">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ icdCode }: IRootState) => ({
  icdCodeEntity: icdCode.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(IcdCodeDetail);
