import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './notification-monitor.reducer';
import { INotificationMonitor } from 'app/shared/model/notification-monitor.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getNotificationMonitorsByCriteria } from 'app/entities/notification-monitor/notification-monitor.reducer'

export interface INotificationMonitorProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const NotificationMonitor = (props: INotificationMonitorProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const [searchValue, setSearchValue] = useState(null);

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

    const onSearch = () => {
      if (searchValue && searchValue !== null && searchValue !== '')
        props.getNotificationMonitorsByCriteria(searchValue);
      else getAllEntities();
    }
  
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && searchValue && searchValue !== '') {
        onSearch()
      }
    }

  const { notificationMonitorList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="notification-monitor-heading">
        <Translate contentKey="diga1App.notificationMonitor.home.title">Notification Monitors</Translate>
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.notificationMonitor.home.createLabel">Create new Notification Monitor</Translate>
        </Link>
      </h2>
      <Row style={{ "width": "50%" }}>
        <input className="form-control form-control-sm w-75"
          style={{ borderRadius: '100px' }}
          placeholder={translate("global.form.search")}
          aria-label="Search"
          onChange={(event) => setSearchValue(event.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <Button style={{ "marginLeft": "1rem" }} className="tableButton" onClick={() => onSearch()}>
          <Translate contentKey="maDashboard.searchButton">Open</Translate>
        </Button>
      </Row>
      <br/>
      <div className="table-responsive">
        {notificationMonitorList && notificationMonitorList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('identification')}>
                  <Translate contentKey="diga1App.notificationMonitor.identification">Identification</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('channel')}>
                  <Translate contentKey="diga1App.notificationMonitor.channel">Channel</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('dateTime')}>
                  <Translate contentKey="diga1App.notificationMonitor.dateTime">Date Time</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="diga1App.notificationMonitor.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('reason')}>
                  <Translate contentKey="diga1App.notificationMonitor.reason">Reason</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('details')}>
                  <Translate contentKey="diga1App.notificationMonitor.details">Details</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {notificationMonitorList.map((notificationMonitor, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${notificationMonitor.id}`} color="link" size="sm">
                      {notificationMonitor.id}
                    </Button>
                  </td>
                  <td>{notificationMonitor.identification}</td>
                  <td>{notificationMonitor.channel}</td>
                  <td>
                    {notificationMonitor.dateTime ? (
                      <TextFormat type="date" value={notificationMonitor.dateTime} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{notificationMonitor.status}</td>
                  <td>{notificationMonitor.reason}</td>
                  <td>{notificationMonitor.details}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${notificationMonitor.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${notificationMonitor.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${notificationMonitor.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="diga1App.notificationMonitor.home.notFound">No Notification Monitors found</Translate>
            </div>
          )
        )}
      </div>
      {props.totalItems ? (
        <div className={notificationMonitorList && notificationMonitorList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ notificationMonitor }: IRootState) => ({
  notificationMonitorList: notificationMonitor.entities,
  loading: notificationMonitor.loading,
  totalItems: notificationMonitor.totalItems,
});

const mapDispatchToProps = {
  getEntities,
  getNotificationMonitorsByCriteria
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMonitor);
