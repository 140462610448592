import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster'; import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './conversation-summary.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import moment from 'moment';

export interface IConversationSummaryUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const ConversationSummaryUpdate = (props: IConversationSummaryUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { conversationSummaryEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/conversation-summary' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.startTime = convertDateTimeToServer(values.startTime);
    values.endTime = convertDateTimeToServer(values.endTime);

    const momentObj = moment(values.requiredSince, 'YYYY-MM-DDLT');
    values.requiredSince = moment.tz(momentObj.format('YYYY-MM-DDTHH:mm:ss'),'Europe/Berlin');

    if (errors.length === 0) {
      const entity = {
        ...conversationSummaryEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="diga1App.conversationSummary.home.createOrEditLabel">
            <Translate contentKey={isNew
              ? "diga1App.conversationSummary.home.createLabel"
              : "diga1App.conversationSummary.home.editLabel"}
            >
              Create or edit a ConversationSummary</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <AvForm model={isNew ? {} : conversationSummaryEntity} onSubmit={saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="conversation-summary-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="conversation-summary-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="entityIdLabel" for="conversation-summary-entityId">
                    <Translate contentKey="diga1App.conversationSummary.entityId">Entity Id</Translate>
                  </Label>
                  <AvField id="conversation-summary-entityId" type="string" className="form-control" name="entityId" />
                </AvGroup>
                <AvGroup>
                  <Label id="conversationTypeIdLabel" for="conversation-summary-conversationTypeId">
                    <Translate contentKey="diga1App.conversationSummary.conversationTypeId">Conversation Type Id</Translate>
                  </Label>
                  <AvField
                    id="conversation-summary-conversationTypeId"
                    type="string"
                    className="form-control"
                    name="conversationTypeId"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      number: { value: true, errorMessage: translate('entity.validation.number') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="conversationTypeLabel" for="conversation-summary-conversationType">
                    <Translate contentKey="diga1App.conversationSummary.conversationType">Conversation Type</Translate>
                  </Label>
                  <AvField
                    id="conversation-summary-conversationType"
                    type="text"
                    name="conversationType"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="appointmentIdLabel" for="conversation-summary-appointmentId">
                    <Translate contentKey="diga1App.conversationSummary.appointmentId">Appointment Id</Translate>
                  </Label>
                  <AvField id="conversation-summary-appointmentId" type="string" className="form-control" name="appointmentId" />
                </AvGroup>
                <AvGroup>
                  <Label id="startTimeLabel" for="conversation-summary-startTime">
                    <Translate contentKey="diga1App.conversationSummary.startTime">Start Time</Translate>
                  </Label>
                  <AvInput
                    id="conversation-summary-startTime"
                    type="datetime-local"
                    className="form-control"
                    name="startTime"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.conversationSummaryEntity.startTime)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="endTimeLabel" for="conversation-summary-endTime">
                    <Translate contentKey="diga1App.conversationSummary.endTime">End Time</Translate>
                  </Label>
                  <AvInput
                    id="conversation-summary-endTime"
                    type="datetime-local"
                    className="form-control"
                    name="endTime"
                    placeholder={'YYYY-MM-DD HH:mm'}
                    value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.conversationSummaryEntity.endTime)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="currentMessageIdLabel" for="conversation-summary-currentMessageId">
                    <Translate contentKey="diga1App.conversationSummary.currentMessageId">Current Message Id</Translate>
                  </Label>
                  <AvField id="conversation-summary-currentMessageId" type="string" className="form-control" name="currentMessageId" />
                </AvGroup>
                <AvGroup>
                  <Label id="requiredSinceLabel" for="conversation-summary-requiredSince">
                    <Translate contentKey="diga1App.conversationSummary.requiredSince">Required Since</Translate>
                  </Label>
                  <AvField id="conversation-summary-requiredSince" type="date" className="form-control" name="requiredSince" />
                </AvGroup>
                <AvGroup>
                  <Label id="statusLabel" for="conversation-summary-status">
                    <Translate contentKey="diga1App.conversationSummary.status">Status</Translate>
                  </Label>
                  <AvField id="conversation-summary-status" type="text" name="status" />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/conversation-summary" replace className="rounded" color="success" >
                  <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
              &nbsp;
                <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  conversationSummaryEntity: storeState.conversationSummary.entity,
  loading: storeState.conversationSummary.loading,
  updating: storeState.conversationSummary.updating,
  updateSuccess: storeState.conversationSummary.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationSummaryUpdate);
