import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Row, Col, Button, InputGroup, InputGroupAddon, Input, Form, Alert } from 'reactstrap';
import moment from 'moment';
import { sendMessage, getConversation } from './chatbot.reducer';

import {IConversationEvent} from '../../shared/model/conversation-event.model'

interface IChatbotTitle extends StateProps, DispatchProps { }



const ChatbotTitle = (props: IChatbotTitle) => {

    const [chatbotInput, setChatbotInput] = useState("");
    const [chatbotMessages, setChatbotMessages] = useState("");

    const getBotName = () => {
        if (props.selectedBot && props.selectedBot.conversationType) {
            return `${props.selectedBot.conversationType}  -  required Since:   ${moment(props.selectedBot.requiredSince).format("MMM Do YY")}`;
        }
        else {
            "";
        }
    };

    const triggerMessage = (message: IConversationEvent) => {

        if (message.messageText && message.messageText !== "start_conversation" && message.messageType !== "Message" && message.replyText && message.replyText.length > 0) {
            setChatbotMessages(chatbotMessages + "user >>>" + chatbotInput + "\n\n");
        }
        if (message.quickReplies && message.quickReplies.length > 0)    {
            message.quickReplies.forEach( quickReply => {
                if (quickReply.quickReply.toLowerCase() === message.replyText.toLowerCase()) {
                    message.replyId = quickReply.replyId;
                }
            });
        }
        props.sendMessage(message);
        setChatbotInput("");
    };


    useEffect(() => {

        if (props.selectedBot && props.selectedBot.id > 0) {
            props.getConversationHistory(props.selectedBot.id);
        }

    }, [props.selectedBot]);

    useEffect(() => {

        let newChatbotMessages = "";
        if (props.conversationHistory && props.conversationHistory.length > 0) {


            let lastConversationEvent = {};
            props.conversationHistory.forEach(conversationEvent => {
                newChatbotMessages = newChatbotMessages + "diga1 Bot >>>  " + conversationEvent.messageText + "\n\n";

                if (conversationEvent.replyText && conversationEvent.replyText !== "") {
                    newChatbotMessages = newChatbotMessages + "user   >>>" + conversationEvent.replyText + "\n\n";
                }
                lastConversationEvent = { ...conversationEvent };
            });
            setChatbotMessages(newChatbotMessages);
            if (!props.currentMessage) {
                triggerMessage(lastConversationEvent);
            }
        }
        else {
            if (props.selectedBot && props.selectedBot.id > 0) {

                const message = {

                    conversationId: props.selectedBot.id,
                    messageId: 0,
                    messageText: "start_conversation",
                    replyId: 0,
                    replyText: ""
                };
                triggerMessage(message);
            }
        }
        setChatbotMessages(newChatbotMessages);
        const textarea = document.getElementById('botMessagingArea');
        if (textarea) {
            textarea.scrollTop = textarea.scrollHeight;
        }
    }, [props.conversationHistory]);


    useEffect(() => {
        if (props.currentMessage) {
            setChatbotMessages(chatbotMessages + "diga1 Bot >>>  " + props.currentMessage.messageText + "\n\n");
            const textarea = document.getElementById('botMessagingArea');
            if (textarea) {
                textarea.scrollTop = textarea.scrollHeight;
            }

            if ("Message" === props.currentMessage.messageType) {
                const message = { ...props.currentMessage };
                triggerMessage(message);
            }
        }
    }, [props.currentMessage]);



    const onInputChange = (e) => {
        const newMessage = e.target.value;
        setChatbotInput(newMessage);
    };

    const hanldeOnSubimt = (e) => {
        e.preventDefault();
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            const message = {
                ...props.currentMessage,
                replyText: chatbotInput,
            };
            triggerMessage(message);
        }
    };

    const handleClick = () => {
        const message = {
            ...props.currentMessage,
            replyText: chatbotInput
        };
        triggerMessage(message);
    };

    return (
        <div>
            {
                props.selectedBot
                    ?
                    <div>
                        <Row>
                            <Col style={{ height: "5rem" }} />
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <Form onSubmit={hanldeOnSubimt} >
                                    <Alert color="info">{getBotName()}</Alert>
                                    <br />
                                    <Input
                                        id="botMessagingArea"
                                        type="textarea"
                                        cols={100}
                                        name="botMessagingArea"
                                        style={{ height: "15rem" }}
                                        value={chatbotMessages}
                                        readOnly={true}
                                    />
                                    <InputGroup>
                                        <Input
                                            placeholder="Enter your message"
                                            id="messageInput"
                                            name="messageInput"
                                            value={chatbotInput}
                                            onChange={onInputChange}
                                            onKeyPress={handleKeyPress}
                                        />
                                        <InputGroupAddon addonType="append"><Button color="info" onClick={handleClick}>{'>'}</Button></InputGroupAddon>
                                    </InputGroup>
                                </Form>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <h2>Choose Bot</h2>
                    </div>

            }
        </div >

    );
};


const mapStateToProps = ({ chatbotReducer }: IRootState) => ({
    selectedBot: chatbotReducer.selectedBot,
    conversationHistory: chatbotReducer.conversationHistory,
    currentMessage: chatbotReducer.currentMessage

});

const mapDispatchToProps = {
    sendMessage, getConversationHistory: getConversation

};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ChatbotTitle);