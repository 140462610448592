import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, openFile, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './documents.reducer';
import { IDocuments } from 'app/shared/model/documents.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IDocumentsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const DocumentsDetail = (props: IDocumentsDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { documentsEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>

        <h2>
          <Translate contentKey="diga1App.documents.detail.title">Documents</Translate> [<b>{documentsEntity.id}</b>]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="userId">
              <Translate contentKey="diga1App.documents.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{documentsEntity.userId}</dd>
          <dt>
            <span id="fileName">
              <Translate contentKey="diga1App.documents.fileName">File Name</Translate>
            </span>
          </dt>
          <dd>{documentsEntity.fileName}</dd>
          <dt>
            <span id="file">
              <Translate contentKey="diga1App.documents.file">File</Translate>
            </span>
          </dt>
          <dd>
            {documentsEntity.file ? (
              <div>
                {/* {documentsEntity.fileContentType ? (
                  <a onClick={openFile(documentsEntity.fileContentType, documentsEntity.file)}>
                    <Translate contentKey="entity.action.open">Open</Translate>&nbsp;
                  </a>
                ) : null} */}
                <span>
                  {documentsEntity.fileContentType}, {byteSize(documentsEntity.file)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="uploadedDate">
              <Translate contentKey="diga1App.documents.uploadedDate">Uploaded Date</Translate>
            </span>
          </dt>
          <dd>
            {documentsEntity.uploadedDate ? <TextFormat value={documentsEntity.uploadedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="fileContentType">
              <Translate contentKey="diga1App.documents.fileContentType">File Content Type</Translate>
            </span>
          </dt>
          <dd>{documentsEntity.fileContentType}</dd>
        </dl>
        <Button tag={Link} to="/documents" replace color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/documents/${documentsEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ documents }: IRootState) => ({
  documentsEntity: documents.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsDetail);
