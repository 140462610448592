import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getClinics } from 'app/entities/clinic/clinic.reducer';
import { getEntitiesByQuery as getHealthcareProfessionals } from 'app/entities/healthcare-professional/healthcare-professional.reducer';
import { getEntities as getPatients } from 'app/entities/patient/patient.reducer';
import { getEntity, updateEntity, createEntity, reset } from '../../../entities/appointment/appointment.reducer';
import moment from 'moment';
import 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { ROUTING } from 'app/shared/constants/routing';
import { getEntitiesByRealm as getIcdCodesByApp } from 'app/entities/icd-code/icd-code.reducer';
import { REALM } from 'app/shared/constants/realm.model';
import _ from 'lodash';
import { getEntityByUser } from 'app/entities/medical-assistant/medical-assistant.reducer';

const NOW = new Date().toJSON().split('T')[0];
const MAX_TIME_SINCE_TODAY = 10;

export interface IMFADashboardPatientAppointmentUpdateProps extends StateProps, DispatchProps { }

export const MFADashboardPatientAppointmentUpdate = (props: IMFADashboardPatientAppointmentUpdateProps) => {
  const [apppintmentDate, setDate] = useState('')
  const [apppintmentTime, setTime] = useState('')
  const history = useHistory();

  const { appointmentEntity, healthcareProfessionals, loading, updating } = props;

  useEffect(() => {
    props.getHealthcareProfessionals(`clinicId.equals=${props.selectedClinicId}`);
    // props.getPatients(0, 50, 'asc');
    if (props.appointmentEntity === undefined ||
      props.appointmentEntity === null ||
      _.isEmpty(props.appointmentEntity))
      history.push(ROUTING.MFA_HOME_PAGE)
  }, []);


  useEffect(() => {
    props.getEntityByUser();
  }, [props.account])

  useEffect(() => {
    if (appointmentEntity?.patient?.realm === REALM.DIGA1)
      props.getIcdCodesByApp(REALM.DIGA1_APP)
    if (appointmentEntity?.patient?.realm === REALM.VELARIA)
      props.getIcdCodesByApp(REALM.VELARIA_APP)
  }, [appointmentEntity?.patient?.realm]);

  useEffect(() => {
    const dateappointment = moment(appointmentEntity?.date, 'YYYY-MM-DDLT').format('YYYY-MM-DD')

    console.log('after: ' + dateappointment);

    const timeappointment = moment.tz(moment(appointmentEntity?.date), 'Europe/Berlin').format('HH:mm')
    setDate(dateappointment);
    setTime(timeappointment);
  }, [props.appointmentEntity])

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...appointmentEntity,
        confirmed: 'Not Confirmed',
        ...values
      };

      props.updateEntity(entity);
      history.push(ROUTING.MFA_HOME_PAGE)
    }
  };

  const getIcdCodesByAppOptions = () => {
    return props.icdCodes
      ? props.icdCodes.map(otherEntity => (
        <option value={otherEntity?.id} key={otherEntity?.id}>
          {`${otherEntity?.code}  -   ${otherEntity?.description}`}
        </option>
      )).reverse()
      : ''
  }

  const getMaxDateForAppointment = () => {
    const fiveYearsAgoFromNow = new Date();

    fiveYearsAgoFromNow.setFullYear(fiveYearsAgoFromNow.getFullYear() + MAX_TIME_SINCE_TODAY);

    return fiveYearsAgoFromNow.toJSON().split('T')[0];
  };

  const defaultValues = {
    ...appointmentEntity,
    icdCode: appointmentEntity?.icdCode
  }

  return (
    <Col md={5} className='page-container'>
      <Row>
        <Col>
          <Row style={{ margin: "1rem" }}>
            <h4 id="diga1App.appointment.home.createOrEditLabel">
              <Translate contentKey={"diga1App.appointment.home.editLabel"}>
                Edit Appointment</Translate>
            </h4>
          </Row>
          <br />
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={defaultValues} onSubmit={saveEntity}>
              <Row >
                <Col>
                  <AvGroup>
                    <Label id="patient" for="appointment-patient">
                      <Translate contentKey="diga1App.appointment.patient">Patient</Translate>
                    </Label>
                    <AvInput
                      id="appointment-healthcareProfessional"
                      type="select"
                      className="form-control"
                      name="selectedPatientId"
                      value={
                        appointmentEntity?.patient?.id
                      }
                      required
                      disabled
                    >
                      <option value={`${appointmentEntity?.patient?.id}`} key={appointmentEntity?.patient?.id}>
                        {`${appointmentEntity?.patient?.salutation} ${appointmentEntity?.patient?.firstName} ${appointmentEntity?.patient?.lastName}`}
                      </option>
                    </AvInput>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AvGroup>
                    <Label for="appointment-healthcareProfessional">
                      <Translate contentKey="diga1App.appointment.healthcareProfessional">Healthcare Professional</Translate>
                    </Label>
                    <AvInput
                      id="appointment-healthcareProfessional"
                      type="select"
                      className="form-control"
                      name="healthcareProfessional.id"
                      value={appointmentEntity?.healthcareProfessional?.id}
                      required
                    >
                      {healthcareProfessionals
                        ? healthcareProfessionals.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {`${otherEntity.salutation} ${otherEntity.title} ${otherEntity.firstName} ${otherEntity.lastName}`}
                          </option>
                        ))
                        : null}
                    </AvInput>
                    <AvFeedback>
                      <Translate contentKey="entity.validation.required">This field is required.</Translate>
                    </AvFeedback>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <AvGroup>
                        <Label for="appointment-icdCode">
                          <Translate contentKey="diga1App.appointment.icdCode">Icd Code</Translate>
                        </Label>
                        <AvInput id="appointment-icdCode" type="select" className="form-control" name="icdCode.id"
                          value={props.appointmentEntity?.icdCode?.id}>
                          {getIcdCodesByAppOptions()}
                        </AvInput>
                      </AvGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AvGroup>
                    <Label id="dateLabel" for="appointment-date">
                      <Translate contentKey="diga1App.appointment.date">Date</Translate>
                    </Label>
                    <AvField
                      id="appointment-date"
                      type="date"
                      className="form-control"
                      name="date"
                      value={apppintmentDate}
                      min={NOW}
                      max={getMaxDateForAppointment()}
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AvGroup>
                    <Label for="appointment-time">
                      <Translate contentKey="diga1App.appointment.time">Time</Translate>
                    </Label>
                    <AvField
                      id="appointment-time"
                      type="text"
                      name="time"
                      value={apppintmentTime}
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        maxLength: { value: 5 },
                        pattern: {
                          value: '^(20|21|22|23|[01]\\d|\\d)((:[0-5]\\d){1})$',
                          errorMessage: translate('global.messages.validate.appointment.invalidTime'),
                        }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                &nbsp;
                <Col xs={3} />
                <Col xs={5}>
                  <div className="wizard-form-in-button">
                    <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                      <FontAwesomeIcon icon="save" />
                      &nbsp;
                      <Translate contentKey="entity.action.save">Save</Translate>
                    </Button>
                  </div></Col></Row>
            </AvForm>
          )}
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  healthcareProfessionals: storeState.healthcareProfessional.entities,
  appointmentEntity: storeState.maDashboardReducer.selectedAppointment,
  loading: storeState.appointment.loading,
  updating: storeState.appointment.updating,
  updateSuccess: storeState.appointment.updateSuccess,
  currentLocale: storeState.locale.currentLocale,
  icdCodes: storeState.icdCode?.entitiesByApp,
  selectedClinicId: storeState.medicalAssistant.maClinicId,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getClinics,
  getHealthcareProfessionals,
  getPatients,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getIcdCodesByApp,
  getEntityByUser
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MFADashboardPatientAppointmentUpdate);
