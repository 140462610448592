import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Spinner, Col, Row, Button } from 'reactstrap';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntitiesByQuery } from 'app/entities/self-registration/self-registration.reducer';
import { updateSelectedPatient } from '../ma-dashboard.reducer';
import { URL_PREFIX, URL_SUFFIX } from '../ma-dashboard-layout/pages-list';
import '../ma-pages.scss';
import { Link } from 'react-router-dom';
import { REALM } from 'app/shared/constants/realm.model';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getEntityByUser } from 'app/entities/medical-assistant/medical-assistant.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IMedicalAssistantSelfRegistrations extends DispatchProps, StateProps { }

const MedicalAssistantSelfRegistrations = (props: IMedicalAssistantSelfRegistrations) => {
    const [paginationState, setPaginationState] = useState(
        overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE), location.search)
    );

    const navigateToCreatePatient = (patientToCreate) => {
        patientToCreate = { ...patientToCreate, realm: REALM.DIGA1 }
        props.updateSelectedPatient(patientToCreate);
    };

    useEffect(() => {
        props.getEntityByUser();
    }, [props.account])

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const page = params.get('page');
        const sort = params.get('sort');
        if (page && sort) {
            const sortSplit = sort.split(',');
            setPaginationState({
                ...paginationState,
                activePage: +page,
                sort: sortSplit[0],
                order: sortSplit[1],
            });
        }
    }, [location.search]);

    const sort = p => () => {
        setPaginationState({
            ...paginationState,
            order: paginationState.order === 'asc' ? 'desc' : 'asc',
            sort: p,
        });
    };

    const handlePagination = currentPage =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });

    useEffect(() => {
        if (props.selectedClinic && props.selectedClinic !== -1) {
            props.getEntitiesByQuery('clinicId.equals=' + props.selectedClinic, paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
        }
    }, [props.selectedClinic]);

    return (
        <Row>
            <Col>
                <div className="table-responsive">
                    {props.selfRegistrationList && props.selfRegistrationList.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr style={{ textAlign: "center" }}>
                                    <th>
                                        {''}
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.selfRegistration.create">Create Patient</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.selfRegistration.firstName">First Name</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.selfRegistration.lastName">Last Name</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.selfRegistration.langKey">Language</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.selfRegistration.gender">Gender</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.selfRegistration.birthdate">Birthday</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.selfRegistration.email">Email</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.selfRegistration.mobileNumber">Mobile Number</Translate>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.selfRegistrationList.map((selfRegistration, i) => {
                                    return (
                                        <tr key={`entity-${i}`} style={{ textAlign: "center" }}>
                                            <td>
                                                {`${i + 1}`}
                                            </td>
                                            <td style={{ cursor: "pointer" }}>
                                                <Link to={URL_PREFIX.MA_DASHBOARD + URL_SUFFIX.ADD_NEW_PATIENT_SR}>
                                                    <Button className="tableButton-2" onClick={() => navigateToCreatePatient(selfRegistration)}>
                                                        <Translate contentKey="diga1App.selfRegistration.create">Create patient</Translate>
                                                    </Button>
                                                </Link>
                                            </td>
                                            <td>
                                                {`${selfRegistration.firstName}`}

                                            </td>
                                            <td>
                                                {`${selfRegistration.lastName}`}
                                            </td>
                                            <td>
                                                {`${selfRegistration.langKey}`}
                                            </td>
                                            <td>
                                                {`${selfRegistration.gender}`}
                                            </td>
                                            <td>
                                                {new Date(selfRegistration.birthdate).toLocaleDateString(props.currentLocale, { timeZone: 'Europe/Berlin' })}
                                            </td>
                                            <td>
                                                {`${selfRegistration.email}`}
                                            </td>
                                            <td>
                                                {`${selfRegistration.mobileNumber}`}
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </Table>
                    ) : (<Col><div><Translate contentKey="diga1App.selfRegistration.home.notFound">No Self Registrations found</Translate></div></Col>)}
                </div>
                {props.totalItems ? (
                    <div className={props.selfRegistrationList && props.selfRegistrationList.length > 0 ? '' : 'd-none'}>
                        <Row className="justify-content-center">
                            <JhiItemCount page={paginationState.activePage} total={props.totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
                        </Row>
                        <Row className="justify-content-center">
                            <JhiPagination
                                activePage={paginationState.activePage}
                                onSelect={handlePagination}
                                maxButtons={5}
                                itemsPerPage={paginationState.itemsPerPage}
                                totalItems={props.totalItems}
                            />
                        </Row>
                    </div>
                ) : (
                    ''
                )}

            </Col >
        </Row>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    selfRegistrationList: storeState.selfRegistration.entities,
    selectedClinic: storeState.medicalAssistant.maClinicId,
    currentLocale: storeState.locale.currentLocale,
    totalItems: storeState.selfRegistration.totalItems,
    account: storeState.authentication.account
});

const mapDispatchToProps = {
    getEntitiesByQuery,
    updateSelectedPatient,
    getEntityByUser
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantSelfRegistrations);
