import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ITipCatalogTr, defaultValue } from 'app/shared/model/tip-catalog-tr.model';

export const ACTION_TYPES = {
  FETCH_TIPCATALOGTR_LIST: 'tipCatalogTr/FETCH_TIPCATALOGTR_LIST',
  FETCH_TIPCATALOGTR: 'tipCatalogTr/FETCH_TIPCATALOGTR',
  CREATE_TIPCATALOGTR: 'tipCatalogTr/CREATE_TIPCATALOGTR',
  UPDATE_TIPCATALOGTR: 'tipCatalogTr/UPDATE_TIPCATALOGTR',
  DELETE_TIPCATALOGTR: 'tipCatalogTr/DELETE_TIPCATALOGTR',
  RESET: 'tipCatalogTr/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITipCatalogTr>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type TipCatalogTrState = Readonly<typeof initialState>;

// Reducer

export default (state: TipCatalogTrState = initialState, action): TipCatalogTrState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TIPCATALOGTR_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TIPCATALOGTR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_TIPCATALOGTR):
    case REQUEST(ACTION_TYPES.UPDATE_TIPCATALOGTR):
    case REQUEST(ACTION_TYPES.DELETE_TIPCATALOGTR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_TIPCATALOGTR_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TIPCATALOGTR):
    case FAILURE(ACTION_TYPES.CREATE_TIPCATALOGTR):
    case FAILURE(ACTION_TYPES.UPDATE_TIPCATALOGTR):
    case FAILURE(ACTION_TYPES.DELETE_TIPCATALOGTR):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_TIPCATALOGTR_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_TIPCATALOGTR):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_TIPCATALOGTR):
    case SUCCESS(ACTION_TYPES.UPDATE_TIPCATALOGTR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_TIPCATALOGTR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/tip-catalog-trs';

// Actions

export const getEntities: ICrudGetAllAction<ITipCatalogTr> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TIPCATALOGTR_LIST,
    payload: axios.get<ITipCatalogTr>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<ITipCatalogTr> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TIPCATALOGTR,
    payload: axios.get<ITipCatalogTr>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ITipCatalogTr> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TIPCATALOGTR,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ITipCatalogTr> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TIPCATALOGTR,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITipCatalogTr> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TIPCATALOGTR,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
