import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IScoreCalculation, defaultValue } from 'app/shared/model/score-calculation.model';

export const ACTION_TYPES = {
  FETCH_SCORECALCULATION_LIST: 'scoreCalculation/FETCH_SCORECALCULATION_LIST',
  FETCH_SCORECALCULATION: 'scoreCalculation/FETCH_SCORECALCULATION',
  CREATE_SCORECALCULATION: 'scoreCalculation/CREATE_SCORECALCULATION',
  UPDATE_SCORECALCULATION: 'scoreCalculation/UPDATE_SCORECALCULATION',
  DELETE_SCORECALCULATION: 'scoreCalculation/DELETE_SCORECALCULATION',
  RESET: 'scoreCalculation/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IScoreCalculation>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type ScoreCalculationState = Readonly<typeof initialState>;

// Reducer

export default (state: ScoreCalculationState = initialState, action): ScoreCalculationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SCORECALCULATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_SCORECALCULATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_SCORECALCULATION):
    case REQUEST(ACTION_TYPES.UPDATE_SCORECALCULATION):
    case REQUEST(ACTION_TYPES.DELETE_SCORECALCULATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_SCORECALCULATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SCORECALCULATION):
    case FAILURE(ACTION_TYPES.CREATE_SCORECALCULATION):
    case FAILURE(ACTION_TYPES.UPDATE_SCORECALCULATION):
    case FAILURE(ACTION_TYPES.DELETE_SCORECALCULATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SCORECALCULATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_SCORECALCULATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_SCORECALCULATION):
    case SUCCESS(ACTION_TYPES.UPDATE_SCORECALCULATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_SCORECALCULATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/score-calculations';

// Actions
export const getEntitiesWithFilters = (page?, size?, sort?, filters = '') => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;

  if (filters) {
    requestUrl += `&${filters}`;
  }
  return {
    type: ACTION_TYPES.FETCH_SCORECALCULATION_LIST,
    payload: axios.get<IScoreCalculation>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntities: ICrudGetAllAction<IScoreCalculation> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_SCORECALCULATION_LIST,
    payload: axios.get<IScoreCalculation>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IScoreCalculation> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_SCORECALCULATION,
    payload: axios.get<IScoreCalculation>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IScoreCalculation> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SCORECALCULATION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IScoreCalculation> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_SCORECALCULATION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IScoreCalculation> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_SCORECALCULATION,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
