export const getBrowserDetails = () => {
  const nAgt = navigator.userAgent;
  const platform = navigator.platform;
  let browserName = '';
  let fullVersion = '';
  let majorVersion = 0;
  let nameOffset, verOffset, ix;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer';
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) !== -1) fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(' ')) !== -1) fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt('' + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  return { browserName, fullVersion, majorVersion, platform };
};

export const supportedBrowser = [
  { browserName: 'Chrome', platform: 'Desktop', version: '80' },
  { browserName: 'Chrome', platform: 'Android', version: '80' },
  { browserName: 'Chrome', platform: 'iOS', version: '80' },
  { browserName: 'Firefox', platform: 'Desktop', version: '75' },
  { browserName: 'Firefox', platform: 'iOS', version: '23' },
  { browserName: 'Firefox', platform: 'Android', version: '68' },
  { browserName: 'Safari', platform: 'Desktop', version: '13' },
  { browserName: 'Safari', platform: 'Desktop', version: '5' },
  { browserName: 'Edge', platform: 'Desktop', version: 'Chromium' }
];

export const isSupportedBrowser = () => {
  const browserDetails = getBrowserDetails();
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  if (isAndroid) {
    browserDetails.platform = 'Android';
  } else if (isIOS) {
    browserDetails.platform = 'iOS';
  } else {
    browserDetails.platform = 'Desktop';
  }

  const found = supportedBrowser.filter(s => {
    return (
      s.browserName.toLowerCase().includes(String(browserDetails?.browserName).toLowerCase()) &&
      parseInt(s.version, 10) <= browserDetails.majorVersion &&
      s.platform === browserDetails.platform
    );
  });

  console.log({ found });

  return {
    isSupported: found?.length > 0,
    browserDetails
  };
};
