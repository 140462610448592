import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './appointment.reducer';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAppointmentDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const AppointmentDetail = (props: IAppointmentDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { appointmentEntity } = props;
  return (
    <Row>
      <Col>
        <Row className="justify-content-center">
          <Col md={4} sm={8}>
            <h2>
              <Translate contentKey="diga1App.appointment.detail.title">Appointment</Translate> [<b>{appointmentEntity.id}</b>]
          </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} sm={8} className="info-box">
            <dl className="jh-entity-details">
              <dt>
                <span id="title">
                  <Translate contentKey="diga1App.appointment.title">Title</Translate>
                </span>
              </dt>
              <dd>{appointmentEntity.title}</dd>
              <dt>
                <span id="date">
                  <Translate contentKey="diga1App.appointment.date">Date</Translate>
                </span>
              </dt>
              <dd>
                <TextFormat value={appointmentEntity.date} type="date" format={APP_LOCAL_DATE_FORMAT} />
              </dd>
              <dt>
                <span id="time">
                  <Translate contentKey="diga1App.appointment.time">Time</Translate>
                </span>
              </dt>
              <dd>{appointmentEntity.time}</dd>
              <dt>
                <span id="confirmed">
                  <Translate contentKey="diga1App.appointment.confirmed">Confirmed</Translate>
                </span>
              </dt>
              <dd>{appointmentEntity.confirmed}</dd>
              <dt>
                <Translate contentKey="diga1App.appointment.clinic">Clinic</Translate>
              </dt>
              <dd>{appointmentEntity.clinic ? appointmentEntity.clinic.name : ''}</dd>
              <dt>
                <Translate contentKey="diga1App.appointment.healthcareProfessional">Healthcare Professional</Translate>
              </dt>
              <dd>{appointmentEntity.healthcareProfessional ? `${appointmentEntity.healthcareProfessional.firstName} ${appointmentEntity.healthcareProfessional.lastName}` : ''}</dd>
              <dt>
                <Translate contentKey="diga1App.appointment.patient">Patient</Translate>
              </dt>
              <dd>{appointmentEntity.patient ? `${appointmentEntity.patient.firstName} ${appointmentEntity.patient.lastName}` : ''}</dd>
              <dt>
                <Translate contentKey="diga1App.appointment.icdCode">Icd Code</Translate>
              </dt>
              <dd>{appointmentEntity.icdCode ? appointmentEntity.icdCode.id : ''}</dd>
            </dl>
            <Button tag={Link} to="/appointment" replace className="rounded" color="success" >
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
        &nbsp;
        <Button tag={Link} to={`/appointment/${appointmentEntity.id}/edit`} replace className="rounded" color="danger">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ appointment }: IRootState) => ({
  appointmentEntity: appointment.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetail);
