import React, { Component } from 'react';
import { Row } from 'reactstrap';
import SideBarLinkBox from './SideBarLinkBox/SideBarLinkBox';
import { MA_DASH_PAGE, URL_SUFFIX } from './pages-list';
import { translate } from 'react-jhipster';

export const PageNavSideBar = () => {
    return (
        <Row>
            <div className="col-md-5 offset-md-4"> <img src='content/images/diga1-logo.svg' alt='logo' style={{ marginLeft: "2rem", marginBottom: "1rem" }}></img></div>
            <SideBarLinkBox svg={"content/images/appointments.svg"} text={translate(MA_DASH_PAGE.HOME)} urlSuffix={URL_SUFFIX.HOME} />
            <SideBarLinkBox svg={"content/images/patients.svg"} text={translate(MA_DASH_PAGE.PATIENTS_LIST)} urlSuffix={URL_SUFFIX.PATIENTS_LIST} />
            <SideBarLinkBox svg={"content/images/selfRegistered.svg"} text={translate(MA_DASH_PAGE.SELF_REGISTERATION)} urlSuffix={URL_SUFFIX.SELF_REGISTRATION} />
            <SideBarLinkBox svg={"content/images/support-icon.svg"} text={translate(MA_DASH_PAGE.SUPPORT_PAGE)} urlSuffix={URL_SUFFIX.SUPPORT_PAGE} />
        </Row>
    );
};

export default PageNavSideBar;