import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './scheduled-task.reducer';
import { IScheduledTask } from 'app/shared/model/scheduled-task.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IScheduledTaskDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const ScheduledTaskDetail = (props: IScheduledTaskDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { scheduledTaskEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="diga1App.scheduledTask.detail.title">ScheduledTask</Translate> [<b>{scheduledTaskEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="type">
              <Translate contentKey="diga1App.scheduledTask.type">Type</Translate>
            </span>
          </dt>
          <dd>{scheduledTaskEntity.type === 1 ? translate('diga1App.scheduledTask.typeList.sendReportToClinic') :
               scheduledTaskEntity.type === 2 ? translate('diga1App.scheduledTask.typeList.chatbotRequestedByPatientEmailReminder') :
               scheduledTaskEntity.type === 3 ? translate('diga1App.scheduledTask.typeList.icdNotification') :
               scheduledTaskEntity.type === 4 ? translate('diga1App.scheduledTask.typeList.sendAppointmentReminder') : ''
          }</dd>

          <dt>
            <span id="runTime">
              <Translate contentKey="diga1App.scheduledTask.runTime">Run Time</Translate>
            </span>
          </dt>
          <dd>
            {scheduledTaskEntity.runTime ? <TextFormat value={scheduledTaskEntity.runTime} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="request">
              <Translate contentKey="diga1App.scheduledTask.request">Request</Translate>
            </span>
          </dt>
          <dd>{scheduledTaskEntity.request}</dd>
          <dt>
            <span id="state">
              <Translate contentKey="diga1App.scheduledTask.state">State</Translate>
            </span>
          </dt>
          <dd>{scheduledTaskEntity.state === 1 ? translate('diga1App.scheduledTask.stateList.schedule')
            : scheduledTaskEntity.state === 2 ? translate('diga1App.scheduledTask.stateList.done')
              : scheduledTaskEntity.state === 3 ? translate('diga1App.scheduledTask.stateList.failed')
                : scheduledTaskEntity.state === 4 ? translate('diga1App.scheduledTask.stateList.cancelled')
                : ''}</dd>
        </dl>
        <Button tag={Link} to="/scheduled-task" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/scheduled-task/${scheduledTaskEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ scheduledTask }: IRootState) => ({
  scheduledTaskEntity: scheduledTask.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledTaskDetail);
