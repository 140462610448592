import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, getConversationCatalogUniqueTypes, getEntitiesByCriteria } from './conversation-creation.reducer';
import { getEntities as getEntitiesForICDCodes } from '../icd-code/icd-code.reducer';
import { IConversationCreation } from 'app/shared/model/conversation-creation.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import SearchInputText from 'app/shared/input-text/search-input-text/search-input-text';
import { isArray } from 'lodash';

export interface IConversationCreationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const ConversationCreation = (props: IConversationCreationProps) => {
  const [filters, setFilters] = useState({
    icdCode: '',
    conversationType: '',
  });

  const [keyword, setKeyword] = useState('');
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    if (filters.icdCode || filters.conversationType) {
      const { conversationTypesArray, icdCodes } = props;
      let query = '';
      let icdCodesIds = icdCodes?.filter((i) => String(i.code).toLowerCase().includes(filters?.icdCode.toLowerCase())).map((i) => i.id).join(',');
      let conversationTypeIds = conversationTypesArray?.filter((i) => String(i.name).toLowerCase().includes(filters?.conversationType.toLowerCase())).map((i) => i.id).join(',');

      if (filters?.icdCode === '*') {
        icdCodesIds = '0';
      }

      if (filters?.conversationType === '*') {
        conversationTypeIds = '0';
      }

      if (icdCodesIds && filters?.icdCode) {
        query = `${query}icdCodeId.in=${icdCodesIds}&`
      } else if (filters?.icdCode) {
        query = `${query}icdCodeId.in=-1&`
      }

      if (conversationTypeIds && filters?.conversationType) {
        query = `${query}conversationTypeId.in=${conversationTypeIds}`
      } else if (filters?.conversationType) {
        query = `${query}conversationTypeId.in=-1`
      }
      if (query)
        props.getEntitiesByCriteria(query, paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort}`);
      else props.getEntities();
      return;
    }

    if (keyword) {
      const { conversationTypesArray, icdCodes } = props;
      let query = '';
      const icdCodesIds = icdCodes?.filter((i) => String(i.code).toLowerCase().includes(keyword.toLowerCase())).map((i) => i.id).join(',');
      const conversationTypeIds = conversationTypesArray?.filter((i) => String(i.name).toLowerCase().includes(keyword.toLowerCase())).map((i) => i.id).join(',');

      if(icdCodesIds) {
        query = `${query}icdCodeId.in=${icdCodesIds}`
      } else if(conversationTypeIds) {
        query = `${query}conversationTypeId.in=${conversationTypeIds}`
      }
      if (query)
        props.getEntitiesByCriteria(query, paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort}`);
      else getEntities();
      return;
    }
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, keyword, filters.icdCode, filters.conversationType]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  useEffect(() => {
    props.getConversationCatalogUniqueTypes();
    props.getEntitiesForICDCodes();
  }, []);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const onSearch = (search:string) => {
    setKeyword(search);
  };

  const { conversationCreationList, match, loading, totalItems, conversationTypes, icdCodes } = props;
  return (
    <div>
      <h2 id="conversation-creation-heading">
        <Translate contentKey="diga1App.conversationCreation.home.title">Conversation Creations</Translate>
        <Link to={`${match.url}/new`} className="btn btn-outline-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.conversationCreation.home.createLabel">Create new Conversation Creation</Translate>
        </Link>
      </h2>
      <SearchInputText onSearch={onSearch} />
      <div className="table-responsive">
        {(
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('icdCodeId')}>
                  <Translate contentKey="diga1App.conversationCreation.icdCodeId">Icd Code Id</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        icdCode: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('conversationTypeId')}>
                  <Translate contentKey="diga1App.conversationCreation.conversationTypeId">Conversation Type Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        conversationType: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('triggersWhen')}>
                  <Translate contentKey="diga1App.conversationCreation.triggersWhen">Triggers When</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('startAfter')}>
                  <Translate contentKey="diga1App.conversationCreation.startsAfter">Starts After</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('startTime')}>
                  <Translate contentKey="diga1App.conversationCreation.startTime">Start Time</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                !loading && conversationCreationList?.length === 0 && (
                  <tr>
                    <td colSpan={6}>
                      <div className="alert alert-warning">
                        <Translate contentKey="diga1App.conversationCreation.home.notFound">No Conversation Creations found</Translate>
                      </div>
                    </td>
                  </tr>
                )
              }
              {conversationCreationList.map((conversationCreation, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${conversationCreation.id}`} color="link" size="sm">
                      {conversationCreation.id}
                    </Button>
                  </td>
                  <td>
                    {isArray(icdCodes) ? (
                      icdCodes.find(c => c.id === conversationCreation.icdCodeId)?.code ?? (
                        <Translate contentKey="diga1App.conversationCreation.allIcdCodes">All ICD codes</Translate>
                      )
                    ) : (
                      <Translate contentKey="diga1App.conversationCreation.allIcdCodes">All ICD codes</Translate>
                    )}
                  </td>
                  <td>{conversationTypes?.[conversationCreation.conversationTypeId] ?? '-' }</td>
                  <td>{conversationCreation.triggersWhen}</td>
                  <td>{conversationCreation.startAfter}</td>
                  <td>{conversationCreation.startTime}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${conversationCreation.id}`}
                        outline
                        className="mr-1"
                        color="success"
                        size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${conversationCreation.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      {props.isAdmin && (
                      <Button
                        tag={Link}
                        to={`${match.url}/${conversationCreation.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1 "
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      {props.totalItems ? (
        <div className={conversationCreationList && conversationCreationList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ authentication, conversationCreation, icdCode }: IRootState) => ({
  conversationCreationList: conversationCreation.entities,
  loading: conversationCreation.loading,
  totalItems: conversationCreation.totalItems,
  conversationTypes: conversationCreation.uniqueConversationTypeObject,
  conversationTypesArray: conversationCreation.uniqueConversationType,
  icdCodes: icdCode.entities,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN])
});

const mapDispatchToProps = {
  getEntities,
  getConversationCatalogUniqueTypes,
  getEntitiesForICDCodes,
  getEntitiesByCriteria,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationCreation);
