import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, getEntitiesWithFilters } from './internal-api-logs.reducer';
import { IInternalApiLogs } from 'app/shared/model/internal-api-logs.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import SearchInputText from 'app/shared/input-text/search-input-text/search-input-text';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IInternalApiLogsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const InternalApiLogs = (props: IInternalApiLogsProps) => {
  const [filters, setFilters] = useState({
    apiName: '',
    url: '',
    request: '',
    responses: '',
    error: '',
    status: '',
    source: ''
  });
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    const query = Object.keys(filters)
      .filter(k => filters[k] !== '')
      .map(k => {
        if (k === 'userId' || k === 'score') {
          return `${k}.equals=${filters[k]}`;
        }
        return `${k}.contains=${filters[k]}`;
      })
      .join('&');
    props.getEntitiesWithFilters(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      query
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  useEffect(() => {
    sortEntities();
  }, [filters.apiName, filters.request, filters.responses, filters.error, filters.source, filters.status, filters.url]);

  const { internalApiLogsList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="internal-api-logs-heading">
        <Translate contentKey="diga1App.internalApiLogs.home.title">Internal Api Logs</Translate>
        <Link to={`${match.url}/new`} className="btn btn-outline-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.internalApiLogs.home.createLabel">Create new Internal Api Logs</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        {
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('apiName')}>
                  <Translate contentKey="diga1App.internalApiLogs.apiName">Api Name</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        apiName: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('url')}>
                  <Translate contentKey="diga1App.internalApiLogs.url">Url</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        url: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('request')}>
                  <Translate contentKey="diga1App.internalApiLogs.request">Request</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        request: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('responses')}>
                  <Translate contentKey="diga1App.internalApiLogs.responses">Responses</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        responses: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('error')}>
                  <Translate contentKey="diga1App.internalApiLogs.error">Error</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        error: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="diga1App.internalApiLogs.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        status: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('source')}>
                  <Translate contentKey="diga1App.internalApiLogs.source">Source</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        source: text
                      });
                    }}
                    column
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!loading && internalApiLogsList.length === 0 && (
                <tr>
                  <td colSpan={9}>
                    <div className="alert alert-warning">
                      <Translate contentKey="diga1App.internalApiLogs.home.notFound">No internalApiLogs found</Translate>
                    </div>
                  </td>
                </tr>
              )}
              {internalApiLogsList.map((internalApiLogs, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${internalApiLogs.id}`} color="link" size="sm">
                      {internalApiLogs.id}
                    </Button>
                  </td>
                  <td>{internalApiLogs.apiName}</td>
                  <td>{internalApiLogs.url}</td>
                  <td>{internalApiLogs.request}</td>
                  <td>{internalApiLogs.responses}</td>
                  <td>{internalApiLogs.error}</td>
                  <td>{internalApiLogs.status}</td>
                  <td>{internalApiLogs.source}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${internalApiLogs.id}`} outline className="mr-1" color="success" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${internalApiLogs.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      {props.isAdmin && (
                        <Button
                          tag={Link}
                          to={`${match.url}/${internalApiLogs.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          outline
                          className="rounded mr-1 "
                          color="danger"
                          size="sm"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
      </div>
      {props.totalItems ? (
        <div className={internalApiLogsList && internalApiLogsList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ authentication, internalApiLogs }: IRootState) => ({
  internalApiLogsList: internalApiLogs.entities,
  loading: internalApiLogs.loading,
  totalItems: internalApiLogs.totalItems,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN])
});

const mapDispatchToProps = {
  getEntities,
  getEntitiesWithFilters
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InternalApiLogs);
