import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserTask from './user-task';
import UserTaskDetail from './user-task-detail';
import UserTaskUpdate from './user-task-update';
import UserTaskDeleteDialog from './user-task-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserTaskUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserTaskUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserTaskDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserTask} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UserTaskDeleteDialog} />
  </>
);

export default Routes;
