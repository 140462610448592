import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IClinic } from 'app/shared/model/clinic.model';
import { getClinicsByCriteria } from 'app/entities/clinic/clinic.reducer'

import { getEntity, updateEntity, createEntity, reset } from './patient.reducer';
import { IPatient } from 'app/shared/model/patient.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IPatientUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> { }

export const PatientUpdate = (props: IPatientUpdateProps) => {
  const [idsclinic, setIdsclinic] = useState([]);
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { patientEntity, clinics, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/patient' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getClinicsByCriteria(undefined);
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      let entity = {
        ...patientEntity,
        ...values,
        clinics: mapIdList(values.clinics),
      };

      if (isNew) {
        entity = {
          ...entity,
          preferences:'{"patientSafty": "false"}'
        }
        props.createEntity(entity);
      } else {
        const preferencesObject = JSON.parse(entity.preferences);
        entity = {
          ...entity,
          preferences: preferencesObject
        }
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="diga1App.patient.home.createOrEditLabel">
            <Translate contentKey="diga1App.patient.home.createOrEditLabel">Create or edit a Patient</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : patientEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="patient-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="patient-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="userIdLabel" for="patient-userId">
                  <Translate contentKey="diga1App.patient.userId">User Id</Translate>
                </Label>
                <AvField id="patient-userId" type="string" className="form-control" name="userId" />
              </AvGroup>
              <AvGroup>
                <Label id="salutationLabel" for="patient-salutation">
                  <Translate contentKey="diga1App.patient.salutation">Salutation</Translate>
                </Label>
                <AvField
                  id="patient-salutation"
                  type="text"
                  name="salutation"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="titleLabel" for="patient-title">
                  <Translate contentKey="diga1App.patient.title">Title</Translate>
                </Label>
                <AvField
                  id="patient-title"
                  type="text"
                  name="title"
                  validate={{
                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="firstNameLabel" for="patient-firstName">
                  <Translate contentKey="diga1App.patient.firstName">First Name</Translate>
                </Label>
                <AvField
                  id="patient-firstName"
                  type="text"
                  name="firstName"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="lastNameLabel" for="patient-lastName">
                  <Translate contentKey="diga1App.patient.lastName">Last Name</Translate>
                </Label>
                <AvField
                  id="patient-lastName"
                  type="text"
                  name="lastName"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="genderLabel" for="patient-gender">
                  <Translate contentKey="diga1App.patient.gender">Gender</Translate>
                </Label>
                <AvField
                  id="patient-gender"
                  type="text"
                  name="gender"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="emailLabel" for="patient-email">
                  <Translate contentKey="diga1App.patient.email">Email</Translate>
                </Label>
                <AvField
                  id="patient-email"
                  type="text"
                  name="email"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="mobileNumberLabel" for="patient-mobileNumber">
                  <Translate contentKey="diga1App.patient.mobileNumber">Mobile Number</Translate>
                </Label>
                <AvField
                  id="patient-mobileNumber"
                  type="text"
                  name="mobileNumber"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="langKeyLabel" for="patient-langKey">
                  <Translate contentKey="diga1App.patient.langKey">Lang Key</Translate>
                </Label>
                <AvField
                  id="patient-langKey"
                  type="text"
                  name="langKey"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                    maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="birthdateLabel" for="patient-birthdate">
                  <Translate contentKey="diga1App.patient.birthdate">Birthdate</Translate>
                </Label>
                <AvField
                  id="patient-birthdate"
                  type="date"
                  className="form-control"
                  name="birthdate"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="invitationFromLabel" for="patient-invitationFrom">
                  <Translate contentKey="diga1App.patient.invitationFrom">Invitation From</Translate>
                </Label>
                <AvField
                  id="patient-invitationFrom"
                  type="text"
                  name="invitationFrom"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 100, errorMessage: translate('entity.validation.maxlength', { max: 100 }) },
                  }}
                />
              </AvGroup>
              <AvGroup check>
                <Label id="mobileAppInstalledLabel">
                  <AvInput id="patient-mobileAppInstalled" type="checkbox" className="form-check-input" name="mobileAppInstalled" />
                  <Translate contentKey="diga1App.patient.mobileAppInstalled">Mobile App Installed</Translate>
                </Label>
              </AvGroup>
              <AvGroup>
                <Label id="realmLabel" for="patient-realm">
                  <Translate contentKey="diga1App.patient.realm">Realm</Translate>
                </Label>
                <AvField
                  id="patient-realm"
                  type="text"
                  name="realm"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="preferencesLabel" for="patient-preferences">
                  <Translate contentKey="diga1App.patient.preferences">Preferences</Translate>
                </Label>
                <AvField
                  id="patient-preferences"
                  type="text"
                  name="preferences"
                  validate={{
                    maxLength: { value: 2500, errorMessage: translate('entity.validation.maxlength', { max: 2500 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label for="patient-clinic">
                  <Translate contentKey="diga1App.patient.clinic">Clinic</Translate>
                </Label>
                <AvInput
                  id="patient-clinic"
                  type="select"
                  multiple
                  className="form-control"
                  name="clinics"
                  value={patientEntity.clinics && patientEntity.clinics.map(e => e.id)}
                >
                  <option value="" key="0" />
                  {clinics
                    ? clinics.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/patient" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clinics: storeState.clinic.entities,
  patientEntity: storeState.patient.entity,
  loading: storeState.patient.loading,
  updating: storeState.patient.updating,
  updateSuccess: storeState.patient.updateSuccess,
});

const mapDispatchToProps = {
  getClinicsByCriteria,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientUpdate);
