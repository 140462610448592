import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './user-activity.reducer';
import { IUserActivity } from 'app/shared/model/user-activity.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { isArray } from 'lodash';

export interface IUserActivityUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const UserActivityUpdate = (props: IUserActivityUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { userActivityEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/user-activity' + props.location.search);
  };

  useEffect(() => {
    props.getUsers(0, 1000, 'id,asc');
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.activityTime = convertDateTimeToServer(values.activityTime);

    if (errors.length === 0) {
      const entity = {
        ...userActivityEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  console.log('props.user', props.users);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="diga1App.userActivity.home.createOrEditLabel">
            <Translate contentKey="diga1App.userActivity.home.createOrEditLabel">Create or edit a User Activity</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : userActivityEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="user-activity-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="user-activity-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="userIdLabel" for="user-activity-userId">
                  <Translate contentKey="diga1App.userActivity.userId">User</Translate>
                </Label>
                <AvInput
                  id="user-activity-userId"
                  type="select"
                  className="form-control"
                  name="userId"
                  value={(!isNew && userActivityEntity.userId) || ''}
                >
                  {isArray(props.users)
                    ? props.users.map(user => <option key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>)
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="activityTypeIdLabel" for="user-activity-activityTypeId">
                  <Translate contentKey="diga1App.userActivity.activityTypeId">Activity Type Id</Translate>
                </Label>
                <AvInput
                  id="user-activity-activityTypeId"
                  type="select"
                  className="form-control"
                  name="activityTypeId"
                  value={(!isNew && userActivityEntity.activityTypeId) || 'LOGINTIME'}
                >
                  <option value="LOGINTIME">{translate('diga1App.ActivityTypeEnum.LOGINTIME')}</option>
                  <option value="LOGOUTTIME">{translate('diga1App.ActivityTypeEnum.LOGOUTTIME')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="activityTimeLabel" for="user-activity-activityTime">
                  <Translate contentKey="diga1App.userActivity.activityTime">Activity Time</Translate>
                </Label>
                <AvInput
                  id="user-activity-activityTime"
                  type="datetime-local"
                  className="form-control"
                  name="activityTime"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.userActivityEntity.activityTime)}
                />
              </AvGroup>
              <AvGroup>
                <Label id="activitySourceLabel" for="user-activity-activitySource">
                  <Translate contentKey="diga1App.userActivity.activitySource">Activity Source</Translate>
                </Label>
                <AvField id="user-activity-activitySource" type="text" name="activitySource" />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/user-activity" replace className="rounded" color="success">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  userActivityEntity: storeState.userActivity.entity,
  loading: storeState.userActivity.loading,
  updating: storeState.userActivity.updating,
  updateSuccess: storeState.userActivity.updateSuccess,
  users: storeState.userManagement.users
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getUsers
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserActivityUpdate);
