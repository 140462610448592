import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './patient.reducer';
import { IPatient } from 'app/shared/model/patient.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IPatientDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PatientDetail = (props: IPatientDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { patientEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="diga1App.patient.detail.title">Patient</Translate> [<b>{patientEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="userId">
              <Translate contentKey="diga1App.patient.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{patientEntity.userId}</dd>
          <dt>
            <span id="salutation">
              <Translate contentKey="diga1App.patient.salutation">Salutation</Translate>
            </span>
          </dt>
          <dd>{patientEntity.salutation}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="diga1App.patient.title">Title</Translate>
            </span>
          </dt>
          <dd>{patientEntity.title}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="diga1App.patient.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{patientEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="diga1App.patient.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{patientEntity.lastName}</dd>
          <dt>
            <span id="gender">
              <Translate contentKey="diga1App.patient.gender">Gender</Translate>
            </span>
          </dt>
          <dd>{patientEntity.gender}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="diga1App.patient.email">Email</Translate>
            </span>
          </dt>
          <dd>{patientEntity.email}</dd>
          <dt>
            <span id="mobileNumber">
              <Translate contentKey="diga1App.patient.mobileNumber">Mobile Number</Translate>
            </span>
          </dt>
          <dd>{patientEntity.mobileNumber}</dd>
          <dt>
            <span id="langKey">
              <Translate contentKey="diga1App.patient.langKey">Lang Key</Translate>
            </span>
          </dt>
          <dd>{patientEntity.langKey}</dd>
          <dt>
            <span id="birthdate">
              <Translate contentKey="diga1App.patient.birthdate">Birthdate</Translate>
            </span>
          </dt>
          <dd>
            {patientEntity.birthdate ? <TextFormat value={patientEntity.birthdate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="invitationFrom">
              <Translate contentKey="diga1App.patient.invitationFrom">Invitation From</Translate>
            </span>
          </dt>
          <dd>{patientEntity.invitationFrom}</dd>
          <dt>
            <span id="mobileAppInstalled">
              <Translate contentKey="diga1App.patient.mobileAppInstalled">Mobile App Installed</Translate>
            </span>
          </dt>
          <dd>{patientEntity.mobileAppInstalled ? 'true' : 'false'}</dd>
          <dt>
            <span id="realm">
              <Translate contentKey="diga1App.patient.realm">Realm</Translate>
            </span>
          </dt>
          <dd>{patientEntity.realm}</dd>
          <dt>
            <span id="preferences">
              <Translate contentKey="diga1App.patient.preferences">Preferences</Translate>
            </span>
          </dt>
          <dd>{patientEntity.preferences}</dd>
          <dt>
            <Translate contentKey="diga1App.patient.clinic">Clinic</Translate>
          </dt>
          <dd>
            {patientEntity.clinics
              ? patientEntity.clinics.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {patientEntity.clinics && i === patientEntity.clinics.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/patient" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/patient/${patientEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ patient }: IRootState) => ({
  patientEntity: patient.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetail);
