import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { DropDownToggle } from 'reactstrap';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <MenuItem icon="asterisk" to="/clinic" id="clinic">
      <Translate contentKey="global.menu.entities.clinic" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/medical-assistant" id="medical-assistant">
      <Translate contentKey="global.menu.entities.medicalAssistant" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/patient" id="patient">
      <Translate contentKey="global.menu.entities.patient" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/healthcare-professional" id="healthcare-professional">
      <Translate contentKey="global.menu.entities.healthcareProfessional" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/appointment" id="appointment">
      <Translate contentKey="global.menu.entities.appointment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/icd-code" id="icd-code">
      <Translate contentKey="global.menu.entities.icdCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/terms-and-conditions-content" id="terms-and-conditions-content">
      <Translate contentKey="global.menu.entities.termsAndConditionsContent" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-catalog-de" id="conversation-catalog-de">
      <Translate contentKey="global.menu.entities.conversationCatalogDe" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-catalog-tr" id="conversation-catalog-tr">
      <Translate contentKey="global.menu.entities.conversationCatalogTr" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-catalog-en" id="conversation-catalog-en">
      <Translate contentKey="global.menu.entities.conversationCatalogEn" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-event" id="conversation-event">
      <Translate contentKey="global.menu.entities.conversationEvent" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-summary" id="conversation-summary">
      <Translate contentKey="global.menu.entities.conversationSummary" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/insurance-code" id="insurance-code">
      <Translate contentKey="global.menu.entities.insuranceCode" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/scheduled-task" id="scheduled-task">
      <Translate contentKey="global.menu.entities.scheduledTask" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/scheduled-task-execution" id="scheduled-task-execution">
      <Translate contentKey="global.menu.entities.scheduledTaskExecution" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/self-registration" id="self-registration">
      <Translate contentKey="global.menu.entities.selfRegistration" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/tip-catalog">
      <Translate contentKey="global.menu.entities.tipCatalogEn" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/task-catalog">
      <Translate contentKey="global.menu.entities.taskCatalogEn" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-tip">
      <Translate contentKey="global.menu.entities.userTip" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-task">
      <Translate contentKey="global.menu.entities.userTask" />
    </MenuItem>
    {/* <MenuItem icon="asterisk" to="/catalog-notification">
      <Translate contentKey="global.menu.entities.catalogNotification" />
    </MenuItem> */}
    <MenuItem icon="asterisk" to="/tip-catalog-de">
      <Translate contentKey="global.menu.entities.tipCatalogDe" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/task-catalog-de">
      <Translate contentKey="global.menu.entities.taskCatalogDe" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/tip-catalog-tr">
      <Translate contentKey="global.menu.entities.tipCatalogTr" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/task-catalog-tr">
      <Translate contentKey="global.menu.entities.taskCatalogTr" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/notification-monitor">
      <Translate contentKey="global.menu.entities.notificationMonitor" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-activity">
      <Translate contentKey="global.menu.entities.userActivity" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-creation">
      <Translate contentKey="global.menu.entities.conversationCreation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/conversation-notification">
      <Translate contentKey="global.menu.entities.conversationNotification" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/self-registration">
      <Translate contentKey="global.menu.entities.selfRegistration" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/terms-and-condition">
      <Translate contentKey="global.menu.entities.termsAndCondition" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/application-version">
      <Translate contentKey="global.menu.entities.applicationVersion" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/score-calculation">
      <Translate contentKey="global.menu.entities.scoreCalculation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-preferences">
      <Translate contentKey="global.menu.entities.userPreferences" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/internal-api-logs">
      <Translate contentKey="global.menu.entities.internalApiLogs" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/external-app-info">
      <Translate contentKey="global.menu.entities.externalAppInfo" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-device-info">
      <Translate contentKey="global.menu.entities.userDeviceInfo" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/documents">
      <Translate contentKey="global.menu.entities.documents" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-notifications">
      <Translate contentKey="global.menu.entities.userNotifications" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/notification-settings">
      <Translate contentKey="global.menu.entities.notificationSettings" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/notification-template">
      <Translate contentKey="global.menu.entities.notificationTemplate" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
