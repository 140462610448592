import React from 'react';
import { IAppointment } from 'app/shared/model/appointment.model';
import { updateSelectedAppointment, updateSelectedPatient } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer';
import { connect } from 'react-redux';
import { translate, Translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { generateReport } from '../shard/reportUtil';
import { Button } from 'reactstrap';
import moment from 'moment';
import 'moment-timezone';
import { REALM } from '../../../shared/constants/realm.model';
import { APPOINTMENT_CONFIRMATION } from '../appointment-constants';

interface PatientAppointmentDetailsProps extends DispatchProps, StateProps {
    patientAppointmentDetails: IAppointment;
    isShowDate: boolean;
}

const MedicalAssistantAppointmentRow = (props: PatientAppointmentDetailsProps): any => {

    const appointment = props.patientAppointmentDetails;

    const printReport = (patientId) => {
        generateReport(patientId);
    };

    return (
        <tr style={{ textAlign: "center" }}>
            <td>
                {`${appointment.patient.firstName}`}
            </td>
            <td>
                {`${appointment.patient.lastName}`}
            </td>
            <td>
                {`${moment.tz(moment(appointment.date), 'Europe/Berlin').format('HH:mm')}`}
            </td>
            <td style={{
                color: appointment.confirmed === APPOINTMENT_CONFIRMATION.CONFIRMED ?
                    'green' :
                    appointment.confirmed === APPOINTMENT_CONFIRMATION.DECLINED ?
                        'red' : '#e6b800'

            }}>
                {`${appointment.confirmed === APPOINTMENT_CONFIRMATION.UNCONFIRMED ?
                     translate('diga1App.appointment.unconfirmed') : appointment.confirmed === APPOINTMENT_CONFIRMATION.DECLINED ?
                         translate('diga1App.appointment.decline') : translate('diga1App.appointment.confirmed')}`}
            </td>
            <td style={{ color: appointment.patient.realm === REALM.DIGA1 ? '#e6b800' : '#1a8cff' }}>
                {`${appointment.patient.realm}`}
            </td>
            <td
                style={
                    appointment.patient.mobileAppInstalled
                        ? { color: "green" }
                        : { color: "red" }
                }>
                {appointment.patient.mobileAppInstalled ? translate("maDashboard.general.active") : translate("maDashboard.general.notInstalled")}
            </td>
            <td style={{ cursor: "pointer" }}>
                {appointment.patient.mobileAppInstalled && (
                    <Button className="tableButton" onClick={() => printReport(appointment.patient.id)}>
                    <Translate contentKey="diga1App.patient.open">Open</Translate>
                </Button>
                )}
            </td>
        </tr>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    currentLocale: storeState.locale.currentLocale
});

const mapDispatchToProps = {
    updateSelectedAppointment,
    updateSelectedPatient
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantAppointmentRow);

