export interface IExternalAppInfo {
  id?: number;
  internalEntityId?: number;
  internalEntityType?: string;
  externalPatientId?: string;
  externalClinicId?: string;
  externalPhycianId?: string;
  externalAppName?: string;
}

export const defaultValue: Readonly<IExternalAppInfo> = {};
