import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField, AvFeedback } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster'; import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntitiesByRealm as getIcdCodes } from 'app/entities/icd-code/icd-code.reducer';
import { createPatientWithAppointment, reset } from 'app/entities/patient/patient.reducer';
import "../ma-pages.scss";
import { LanguageCode } from 'app/shared/languages/language-options';
import { updateHideCalendar } from '../ma-dashboard.reducer';
import { getDoctorsByQuery as getDoctorsByClinicId } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer'
import { getMedicalAssistantByAccountId } from 'app/entities/medical-assistant/medical-assistant.reducer'

import moment from 'moment';
import 'moment-timezone';
import { isSafari } from 'react-device-detect';
import { REALM } from 'app/shared/constants/realm.model';
export interface IMaDashPatientCreateUpdateProps extends StateProps, DispatchProps { }

const NOW = new Date().toJSON().split('T')[0];
const MAX_TIME_SINCE_TODAY = 10;

const reasonToICDCodesMap = {
    'Hoher Blutdruck': 'I10',
    'Übergewicht': 'E66',
    'Diabetes': 'E11',
    'High blood pressure': 'I10',
    'Obesity': 'E66',
};
const MaDashSelfRegistrationCreatePatient = (props: IMaDashPatientCreateUpdateProps) => {

    const { icdCodes } = props;

    const handleClose = () => {
        if (props.updateSuccess) {
            props.reset();
            window.history.back();
        }
    };

    useEffect(() => {
        props.reset();
        props.getIcdCodes(REALM.DIGA1_APP);
        props.updateHideCalendar(true);
    }, []);

    useEffect(() => {
        if (props.updateSuccess) {
            handleClose();
        }
    }, [props.updateSuccess]);

    useEffect(() => {
        props.selectedClinic ?
            props.getDoctorsByClinicId(`clinicId.equals=${props.selectedClinic.id}`) : undefined
    }, [props.selectedClinic && props.selectedClinic.length > 0])

    useEffect(() => {
        props.getMedicalAssistantByAccountId(props.account.id);
    }, [props.account])

    const saveEntity = (event, errors, { patient, appointment, selectedDoctorId }) => {
        if (errors.length === 0) {

            if (appointment.icdCode.id.code !== undefined && appointment.icdCode.id.description !== undefined) {
                const icdCode = { id: appointment.icdCode.id.id }
                appointment.icdCode = icdCode
            }
            const invitationFromDoctor = props.maSelectedClinicDoctors.filter(doctor => doctor.id.toString() === selectedDoctorId)[0];

            patient.invitationFrom = `${invitationFromDoctor.salutation} ${invitationFromDoctor.firstName} ${invitationFromDoctor.lastName}`;
            patient.preferences = '{"patientSafty": "false"}';
            patient.clinics = [props.selectedClinic];
            patient.realm = REALM.DIGA1;
            patient.mobileAppInstalled  = false;
            const momentObj = moment(appointment.date + appointment.time, 'YYYY-MM-DDLT');
            appointment.date = moment.tz(momentObj.format('YYYY-MM-DDTHH:mm:ss'), 'Europe/Berlin');

            appointment.clinic = props.selectedClinic;
            appointment.confirmed = "Not Confirmed";
            appointment.healthcareProfessional = invitationFromDoctor;
            props.createPatientWithAppointment({ patient, appointment });
        }
    };

    const getMaxDateForAppointment = () => {
        const fiveYearsAgoFromNow = new Date();
        fiveYearsAgoFromNow.setFullYear(fiveYearsAgoFromNow.getFullYear() + MAX_TIME_SINCE_TODAY);

        return fiveYearsAgoFromNow.toJSON().split('T')[0];
    };

    const getPatientToCreateGender = () : string => {
        switch ( props.selectedPatientToCreate.gender ){
            case 'Female':
            case 'Weiblich':
                return translate('diga1App.gender.female');
            case 'Male': 
            case 'Männlich':
                return translate('diga1App.gender.male');
            case 'Divers':
                return translate('diga1App.gender.other');
            default: 
                return translate('diga1App.gender.male');
        }
    }
    return (
        <Col className="page-container">
            <Row>
                <Col>
                    <h2 id="diga1App.patient.home.createLabel">
                        <Translate contentKey="diga1App.patient.home.createLabel">DiGA1 invitation</Translate>
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.loading ? (
                        <p>Loading...</p>
                    ) : (
                        <AvForm onSubmit={saveEntity}>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="salutationLabel" for="patient-salutation">
                                            <Translate contentKey="diga1App.patient.salutation">Salutation</Translate>
                                        </Label>
                                        <AvInput
                                            id="patient-salutation"
                                            type="select"
                                            className="form-control"
                                            name="patient.salutation"
                                            value={props.selectedPatientToCreate?.gender === 'Female' || props.selectedPatientToCreate?.gender === 'Weiblich' ?
                                                translate('diga1App.salutation.ms') : translate('diga1App.salutation.mr')}
                                        >
                                            <option value={translate('diga1App.salutation.mr')}>{translate('diga1App.salutation.mr')}</option>
                                            <option value={translate('diga1App.salutation.ms')}>{translate('diga1App.salutation.ms')}</option>
                                        </AvInput>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label id="titleLabel" for="patient-title">
                                            <Translate contentKey="diga1App.patient.title">Title</Translate>
                                        </Label>
                                        <AvField
                                            id="patient-title"
                                            type="text"
                                            name="patient.title"
                                            validate={{
                                                maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="firstNameLabel" for="patient-firstName">
                                            <Translate contentKey="diga1App.patient.firstName">First Name</Translate>*
                                        </Label>
                                        <AvField
                                            id="patient-firstName"
                                            type="text"
                                            name="patient.firstName"
                                            value={props.selectedPatientToCreate?.firstName ? props.selectedPatientToCreate?.firstName : undefined}
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label id="lastNameLabel" for="patient-lastName">
                                            <Translate contentKey="diga1App.patient.lastName">Last Name</Translate>*
                                        </Label>
                                        <AvField
                                            id="patient-lastName"
                                            type="text"
                                            name="patient.lastName"
                                            value={props.selectedPatientToCreate?.lastName ? props.selectedPatientToCreate?.lastName : undefined}
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="genderLabel" for="patient-gender">
                                            <Translate contentKey="diga1App.patient.gender">Gender</Translate>
                                        </Label>
                                        <AvInput
                                            id="patient-gender"
                                            type="select"
                                            className="form-control"
                                            name="patient.gender"
                                            value={!props.selectedPatientToCreate?.gender ? translate('diga1App.gender.male') : getPatientToCreateGender()  }
                                        >
                                            <option value={translate('diga1App.gender.male')}>{translate('diga1App.gender.male')}</option>
                                            <option value={translate('diga1App.gender.female')}>{translate('diga1App.gender.female')}</option>
                                            <option value={translate('diga1App.gender.other')}>{translate('diga1App.gender.other')}</option>
                                        </AvInput>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label id="emailLabel" for="patient-email">
                                            <Translate contentKey="diga1App.patient.email">Email</Translate>*
                                        </Label>
                                        <AvField
                                            id="patient-email"
                                            type="email"
                                            name="patient.email"
                                            value={props.selectedPatientToCreate?.email ? props.selectedPatientToCreate?.email : undefined}
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                    errorMessage: translate('global.messages.validate.email.invalid')
                                                }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="mobileNumberLabel" for="patient-mobileNumber">
                                            <Translate contentKey="diga1App.patient.mobileNumber">Mobile Number</Translate>*
                                        </Label>
                                        <AvField
                                            id="patient-mobileNumber"
                                            type="text"
                                            placeholder={translate('global.messages.validate.mobileNumber.placeHolder')}
                                            name="patient.mobileNumber"
                                            value={props.selectedPatientToCreate?.mobileNumber ? props.selectedPatientToCreate?.mobileNumber : undefined}
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                pattern: {
                                                    value: '^([0-9]{2,})$',
                                                    errorMessage: translate('global.messages.validate.mobileNumber.invalidPattern')
                                                },
                                                maxLength: { value: 20, errorMessage: translate('global.messages.validate.mobileNumber.maxlength', { max: 20 }) }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label id="birthdateLabel" for="patient-birthdate">
                                            {!isSafari ?
                                                <Translate contentKey="diga1App.selfRegistration.birthdate">Birthdate</Translate> :
                                                <Translate contentKey="diga1App.selfRegistration.birthdateWithFormat">Birthdate (dd/mm/yyyy / yyyy-mm-dd)</Translate>
                                            }*
                                        </Label>
                                        <AvField
                                            id="patient-birthdate"
                                            type="date"
                                            className="form-control"
                                            name="patient.birthdate"
                                            value={props.selectedPatientToCreate?.birthdate ? props.selectedPatientToCreate?.birthdate : undefined}
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                dateRange: {
                                                    start: { value: -130, units: 'years', },
                                                    end: { value: -18, units: 'years', },
                                                    errorMessage: translate('global.messages.validate.birthdate.invalidAge')
                                                }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="appointment-healthcareProfessional">
                                            <Translate contentKey="diga1App.appointment.healthcareProfessional">Healthcare Professional</Translate>
                                        </Label>
                                        <AvInput
                                            id="appointment-healthcareProfessional"
                                            type="select"
                                            className="form-control"
                                            name="selectedDoctorId"
                                            value={
                                                props.maSelectedClinicDoctors &&
                                                props.maSelectedClinicDoctors[0] &&
                                                props.maSelectedClinicDoctors[0].id &&
                                                props.maSelectedClinicDoctors[0].id.toString()
                                            }
                                            required
                                        >
                                            {props.maSelectedClinicDoctors
                                                ? props.maSelectedClinicDoctors.map(otherEntity => (
                                                    <option value={`${otherEntity.id}`} key={otherEntity.id}>
                                                        {`${otherEntity.salutation} ${otherEntity.title} ${otherEntity.firstName} ${otherEntity.lastName}`}
                                                    </option>
                                                ))
                                                : ''}
                                        </AvInput>
                                        <AvFeedback>
                                            <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                        </AvFeedback>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label for="appointment-icdCode">
                                            <Translate contentKey="diga1App.appointment.icdCode">Icd Code</Translate>
                                        </Label>
                                        <AvInput id="patient-icdCode" type="select" className="form-control" name="appointment.icdCode.id"
                                            value={icdCodes?.find((c) => c.code === reasonToICDCodesMap?.[props.selectedPatientToCreate.reason])?.id ?? icdCodes[icdCodes.length - 1]}>
                                            {icdCodes
                                                ? icdCodes.map(otherEntity => (
                                                    <option value={otherEntity.id} key={otherEntity.id}>
                                                        {`${otherEntity.code}  -   ${otherEntity.description}`}
                                                    </option>
                                                )).reverse()
                                                : ''}
                                        </AvInput>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="dateLabel" for="appointment-date">
                                            {!isSafari ?
                                                <Translate contentKey="diga1App.patient.appointmentDate">Appointment Date</Translate> :
                                                <Translate contentKey="diga1App.patient.appointmentWithFormat">Appointment Date (yyyy-mm-dd)</Translate>
                                            }*
                                        </Label>
                                        <AvField
                                            id="appointment-date"
                                            type="date"
                                            className="form-control"
                                            name="appointment.date"
                                            min={NOW}
                                            max={getMaxDateForAppointment()}
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                dateRange: { start: { value: -1, units: 'days' }, end: { value: 10, units: 'years' } }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col xs={7}>
                                            <AvGroup>
                                                <Label id="timeLabel" for="appointment-time">
                                                    <Translate contentKey="diga1App.appointment.time">Time</Translate>*
                                                </Label>
                                                <AvField
                                                    id="appointment-time"
                                                    type="time"
                                                    name="appointment.time"
                                                    placeholder="HH:MM"
                                                    pattern="[0-9]{2}:[0-9]{2}"
                                                    validate={{
                                                        required: { value: true, errorMessage: translate('entity.validation.required') },
                                                        maxLength: { value: 5 },
                                                        pattern: {
                                                            value: '^(20|21|22|23|[01]\\d|\\d)((:[0-5]\\d){1})$',
                                                            errorMessage: translate('global.messages.validate.appointment.invalidTime')
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                        <Col xs={5}>
                                            <AvGroup>
                                                <Label id="langKeyLabel" for="patient-langKey">
                                                    <Translate contentKey="diga1App.patient.langKey">Lang Key</Translate>
                                                </Label>
                                                <AvInput
                                                    id="patient-langKey"
                                                    type="select"
                                                    name="patient.langKey"
                                                    value={props.selectedPatientToCreate?.langKey ? props.selectedPatientToCreate?.langKey : LanguageCode.GERMAN}
                                                    validate={{
                                                        required: { value: true, errorMessage: translate('entity.validation.required') },
                                                        minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                                                        maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                                                    }}
                                                >
                                                    <option value={LanguageCode.GERMAN}>{translate('global.form.languageKey.german')}</option>
                                                    <option value={LanguageCode.ENGLISH}>{translate('global.form.languageKey.english')}</option>
                                                    <option value={LanguageCode.TURKISH}>{translate('global.form.languageKey.turkish')}</option>
                                                </AvInput>
                                            </AvGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} />
                                <Col xs={5}>
                                    <div className="wizard-form-in-button">
                                        <Button color="primary" id="save-entity" type="submit" disabled={props.updating}>
                                            <FontAwesomeIcon icon="save" />
                                      &nbsp;
                                      <Translate contentKey="entity.action.send">Send</Translate>
                                        </Button>
                                    </div>
                                </Col>
                                <Col />
                            </Row>

                        </AvForm>
                    )}
                </Col>
            </Row>
        </Col>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    icdCodes: storeState.icdCode.entitiesByApp,
    selectedClinic: storeState.medicalAssistant.maClinic,
    maSelectedClinicDoctors: storeState.maDashboardReducer.maSelectedClinicDoctors,
    selectedPatientToCreate: storeState.maDashboardReducer.selectedPatient,
    loading: storeState.patient.loading,
    updating: storeState.patient.updating,
    updateSuccess: storeState.patient.updateSuccess,
    currentLocale: storeState.locale.currentLocale,
    account: storeState.authentication.account
});

const mapDispatchToProps = {
    getIcdCodes,
    createPatientWithAppointment,
    reset,
    updateHideCalendar,
    getDoctorsByClinicId,
    getMedicalAssistantByAccountId
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MaDashSelfRegistrationCreatePatient);