import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, getEntitiesWithFilters } from './notification-settings.reducer';
import { getEntities as getTemplateEntities } from './../notification-template/notification-template.reducer';
import { INotificationSettings } from 'app/shared/model/notification-settings.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import SearchInputText from 'app/shared/input-text/search-input-text/search-input-text';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface INotificationSettingsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const NotificationSettings = (props: INotificationSettingsProps) => {
  const [filters, setFilters] = useState({
    name: '',
    notificationTemplateId: '',
    channel: '',
    rule: '',
    notificationCategory: ''
  });
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    const query = Object.keys(filters)
      .filter(k => filters[k] !== '')
      .map(k => {
        if (k === 'name' || k === 'channel' || k === 'notificationCategory' || k === 'rule') {
          return `${k}.contains=${filters[k]}`;
        }
        return `${k}.equals=${filters[k]}`;
      })
      .join('&');
    props.getEntitiesWithFilters(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      query
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  useEffect(() => {
    sortEntities();
  }, [filters.name, filters.channel, filters.notificationCategory, filters.notificationTemplateId, filters.rule]);

  useEffect(() => {
    props.getTemplateEntities();
  }, []);

  const { notificationSettingsList, match, loading, totalItems, notificationTemplateList } = props;
  return (
    <div>
      <h2 id="notification-settings-heading">
        <Translate contentKey="diga1App.notificationSettings.home.title">Notification Settings</Translate>
        <Link to={`${match.url}/new`} className="btn btn-outline-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.notificationSettings.home.createLabel">Create new Notification Settings</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        {
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="diga1App.notificationSettings.name">Name</Translate>
                  <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        name: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('notificationCategory')}>
                  <Translate contentKey="diga1App.notificationSettings.notificationCategory">Notification Category</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        notificationCategory: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('rule')}>
                  <Translate contentKey="diga1App.notificationSettings.rule">Rule</Translate>
                  <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        rule: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('notificationTemplateId')}>
                  <Translate contentKey="diga1App.notificationSettings.notificationTemplateId">Notification Template Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        notificationTemplateId: text
                      });
                    }}
                    column
                  />
                </th>
                <th className="hand" onClick={sort('channel')}>
                  <Translate contentKey="diga1App.notificationSettings.channel">Channel</Translate>
                  <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={text => {
                      setFilters({
                        ...filters,
                        channel: text
                      });
                    }}
                    column
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {!loading && notificationSettingsList && notificationSettingsList.length === 0 && (
                <tr>
                  <td colSpan={6}>
                    <div className="alert alert-warning">
                      <Translate contentKey="diga1App.notificationSettings.home.notFound">No Notification Settings found</Translate>
                    </div>
                  </td>
                </tr>
              )}
              {notificationSettingsList.map((notificationSettings, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${notificationSettings.id}`} color="link" size="sm">
                      {notificationSettings.id}
                    </Button>
                  </td>
                  <td>{notificationSettings.name}</td>
                  <td>{notificationSettings.notificationCategory}</td>
                  <td>{notificationSettings.rule}</td>
                  <td>
                    {notificationSettings.notificationTemplateId} -{' '}
                    {notificationTemplateList?.find(t => t.id === notificationSettings.notificationTemplateId)?.name}
                  </td>
                  <td>{notificationSettings.channel}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${notificationSettings.id}`} outline className="mr-1" color="success" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${notificationSettings.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      {props.isAdmin && (
                        <Button
                          tag={Link}
                          to={`${match.url}/${notificationSettings.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="danger"
                          size="sm"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
      </div>
      {props.totalItems ? (
        <div className={notificationSettingsList && notificationSettingsList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ authentication, notificationSettings, notificationTemplate }: IRootState) => ({
  notificationSettingsList: notificationSettings.entities,
  notificationTemplateList: notificationTemplate.entities,
  loading: notificationSettings.loading,
  totalItems: notificationSettings.totalItems,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN])
});

const mapDispatchToProps = {
  getEntities,
  getEntitiesWithFilters,
  getTemplateEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings);
