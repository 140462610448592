import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './notification-settings.reducer';
import { getEntities as getTemplateEntities } from './../notification-template/notification-template.reducer';

import { INotificationSettings } from 'app/shared/model/notification-settings.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface INotificationSettingsUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const NotificationSettingsUpdate = (props: INotificationSettingsUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { notificationSettingsEntity, loading, updating, notificationTemplateList } = props;

  const handleClose = () => {
    props.history.push('/notification-settings' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    props.getTemplateEntities();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...notificationSettingsEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="diga1App.notificationSettings.home.createOrEditLabel">
            <Translate contentKey="diga1App.notificationSettings.home.createOrEditLabel">Create or edit a NotificationSettings</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : notificationSettingsEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="notification-settings-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="notification-settings-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="nameLabel" for="notification-settings-name">
                  <Translate contentKey="diga1App.notificationSettings.name">Name</Translate>
                </Label>
                <AvField id="notification-settings-name" type="text" name="name" />
              </AvGroup>
              <AvGroup>
                <Label id="notificationTemplateIdLabel" for="notification-settings-notificationTemplateId">
                  <Translate contentKey="diga1App.notificationSettings.notificationTemplateId">Notification Template Id</Translate>
                </Label>
                <AvInput
                  id="notification-settings-notificationTemplateId"
                  type="select"
                  className="form-control"
                  name="notificationTemplateId"
                  value={notificationSettingsEntity.notificationTemplateId}
                >
                  <option value="" key="0" />
                  {notificationTemplateList
                    ? notificationTemplateList.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id} - {otherEntity.name}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="channelLabel" for="notification-settings-channel">
                  <Translate contentKey="diga1App.notificationSettings.channel">Channel</Translate>
                </Label>
                <AvField id="notification-settings-channel" type="text" name="channel" />
              </AvGroup>
              <AvGroup>
                <Label id="ruleLabel" for="notification-settings-rule">
                  <Translate contentKey="diga1App.notificationSettings.rule">Rule</Translate>
                </Label>
                <AvField id="notification-settings-rule" type="text" name="rule" />
              </AvGroup>
              <AvGroup>
                <Label id="notificationCategoryLabel" for="notification-settings-notificationCategory">
                  <Translate contentKey="diga1App.notificationSettings.notificationCategory">Notification Category</Translate>
                </Label>
                <AvField id="notification-settings-notificationCategory" type="text" name="notificationCategory" />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/notification-settings" className="rounded" replace color="success">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  notificationSettingsEntity: storeState.notificationSettings.entity,
  loading: storeState.notificationSettings.loading,
  updating: storeState.notificationSettings.updating,
  updateSuccess: storeState.notificationSettings.updateSuccess,
  notificationTemplateList: storeState.notificationTemplate.entities
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getTemplateEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsUpdate);
