import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ITaskCatalogTr, defaultValue } from 'app/shared/model/task-catalog-tr.model';

export const ACTION_TYPES = {
  FETCH_TASKCATALOGTR_LIST: 'taskCatalogTr/FETCH_TASKCATALOGTR_LIST',
  FETCH_TASKCATALOGTR: 'taskCatalogTr/FETCH_TASKCATALOGTR',
  CREATE_TASKCATALOGTR: 'taskCatalogTr/CREATE_TASKCATALOGTR',
  UPDATE_TASKCATALOGTR: 'taskCatalogTr/UPDATE_TASKCATALOGTR',
  DELETE_TASKCATALOGTR: 'taskCatalogTr/DELETE_TASKCATALOGTR',
  RESET: 'taskCatalogTr/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITaskCatalogTr>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type TaskCatalogTrState = Readonly<typeof initialState>;

// Reducer

export default (state: TaskCatalogTrState = initialState, action): TaskCatalogTrState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TASKCATALOGTR_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TASKCATALOGTR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_TASKCATALOGTR):
    case REQUEST(ACTION_TYPES.UPDATE_TASKCATALOGTR):
    case REQUEST(ACTION_TYPES.DELETE_TASKCATALOGTR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_TASKCATALOGTR_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TASKCATALOGTR):
    case FAILURE(ACTION_TYPES.CREATE_TASKCATALOGTR):
    case FAILURE(ACTION_TYPES.UPDATE_TASKCATALOGTR):
    case FAILURE(ACTION_TYPES.DELETE_TASKCATALOGTR):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_TASKCATALOGTR_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_TASKCATALOGTR):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_TASKCATALOGTR):
    case SUCCESS(ACTION_TYPES.UPDATE_TASKCATALOGTR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_TASKCATALOGTR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/task-catalog-trs';

// Actions

export const getEntities: ICrudGetAllAction<ITaskCatalogTr> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TASKCATALOGTR_LIST,
    payload: axios.get<ITaskCatalogTr>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<ITaskCatalogTr> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TASKCATALOGTR,
    payload: axios.get<ITaskCatalogTr>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<ITaskCatalogTr> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TASKCATALOGTR,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ITaskCatalogTr> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TASKCATALOGTR,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITaskCatalogTr> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TASKCATALOGTR,
    payload: axios.delete(requestUrl),
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
