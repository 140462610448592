import { IClinic } from 'app/shared/model/clinic.model';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import Axios from 'axios';
import { IHealthcareProfessional } from 'app/shared/model/healthcare-professional.model';
import { IAppointment } from 'app/shared/model/appointment.model';
import { defaultValue, IPatient } from 'app/shared/model/patient.model';

export const ACTION_TYPES = {
  UPDATE_SELECTED_DATE: 'maDashboard/UPDATE_SELECTED_DATE',
  UPDATE_SELECTED_CLINIC: 'maDashboard/UPDATE_SELECTED_CLINIC',
  UPDATE_SELECTED_APPOINTMENT: 'maDashboard/UPDATE_SELECTED_APOPINTMENT',
  UPDATE_SELECTED_PATIENT: 'maDashboard/UPDATE_SELECTED_PATIENT',
  FETCH_HEALTHCAREPROFESSIONAL_LIST: 'maDashboard/FETCH_HEALTHCAREPROFESSIONAL_LIST',
  FETCH_APPOINTMENT_LIST: 'maDashboard/FETCH_APPOINTMENT_LIST',
  FETCH_PATIENT_LIST: 'maDashboard/FETCH_PATIENT_LIST',
  HIDE_CALENDAR: 'maDashboard/HIDE_CALENDAR',
  RESET: 'maDashboard/RESET',
  FETCH_CLINIC_BY_MEDICAL_ASSISTANT_USER_ID: 'maDashboard/FETCH_CLINIC_BY_MEDICAL_ASSISTANT_USER_ID'
};

const initialState = {
  selectedDate: new Date(),
  selectedClinic: null,
  selectedAppointment: null,
  selectedPatient: null,
  maSelectedClinicDoctors: null,
  maSelectedClinicAppointments: null,
  maSelectedClinicPatients: null,
  hideCalendar: false,
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  mfaClinicId: null
};

export type MedicalAssistantDashboardState = {
  selectedDate: Date;
  selectedClinic: IClinic;
  selectedAppointment: IAppointment;
  selectedPatient: IPatient;
  maSelectedClinicDoctors: IHealthcareProfessional[];
  maSelectedClinicAppointments: IAppointment[];
  maSelectedClinicPatients: IPatient[];
  hideCalendar: boolean;
  loading: boolean;
  errorMessage: string;
  updateSuccess: boolean;
  mfaClinicId: number;
};

export default (state: MedicalAssistantDashboardState = initialState, action): MedicalAssistantDashboardState => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload
      };
    case ACTION_TYPES.UPDATE_SELECTED_CLINIC:
      return {
        ...state,
        selectedClinic: action.payload
      };
    case ACTION_TYPES.UPDATE_SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: action.payload
      };
    case ACTION_TYPES.UPDATE_SELECTED_PATIENT:
      return {
        ...state,
        selectedPatient: action.payload
      };

    case ACTION_TYPES.HIDE_CALENDAR:
      return {
        ...state,
        hideCalendar: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CLINIC_BY_MEDICAL_ASSISTANT_USER_ID):
      return {
        ...state,
        mfaClinicId: action.payload.data
      };
    case REQUEST(ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL_LIST):
    case REQUEST(ACTION_TYPES.FETCH_APPOINTMENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PATIENT_LIST):
      return {
        ...state,
        errorMessage: null,
        loading: true
      };

    case FAILURE(ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_APPOINTMENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PATIENT_LIST):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL_LIST):
      return {
        ...state,
        loading: false,
        maSelectedClinicDoctors: action.payload.data,
        updateSuccess: true
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPOINTMENT_LIST):
      return {
        ...state,
        loading: false,
        maSelectedClinicAppointments: action.payload.data,
        updateSuccess: true
      };
    case SUCCESS(ACTION_TYPES.FETCH_PATIENT_LIST):
      return {
        ...state,
        loading: false,
        maSelectedClinicPatients: action.payload.data,
        updateSuccess: true
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        selectedPatient: null
      };
    default:
      return state;
  }
};

export const updateSelectedDate = (selectedDate: Date) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_SELECTED_DATE,
    payload: selectedDate
  });
};

export const updateSelectedAppointment = (selectedAppopintmentToEdit: IAppointment) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_SELECTED_APPOINTMENT,
    payload: selectedAppopintmentToEdit
  });
};

export const updateSelectedPatient = (selectedPatientToEdit: IPatient) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_SELECTED_PATIENT,
    payload: selectedPatientToEdit
  });
};

export const getDoctorsByQuery = query => async dispatch => {
  const requestUrl = `/api/healthcare-professionals?sort=id,desc&${query}`;

  await dispatch({
    type: ACTION_TYPES.FETCH_HEALTHCAREPROFESSIONAL_LIST,
    payload: Axios.get<IHealthcareProfessional>(`${requestUrl}`)
  });
};

export const getPatientsByQuery = query => async dispatch => {
  const requestUrl = `/api/patients?sort=id,desc&${query}`;

  await dispatch({
    type: ACTION_TYPES.FETCH_PATIENT_LIST,
    payload: Axios.get<IPatient>(`${requestUrl}`)
  });
};

export const updateSelectedClinic = (selectedClinic: IClinic, page?, size?) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_SELECTED_CLINIC,
    payload: selectedClinic
  });

  dispatch(getDoctorsByQuery('clinicId.equals=' + selectedClinic.id));
  dispatch(getPatientsByQuery('clinicId.equals=' + selectedClinic.id + `&page=${page}&size=${size}`));
};

export const getAppointmentsByQuery = query => dispatch => {
  const requestUrl = `/api/appointments?sort=date,desc&${query}`;
  dispatch({
    type: ACTION_TYPES.FETCH_APPOINTMENT_LIST,
    payload: Axios.get<IAppointment>(`${requestUrl}`)
  });
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const updateHideCalendar = (isToHide: boolean) => dispatch => {
  dispatch({
    type: ACTION_TYPES.HIDE_CALENDAR,
    payload: isToHide
  });
};

export const getClinicByMedicalAssistantUserId = (medicalAssistantId: number) => dispatch => {
  const requestUrl = `/api/clinic/${medicalAssistantId}`;
  dispatch({
    type: ACTION_TYPES.FETCH_CLINIC_BY_MEDICAL_ASSISTANT_USER_ID,
    payload: Axios.get<number>(`${requestUrl}`)
  });
};
