import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, Row, NavItem, NavLink, Col } from 'reactstrap';
import { getEntitiesByQuery as getPatients } from 'app/entities/patient/patient.reducer';
import { getEntitiesByQuery as getPatientByAppointments } from 'app/entities/appointment/appointment.reducer';
import { updateHideCalendar } from '../ma-dashboard.reducer';

import PatientAppointmentsTable from './ma-appointments-patient-table'
import classnames from 'classnames';
import { translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';

import MaDashUpdatePatient from '../ma-patients/ma-update-patient'
import { useHistory } from 'react-router-dom';

export interface IChangePatientPage extends StateProps, DispatchProps { }

const ChangePatientPage = (props: IChangePatientPage) => {
    const [activeTab, setActiveTab] = useState('1');
    const history = useHistory();

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if (props.selectedClinic) {
            props.getPatients('clinicId.equals=' + props.selectedClinic);
            props.updateHideCalendar(true);
        } else {
            history.push('/ma-dashboard/Patients')
        }
    }, []);

    return (
        <div>
            <Nav className={"patients-tab"} tabs>
                <NavItem >
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                        style={{ borderBottom: "1px solid #d7d4d4" }}
                    >
                        {translate("maDashboard.patientArea.patientsTabTitle")}
                    </NavLink>
                </NavItem>
                <NavItem >
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                        style={{ borderBottom: "1px solid #d7d4d4" }}
                    >
                        {translate("maDashboard.patientArea.patientsAppointment")}
                    </NavLink>
                </NavItem>
            </Nav>
            <div>
                <TabContent activeTab={activeTab} className="page-container">
                    <TabPane tabId="1">
                        <MaDashUpdatePatient />
                    </TabPane>
                    <TabPane tabId="2">
                        <PatientAppointmentsTable />
                    </TabPane>
                </TabContent>
            </div>
        </div>
    );
};


const mapStateToProps = (storeState: IRootState) => ({
    selectedClinic: storeState.medicalAssistant.maClinicId,
    currentLocale: storeState.locale.currentLocale
});

const mapDispatchToProps = {
    getPatients,
    getPatientByAppointments,
    updateHideCalendar
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ChangePatientPage);
