export enum UserActivationStatus {
  EMAIL_SENT = 'email sent to the user',
  SMS_SENT = 'SMS sent to the user',
  SMS_VERIFIED = 'SMS Code Verified Successfully',
  SMS_NOT_VERIFIED = 'SMS Code not Verified',
  ACTIVATED = 'ACTIVATED',
  TERMS_AND_CONDITIONS_VERIFIED = 'Terms Verified',
  TERMS_AND_CONDITIONS_NOT_VERIFIED = 'Terms not Verified',
  CREATE_NEW_PASSWORD_VERIFIED = 'Create password Verified',
  CREATE_NEW_PASSWORD_NOT_VERIFIED = 'Create password not Verified',
  ACTIVATE_VERIFIED = 'ACTIVATED verified'
}

export enum TermsAndConditionsContentType {
  CONSENT = 'web-consent',
  Study = 'web-Study-consent',
  PRIVACY = 'web-data-privacy',
  PRIVACY_DIGA1 = 'web-data-privacy-diga1',
  TERMS_AND_CONDITIONS = 'web-terms-and-conditions',
  DATA_PROCESSING_FOR_DEVELOPMENT = 'data-processing-for-development'
}
