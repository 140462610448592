import { Moment } from 'moment';

export interface INotificationMonitor {
  id?: number;
  identification?: string;
  channel?: string;
  dateTime?: string;
  status?: string;
  reason?: string;
  details?: string;
}

export const defaultValue: Readonly<INotificationMonitor> = {};
