import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IApplicationVersion, defaultValue } from 'app/shared/model/application-version.model';

export const ACTION_TYPES = {
  FETCH_APPLICATIONVERSION_LIST: 'applicationVersion/FETCH_APPLICATIONVERSION_LIST',
  FETCH_APPLICATIONVERSION: 'applicationVersion/FETCH_APPLICATIONVERSION',
  CREATE_APPLICATIONVERSION: 'applicationVersion/CREATE_APPLICATIONVERSION',
  UPDATE_APPLICATIONVERSION: 'applicationVersion/UPDATE_APPLICATIONVERSION',
  DELETE_APPLICATIONVERSION: 'applicationVersion/DELETE_APPLICATIONVERSION',
  RESET: 'applicationVersion/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IApplicationVersion>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type ApplicationVersionState = Readonly<typeof initialState>;

// Reducer

export default (state: ApplicationVersionState = initialState, action): ApplicationVersionState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_APPLICATIONVERSION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_APPLICATIONVERSION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_APPLICATIONVERSION):
    case REQUEST(ACTION_TYPES.UPDATE_APPLICATIONVERSION):
    case REQUEST(ACTION_TYPES.DELETE_APPLICATIONVERSION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_APPLICATIONVERSION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_APPLICATIONVERSION):
    case FAILURE(ACTION_TYPES.CREATE_APPLICATIONVERSION):
    case FAILURE(ACTION_TYPES.UPDATE_APPLICATIONVERSION):
    case FAILURE(ACTION_TYPES.DELETE_APPLICATIONVERSION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPLICATIONVERSION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_APPLICATIONVERSION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_APPLICATIONVERSION):
    case SUCCESS(ACTION_TYPES.UPDATE_APPLICATIONVERSION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_APPLICATIONVERSION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/application-versions';

// Actions

export const getEntitiesWithFilters = (page?, size?, sort?, filters = '') => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;

  if (filters) {
    requestUrl += `&${filters}`;
  }
  return {
    type: ACTION_TYPES.FETCH_APPLICATIONVERSION_LIST,
    payload: axios.get<IApplicationVersion>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntities: ICrudGetAllAction<IApplicationVersion> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_APPLICATIONVERSION_LIST,
    payload: axios.get<IApplicationVersion>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IApplicationVersion> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_APPLICATIONVERSION,
    payload: axios.get<IApplicationVersion>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IApplicationVersion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_APPLICATIONVERSION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IApplicationVersion> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_APPLICATIONVERSION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IApplicationVersion> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_APPLICATIONVERSION,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
