import React, { useEffect, useState } from 'react';
import MedicalAssistantAppointmentsTable from './ma-appointments-table';
import LineStripToggler from '../shard/line-strip-toggler/line-strip-toggler';
import { Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { getDoctorsByQuery, getAppointmentsByQuery, updateHideCalendar } from '../ma-dashboard.reducer';
import { getEntityByUser } from '../../../entities/medical-assistant/medical-assistant.reducer'
import { connect } from 'react-redux';

interface IMedicalAssistantAppointmentsDashboard extends StateProps, DispatchProps { }

const MedicalAssistantAppointmentsDashboard = (props: IMedicalAssistantAppointmentsDashboard) => {
    const docManImagePath = '../../content/images/doctor-man.svg';
    const docWomanImagePath = '../../content/images/doctor-woman.svg';
    const [isToShowTableList, setIsToShowTableList] = useState([]);

    useEffect(() => {
        if (props.selectedClinics?.length > 0) {
            const isToShowTableArray = [];

            if (props.maSelectedClinicDoctors && props.maSelectedClinicDoctors.length > 0) {
                props.maSelectedClinicDoctors.forEach(doctor => {
                    isToShowTableArray.push(true);
                });
                setIsToShowTableList(isToShowTableArray);
            }
        }

    }, [props.maSelectedClinicDoctors]);

    useEffect(() => {
        if (props.selectedClinics?.length > 0) {
            props.getAppointmentsByQuery('clinicId.equals=' + props.selectedClinics[0].id + '&page=0&size=1000');
            props.getDoctorsByQuery('clinicId.equals=' + props.selectedClinics[0].id);
        }
    }, [props.selectedClinics]);

    useEffect(() => {
        props.updateHideCalendar(false);
        props.getEntityByUser();
    },[])

    const updateShowTable = (togglerId: number, isOpen: boolean) => {
        const newDocsLsit = Object.assign({}, isToShowTableList);
        newDocsLsit[togglerId] = isOpen;
        setIsToShowTableList(newDocsLsit);
    };

    return (
            <Col md={6} lg={12}>
                <Row>
                    <Col>
                        <h2 id="healthcare-professional-heading"
                            style={{ marginBottom: "2rem" }}>
                            <Translate contentKey="diga1App.appointment.home.title">Appointments</Translate>
                        </h2>
                    </Col>
                </Row>
                {props.selectedClinics && props.maSelectedClinicDoctors && props.maSelectedClinicDoctors.length > 0
                    ? (
                        props.maSelectedClinicDoctors.map((doctor, index) =>
                            <div key={doctor.id}>
                                <Row>
                                    <Col md={9}>
                                        <LineStripToggler
                                            togglerId={index}
                                            text={` ${doctor.firstName} ${doctor.lastName}`}
                                            leftImagePath={doctor.gender === 'Male' ? docManImagePath : docWomanImagePath}
                                            updateFatherIsTogglerOpen={updateShowTable}
                                        />
                                        <br />
                                    </Col>
                                </Row>
                                {isToShowTableList && isToShowTableList[index]
                                    ?
                                    <Row>
                                        <Col>
                                            <MedicalAssistantAppointmentsTable selectedDoctorId={doctor.id}></MedicalAssistantAppointmentsTable>
                                        </Col>
                                    </Row>
                                    : null
                                }
                            </div>
                        )
                    ) : ''
                }
            </Col>

    );
};


const mapStateToProps = (storeState: IRootState) => ({
    selectedClinics: storeState?.medicalAssistant?.entity?.clinics,
    maSelectedClinicDoctors: storeState.maDashboardReducer.maSelectedClinicDoctors,
    currentLocale: storeState.locale.currentLocale
});

const mapDispatchToProps = {
    getDoctorsByQuery, getAppointmentsByQuery, updateHideCalendar, getEntityByUser
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantAppointmentsDashboard);
