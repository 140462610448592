import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ApplicationVersion from './application-version';
import ApplicationVersionDetail from './application-version-detail';
import ApplicationVersionUpdate from './application-version-update';
import ApplicationVersionDeleteDialog from './application-version-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ApplicationVersionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ApplicationVersionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ApplicationVersionDetail} />
      <ErrorBoundaryRoute path={match.url} component={ApplicationVersion} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ApplicationVersionDeleteDialog} />
  </>
);

export default Routes;
