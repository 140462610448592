import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './healthcare-professional.reducer';

export interface IHealthcareProfessionalDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const HealthcareProfessionalDetail = (props: IHealthcareProfessionalDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { healthcareProfessionalEntity } = props;
  return (
    <Row>
       <Col>
        <Row className="justify-content-center">
          <Col md={4} sm={8}>
            <h2>
              <Translate contentKey="diga1App.healthcareProfessional.detail.title">HealthcareProfessional</Translate> [
          <b>{healthcareProfessionalEntity.id}</b>]
        </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="userId">
              <Translate contentKey="diga1App.healthcareProfessional.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.userId}</dd>
          <dt>
            <span id="salutation">
              <Translate contentKey="diga1App.healthcareProfessional.salutation">Salutation</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.salutation}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="diga1App.healthcareProfessional.title">Title</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.title}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="diga1App.healthcareProfessional.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="diga1App.healthcareProfessional.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.lastName}</dd>
          <dt>
            <span id="gender">
              <Translate contentKey="diga1App.healthcareProfessional.gender">Gender</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.gender}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="diga1App.healthcareProfessional.email">Email</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.email}</dd>
          <dt>
            <span id="mobileNumber">
              <Translate contentKey="diga1App.healthcareProfessional.mobileNumber">Mobile Number</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.mobileNumber}</dd>
          <dt>
            <span id="langKey">
              <Translate contentKey="diga1App.healthcareProfessional.langKey">Lang Key</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.langKey}</dd>
          <dt>
            <span id="registrationNumber">
              <Translate contentKey="diga1App.healthcareProfessional.registrationNumber">Registration Number</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.registrationNumber}</dd>
          <dt>
            <span id="verificationStatus">
              <Translate contentKey="diga1App.healthcareProfessional.verificationStatus">Verification Status</Translate>
            </span>
          </dt>
          <dd>{healthcareProfessionalEntity.verificationStatus}</dd>
          <dt>
            <Translate contentKey="diga1App.healthcareProfessional.clinic">Clinic</Translate>
          </dt>
          <dd>
            {healthcareProfessionalEntity.clinics
              ? healthcareProfessionalEntity.clinics.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.name}</a>
                    {healthcareProfessionalEntity.clinics && i === healthcareProfessionalEntity.clinics.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/healthcare-professional" replace className="rounded" color="success" >
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
        &nbsp;
        <Button tag={Link} to={`/healthcare-professional/${healthcareProfessionalEntity.id}/edit`} replace className="rounded" color="danger">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ healthcareProfessional }: IRootState) => ({
  healthcareProfessionalEntity: healthcareProfessional.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareProfessionalDetail);
