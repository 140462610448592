import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IConversationCreation, defaultValue } from 'app/shared/model/conversation-creation.model';
import { isObject } from 'lodash';

export const ACTION_TYPES = {
  FETCH_CONVERSATIONCREATION_LIST: 'conversationCreation/FETCH_CONVERSATIONCREATION_LIST',
  FETCH_CONVERSATIONCREATION: 'conversationCreation/FETCH_CONVERSATIONCREATION',
  CREATE_CONVERSATIONCREATION: 'conversationCreation/CREATE_CONVERSATIONCREATION',
  UPDATE_CONVERSATIONCREATION: 'conversationCreation/UPDATE_CONVERSATIONCREATION',
  DELETE_CONVERSATIONCREATION: 'conversationCreation/DELETE_CONVERSATIONCREATION',
  RESET: 'conversationCreation/RESET',
  FETCH_CONVERSATION_TYPE_UNIQUE: 'conversationCreation/FETCH_CONVERSATION_TYPE_UNIQUE'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IConversationCreation>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  uniqueConversationType: [],
  uniqueConversationTypeObject: {}
};

export type ConversationCreationState = Readonly<typeof initialState>;

// Reducer

export default (state: ConversationCreationState = initialState, action): ConversationCreationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CONVERSATIONCREATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CONVERSATIONCREATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CONVERSATIONCREATION):
    case REQUEST(ACTION_TYPES.UPDATE_CONVERSATIONCREATION):
    case REQUEST(ACTION_TYPES.DELETE_CONVERSATIONCREATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CONVERSATIONCREATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CONVERSATIONCREATION):
    case FAILURE(ACTION_TYPES.CREATE_CONVERSATIONCREATION):
    case FAILURE(ACTION_TYPES.UPDATE_CONVERSATIONCREATION):
    case FAILURE(ACTION_TYPES.DELETE_CONVERSATIONCREATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONVERSATIONCREATION_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONVERSATIONCREATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CONVERSATIONCREATION):
    case SUCCESS(ACTION_TYPES.UPDATE_CONVERSATIONCREATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CONVERSATIONCREATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONVERSATION_TYPE_UNIQUE):
      return {
        ...state,
        uniqueConversationType: isObject(action.payload.data)
          ? Object.keys(action.payload.data).map(id => ({ id, name: action.payload.data[id] }))
          : [],
        uniqueConversationTypeObject: action.payload.data
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/conversation-creations';

// Actions

export const getEntities: ICrudGetAllAction<IConversationCreation> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONVERSATIONCREATION_LIST,
    payload: axios.get<IConversationCreation>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IConversationCreation> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CONVERSATIONCREATION,
    payload: axios.get<IConversationCreation>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IConversationCreation> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CONVERSATIONCREATION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IConversationCreation> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CONVERSATIONCREATION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IConversationCreation> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CONVERSATIONCREATION,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const getConversationCatalogUniqueTypes: ICrudGetAllAction<any> = () => {
  const requestUrl = `/api/conversation-catalog-unique-types`;
  return {
    type: ACTION_TYPES.FETCH_CONVERSATION_TYPE_UNIQUE,
    payload: axios.get(requestUrl)
  };
};

export const getEntitiesByCriteria = (searchValue, page, size, sort) => {
  const requestUrl = `${apiUrl}?${searchValue}&page=${page}&size=${size}&sort=${sort}`;
  return {
    type: ACTION_TYPES.FETCH_CONVERSATIONCREATION_LIST,
    payload: axios.get<IConversationCreation>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
