import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { INotificationMonitor, defaultValue } from 'app/shared/model/notification-monitor.model';

export const ACTION_TYPES = {
  FETCH_NOTIFICATIONMONITOR_LIST: 'notificationMonitor/FETCH_NOTIFICATIONMONITOR_LIST',
  FETCH_NOTIFICATIONMONITOR: 'notificationMonitor/FETCH_NOTIFICATIONMONITOR',
  CREATE_NOTIFICATIONMONITOR: 'notificationMonitor/CREATE_NOTIFICATIONMONITOR',
  UPDATE_NOTIFICATIONMONITOR: 'notificationMonitor/UPDATE_NOTIFICATIONMONITOR',
  DELETE_NOTIFICATIONMONITOR: 'notificationMonitor/DELETE_NOTIFICATIONMONITOR',
  RESET: 'notificationMonitor/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<INotificationMonitor>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type NotificationMonitorState = Readonly<typeof initialState>;

// Reducer

export default (state: NotificationMonitorState = initialState, action): NotificationMonitorState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATIONMONITOR_LIST):
    case REQUEST(ACTION_TYPES.FETCH_NOTIFICATIONMONITOR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_NOTIFICATIONMONITOR):
    case REQUEST(ACTION_TYPES.UPDATE_NOTIFICATIONMONITOR):
    case REQUEST(ACTION_TYPES.DELETE_NOTIFICATIONMONITOR):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATIONMONITOR_LIST):
    case FAILURE(ACTION_TYPES.FETCH_NOTIFICATIONMONITOR):
    case FAILURE(ACTION_TYPES.CREATE_NOTIFICATIONMONITOR):
    case FAILURE(ACTION_TYPES.UPDATE_NOTIFICATIONMONITOR):
    case FAILURE(ACTION_TYPES.DELETE_NOTIFICATIONMONITOR):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONMONITOR_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_NOTIFICATIONMONITOR):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_NOTIFICATIONMONITOR):
    case SUCCESS(ACTION_TYPES.UPDATE_NOTIFICATIONMONITOR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_NOTIFICATIONMONITOR):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/notification-monitors';

// Actions

export const getEntities: ICrudGetAllAction<INotificationMonitor> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATIONMONITOR_LIST,
    payload: axios.get<INotificationMonitor>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<INotificationMonitor> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_NOTIFICATIONMONITOR,
    payload: axios.get<INotificationMonitor>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<INotificationMonitor> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_NOTIFICATIONMONITOR,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<INotificationMonitor> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_NOTIFICATIONMONITOR,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<INotificationMonitor> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_NOTIFICATIONMONITOR,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const getNotificationMonitorsByCriteria = searchValue => {
  let requestUrl = `${apiUrl}`;

  if (searchValue !== undefined) requestUrl = requestUrl + `${`/criteria?search.contains=${searchValue}`}`;

  return {
    type: ACTION_TYPES.FETCH_NOTIFICATIONMONITOR_LIST,
    payload: axios.get<INotificationMonitor>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};
