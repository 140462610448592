import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import activationChoosePassword, {
  ActivationChoosePasswordState
} from 'app/modules/account/activate-choose-password/activate-choose-password.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import healthcareProfessional, { HealthcareProfessionalState } from 'app/entities/healthcare-professional/healthcare-professional.reducer';
import medicalAssistant, { MedicalAssistantState } from 'app/entities/medical-assistant/medical-assistant.reducer';
import verifySmsReducer, { SmsVerificationState } from 'app/modules/account/sms-code-verification/sms-code-verification.reducer';
import maDashboardReducer, { MedicalAssistantDashboardState } from '../../pages/medical-assistant-dashboard/ma-dashboard.reducer';
import appointment, { AppointmentState } from 'app/entities/appointment/appointment.reducer';
import conversationEvent, { ConversationEventState } from 'app/entities/conversation-event/conversation-event.reducer';
import conversationSummary, { ConversationSummaryState } from 'app/entities/conversation-summary/conversation-summary.reducer';
import chatbotReducer, { ChatbotState } from 'app/pages/chatbot/chatbot.reducer';
import contactUsReducer, { ContactUsState } from 'app/pages/medical-assistant-dashboard/shard/contact-us/contact-us-reducer';
import termsAndConditions, { TermsAndConditionsState } from 'app/modules/account/terms-and-conditions/terms-and-conditions.reducer';
import termsAndConditionsContent, {
  TermsAndConditionsContentState
} from 'app/entities/terms-and-conditions-content/terms-and-conditions-content.reducer';
import conversationCatalogDe, { ConversationCatalogDeState } from 'app/entities/conversation-catalog-de/conversation-catalog-de.reducer';
import conversationCatalogEn, { ConversationCatalogEnState } from 'app/entities/conversation-catalog-en/conversation-catalog-en.reducer';
import conversationCatalogTr, { ConversationCatalogTrState } from 'app/entities/conversation-catalog-tr/conversation-catalog-tr.reducer';
// prettier-ignore
import insuranceCode, {
  InsuranceCodeState
} from 'app/entities/insurance-code/insurance-code.reducer';
// prettier-ignore
import scheduledTask, {
  ScheduledTaskState
} from 'app/entities/scheduled-task/scheduled-task.reducer';
// prettier-ignore
import scheduledTaskExecution, {
  ScheduledTaskExecutionState
} from 'app/entities/scheduled-task-execution/scheduled-task-execution.reducer';
import generateDocumentReducer, { GenerateDocumentState } from 'app/modules/home/generate-document/generate-document-page-reducer';
// prettier-ignore
import selfRegistration, {
  SelfRegistrationState
} from 'app/entities/self-registration/self-registration.reducer';
// prettier-ignore
// prettier-ignore
import clinic, {
  ClinicState
} from 'app/entities/clinic/clinic.reducer';
// prettier-ignore
import icdCode, {
  IcdCodeState
} from 'app/entities/icd-code/icd-code.reducer';
// prettier-ignore
import patient, {
  PatientState
} from 'app/entities/patient/patient.reducer';
import maPatientSection, { MAPatientSectionState } from 'app/pages/medical-assistant-dashboard/ma-patient-section.reducer';
// prettier-ignore
import tipCatalog, {
  TipCatalogState
} from 'app/entities/tip-catalog/tip-catalog.reducer';
// prettier-ignore
import taskCatalog, {
  TaskCatalogState
} from 'app/entities/task-catalog/task-catalog.reducer';
// prettier-ignore
import userTip, {
  UserTipState
} from 'app/entities/user-tip/user-tip.reducer';
// prettier-ignore
import userTask, {
  UserTaskState
} from 'app/entities/user-task/user-task.reducer';
// prettier-ignore
import catalogNotification, {
  CatalogNotificationState
} from 'app/entities/catalog-notification/catalog-notification.reducer';
// prettier-ignore
import tipCatalogDe, {
  TipCatalogDeState
} from 'app/entities/tip-catalog-de/tip-catalog-de.reducer';
// prettier-ignore
import taskCatalogDe, {
  TaskCatalogDeState
} from 'app/entities/task-catalog-de/task-catalog-de.reducer';
// prettier-ignore
import tipCatalogTr, {
  TipCatalogTrState
} from 'app/entities/tip-catalog-tr/tip-catalog-tr.reducer';
// prettier-ignore
import taskCatalogTr, {
  TaskCatalogTrState
} from 'app/entities/task-catalog-tr/task-catalog-tr.reducer';
// prettier-ignore
import notificationMonitor, {
  NotificationMonitorState
} from 'app/entities/notification-monitor/notification-monitor.reducer';
// prettier-ignore
import userActivity, {
  UserActivityState
} from 'app/entities/user-activity/user-activity.reducer';
// prettier-ignore
import conversationCreation, {
  ConversationCreationState
} from 'app/entities/conversation-creation/conversation-creation.reducer';
// prettier-ignore
import conversationNotification, {
  ConversationNotificationState
} from 'app/entities/conversation-notification/conversation-notification.reducer';
// prettier-ignore
import termsAndCondition, {
  TermsAndConditionState
} from 'app/entities/terms-and-condition/terms-and-condition.reducer';
// prettier-ignore
import applicationVersion, {
  ApplicationVersionState
} from 'app/entities/application-version/application-version.reducer';
// prettier-ignore
import scoreCalculation, {
  ScoreCalculationState
} from 'app/entities/score-calculation/score-calculation.reducer';
// prettier-ignore
import userPreferences, {
  UserPreferencesState
} from 'app/entities/user-preferences/user-preferences.reducer';
// prettier-ignore
import internalApiLogs, {
  InternalApiLogsState
} from 'app/entities/internal-api-logs/internal-api-logs.reducer';
// prettier-ignore
import externalAppInfo, {
  ExternalAppInfoState
} from 'app/entities/external-app-info/external-app-info.reducer';
// prettier-ignore
import userDeviceInfo, {
  UserDeviceInfoState
} from 'app/entities/user-device-info/user-device-info.reducer';
// prettier-ignore
import documents, {
  DocumentsState
} from 'app/entities/documents/documents.reducer';
// prettier-ignore
import userNotifications, {
  UserNotificationsState
} from 'app/entities/user-notifications/user-notifications.reducer';
// prettier-ignore
import notificationSettings, {
  NotificationSettingsState
} from 'app/entities/notification-settings/notification-settings.reducer';
// prettier-ignore
import notificationTemplate, {
  NotificationTemplateState
} from 'app/entities/notification-template/notification-template.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly termsAndConditions: TermsAndConditionsState;
  readonly activationChoosePassword: ActivationChoosePasswordState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly clinic: ClinicState;
  readonly medicalAssistant: MedicalAssistantState;
  readonly patient: PatientState;
  readonly maPatientSection: MAPatientSectionState;
  readonly healthcareProfessional: HealthcareProfessionalState;
  readonly appointment: AppointmentState;
  readonly conversationEvent: ConversationEventState;
  readonly conversationSummary: ConversationSummaryState;
  readonly icdCode: IcdCodeState;
  readonly termsAndConditionsContent: TermsAndConditionsContentState;
  readonly conversationCatalogDe: ConversationCatalogDeState;
  readonly conversationCatalogTr: ConversationCatalogTrState;
  readonly conversationCatalogEn: ConversationCatalogEnState;
  readonly insuranceCode: InsuranceCodeState;
  readonly scheduledTask: ScheduledTaskState;
  readonly scheduledTaskExecution: ScheduledTaskExecutionState;
  readonly selfRegistration: SelfRegistrationState;
  readonly tipCatalog: TipCatalogState;
  readonly taskCatalog: TaskCatalogState;
  readonly userTip: UserTipState;
  readonly userTask: UserTaskState;
  readonly catalogNotification: CatalogNotificationState;
  readonly tipCatalogDe: TipCatalogDeState;
  readonly taskCatalogDe: TaskCatalogDeState;
  readonly tipCatalogTr: TipCatalogTrState;
  readonly taskCatalogTr: TaskCatalogTrState;
  readonly notificationMonitor: NotificationMonitorState;
  readonly userActivity: UserActivityState;
  readonly conversationCreation: ConversationCreationState;
  readonly conversationNotification: ConversationNotificationState;
  readonly termsAndCondition: TermsAndConditionState;
  readonly applicationVersion: ApplicationVersionState;
  readonly scoreCalculation: ScoreCalculationState;
  readonly userPreferences: UserPreferencesState;
  readonly internalApiLogs: InternalApiLogsState;
  readonly externalAppInfo: ExternalAppInfoState;
  readonly userDeviceInfo: UserDeviceInfoState;
  readonly documents: DocumentsState;
  readonly userNotifications: UserNotificationsState;
  readonly notificationSettings: NotificationSettingsState;
  readonly notificationTemplate: NotificationTemplateState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
  readonly verifySmsReducer: SmsVerificationState;
  readonly maDashboardReducer: MedicalAssistantDashboardState;
  readonly chatbotReducer: ChatbotState;
  readonly contactUsReducer: ContactUsState;
  readonly generateDocumentReducer: GenerateDocumentState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  activationChoosePassword,
  termsAndConditions,
  verifySmsReducer,
  passwordReset,
  password,
  settings,
  clinic,
  medicalAssistant,
  patient,
  maPatientSection,
  healthcareProfessional,
  appointment,
  conversationEvent,
  conversationSummary,
  icdCode,
  termsAndConditionsContent,
  conversationCatalogDe,
  conversationCatalogTr,
  conversationCatalogEn,
  insuranceCode,
  scheduledTask,
  scheduledTaskExecution,
  selfRegistration,
  tipCatalog,
  taskCatalog,
  userTip,
  userTask,
  catalogNotification,
  tipCatalogDe,
  taskCatalogDe,
  tipCatalogTr,
  taskCatalogTr,
  notificationMonitor,
  userActivity,
  conversationCreation,
  conversationNotification,
  termsAndCondition,
  applicationVersion,
  scoreCalculation,
  userPreferences,
  internalApiLogs,
  externalAppInfo,
  userDeviceInfo,
  documents,
  userNotifications,
  notificationSettings,
  notificationTemplate,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  maDashboardReducer,
  chatbotReducer,
  contactUsReducer,
  generateDocumentReducer
});

export default rootReducer;
