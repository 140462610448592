import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserDeviceInfo from './user-device-info';
import UserDeviceInfoDetail from './user-device-info-detail';
import UserDeviceInfoUpdate from './user-device-info-update';
import UserDeviceInfoDeleteDialog from './user-device-info-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserDeviceInfoUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserDeviceInfoUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserDeviceInfoDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserDeviceInfo} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UserDeviceInfoDeleteDialog} />
  </>
);

export default Routes;
