import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './user-notifications.reducer';
import { IUserNotifications } from 'app/shared/model/user-notifications.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IUserNotificationsUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const UserNotificationsUpdate = (props: IUserNotificationsUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { userNotificationsEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/user-notifications' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    values.date = convertDateTimeToServer(values.date);

    if (errors.length === 0) {
      const entity = {
        ...userNotificationsEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="diga1App.userNotifications.home.createOrEditLabel">
            <Translate contentKey="diga1App.userNotifications.home.createOrEditLabel">Create or edit a UserNotifications</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : userNotificationsEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="user-notifications-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="user-notifications-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="typeLabel" for="user-notifications-type">
                  <Translate contentKey="diga1App.userNotifications.type">Type</Translate>
                </Label>
                <AvField
                  id="user-notifications-type"
                  type="string"
                  className="form-control"
                  name="type"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="dateLabel" for="user-notifications-date">
                  <Translate contentKey="diga1App.userNotifications.date">Date</Translate>
                </Label>
                <AvInput
                  id="user-notifications-date"
                  type="datetime-local"
                  className="form-control"
                  name="date"
                  placeholder={'YYYY-MM-DD HH:mm'}
                  value={isNew ? displayDefaultDateTime() : convertDateTimeFromServer(props.userNotificationsEntity.date)}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="entityTypeLabel" for="user-notifications-entityType">
                  <Translate contentKey="diga1App.userNotifications.entityType">Entity Type</Translate>
                </Label>
                <AvField
                  id="user-notifications-entityType"
                  type="string"
                  className="form-control"
                  name="entityType"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="entityIdLabel" for="user-notifications-entityId">
                  <Translate contentKey="diga1App.userNotifications.entityId">Entity Id</Translate>
                </Label>
                <AvField
                  id="user-notifications-entityId"
                  type="string"
                  className="form-control"
                  name="entityId"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="statusLabel" for="user-notifications-status">
                  <Translate contentKey="diga1App.userNotifications.status">Status</Translate>
                </Label>
                <AvField
                  id="user-notifications-status"
                  type="text"
                  name="status"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="userIdLabel" for="user-notifications-userId">
                  <Translate contentKey="diga1App.userNotifications.userId">User Id</Translate>
                </Label>
                <AvField
                  id="user-notifications-userId"
                  type="string"
                  className="form-control"
                  name="userId"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/user-notifications" replace className="rounded" color="success">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="rounded" id="save-entity" type="submit" color="danger" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  userNotificationsEntity: storeState.userNotifications.entity,
  loading: storeState.userNotifications.loading,
  updating: storeState.userNotifications.updating,
  updateSuccess: storeState.userNotifications.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationsUpdate);
