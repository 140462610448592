import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConversationNotification from './conversation-notification';
import ConversationNotificationDetail from './conversation-notification-detail';
import ConversationNotificationUpdate from './conversation-notification-update';
import ConversationNotificationDeleteDialog from './conversation-notification-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ConversationNotificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ConversationNotificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ConversationNotificationDetail} />
      <ErrorBoundaryRoute path={match.url} component={ConversationNotification} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ConversationNotificationDeleteDialog} />
  </>
);

export default Routes;
