import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './notification-template.reducer';
import { INotificationTemplate } from 'app/shared/model/notification-template.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface INotificationTemplateDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const NotificationTemplateDetail = (props: INotificationTemplateDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { notificationTemplateEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
        <h2>
          <Translate contentKey="diga1App.notificationTemplate.detail.title">NotificationTemplate</Translate> [
          <b>{notificationTemplateEntity.id}</b>]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="name">
              <Translate contentKey="diga1App.notificationTemplate.name">Name</Translate>
            </span>
          </dt>
          <dd>{notificationTemplateEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="diga1App.notificationTemplate.description">Description</Translate>
            </span>
          </dt>
          <dd>{notificationTemplateEntity.description}</dd>
          <dt>
            <span id="headerDe">
              <Translate contentKey="diga1App.notificationTemplate.headerDe">Header De</Translate>
            </span>
          </dt>
          <dd>{notificationTemplateEntity.headerDe}</dd>
          <dt>
            <span id="contentDe">
              <Translate contentKey="diga1App.notificationTemplate.contentDe">Content De</Translate>
            </span>
          </dt>
          <dd>{notificationTemplateEntity.contentDe}</dd>
          <dt>
            <span id="headerEn">
              <Translate contentKey="diga1App.notificationTemplate.headerEn">Header En</Translate>
            </span>
          </dt>
          <dd>{notificationTemplateEntity.headerEn}</dd>
          <dt>
            <span id="contentEn">
              <Translate contentKey="diga1App.notificationTemplate.contentEn">Content En</Translate>
            </span>
          </dt>
          <dd>{notificationTemplateEntity.contentEn}</dd>
          <dt>
            <span id="headerTr">
              <Translate contentKey="diga1App.notificationTemplate.headerTr">Header Tr</Translate>
            </span>
          </dt>
          <dd>{notificationTemplateEntity.headerTr}</dd>
          <dt>
            <span id="contentTr">
              <Translate contentKey="diga1App.notificationTemplate.contentTr">Content Tr</Translate>
            </span>
          </dt>
          <dd>{notificationTemplateEntity.contentTr}</dd>
        </dl>
        <Button tag={Link} to="/notification-template" className="rounded" replace color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/notification-template/${notificationTemplateEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ notificationTemplate }: IRootState) => ({
  notificationTemplateEntity: notificationTemplate.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTemplateDetail);
