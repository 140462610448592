import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { retrieveDocumentData } from './generate-document-page-reducer';
import { RouteComponentProps } from 'react-router-dom';
import parse from 'html-react-parser';
import { Translate, getUrlParameter } from 'react-jhipster';
import { setLocale } from 'app/shared/reducers/locale';
import { generatePDF, PDFFILES } from 'app/pages/medical-assistant-dashboard/shard/pdfUtils';

export interface IGenerateDocumentPage extends StateProps, DispatchProps, RouteComponentProps<{ filename: any; }> { }

export const GenerateDocumentPage = (props: IGenerateDocumentPage) => {

    const isPDFDoc = (filename) =>{
        return (
            PDFFILES.COVID === filename ||
            PDFFILES.SALZARM === filename ||
            PDFFILES.STUDY_PARTICIPATION_DE === filename ||
            PDFFILES.INSTALLATION_GUIDE_DE === filename ||
            PDFFILES.INSTALLATION_GUIDE_EN === filename ||
            PDFFILES.INSTALLATION_GUIDE_TR === filename ||
            PDFFILES.DATA_PROTECTION_EN === filename ||
            PDFFILES.DATA_PROTECTION_DE === filename ||
            PDFFILES.DATA_PROTECTION_TR === filename ||
            PDFFILES.DATA_PROTECTION_DIGA1_EN === filename ||
            PDFFILES.DATA_PROTECTION_DIGA1_DE === filename ||
            PDFFILES.DATA_PROTECTION_DIGA1_TR === filename
        );
    };

    useEffect(() => {
        const filename = getUrlParameter('filename', props.location.search);
        filename &&
        isPDFDoc(filename)
          ? generatePDF(filename)
          : props.retrieveDocumentData(filename, props.locale);
    }, []);

    return (
        <Row md={8} style={{ margin: "2rem", display: "flow-root" }}>
            {props.content ? parse(props.content) : ''}
            {props.callStatus.loading ? 'Loading...' : ''}
            <Row style={{ margin: "2rem", marginBottom: "100%" }}>
                <p style={{ color: "red" }}>{props.callStatus.failure ?
                    <Translate contentKey="activationTermsConditions.callStatusFailed"></Translate> : ''}</p>
            </Row>
        </Row>
    ) 

};

const mapStateToProps = ({ generateDocumentReducer, locale }: IRootState) => ({
    content: generateDocumentReducer.response,
    locale: locale.currentLocale,
    callStatus: generateDocumentReducer
});

const mapDispatchToProps = { retrieveDocumentData, setLocale };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GenerateDocumentPage);
