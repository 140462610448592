import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, Button, NavItem, NavLink, Col, Row } from 'reactstrap';
import { getPatientsByCriteria as getPatientsOnSearch } from 'app/pages/medical-assistant-dashboard/ma-patient-section.reducer'
import { getPatientsByQuery } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer'
import { updateHideCalendar } from '../ma-dashboard.reducer';
import { getClinicByMedicalAssistantUserId } from '../ma-dashboard.reducer';

import MedicalAssistantPatients from './ma-patients';

import classnames from 'classnames';
import { getSortState, Translate, translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { getEntityByUser } from 'app/entities/medical-assistant/medical-assistant.reducer';

export interface IMedicalAssistantPatientsPage extends StateProps, DispatchProps { }


const MedicalAssistantPatientsPage = (props: IMedicalAssistantPatientsPage) => {
    const [activeTab, setActiveTab] = useState('1');
    const [paginationState, setPaginationState] = useState(
        overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE), location.search)
    );
    const [searchValue, setSearchValue] = useState(null);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        props.updateHideCalendar(true);
    }, []);

    const onSearch = () => {
        if (searchValue != null && props.selectedClinic !== -1)
            props.getPatientsOnSearch(searchValue, props.selectedClinic);
    }

    useEffect(() => {
        props.getEntityByUser();
    }, [props.account])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && searchValue && searchValue !== '') {
            onSearch()
        }
    }

    return (
        <Col>
            <Row>
                <input className="form-control form-control-sm w-75"
                    style={{ borderRadius: '100px' }}
                    placeholder={translate("diga1App.patient.SearchPatient")}
                    aria-label="Search"
                    onChange={(event) => setSearchValue(event.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                />
                <Button style={{ "marginLeft": "1rem" }} className="tableButton" onClick={() => onSearch()}>
                    <Translate contentKey="maDashboard.searchButton">Open</Translate>
                </Button>
            </Row>
            <br />
            <Nav className={"patients-tab"} tabs>
                <NavItem >
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                        style={{ borderBottom: "1px solid #d7d4d4" }}
                    >
                        {translate("maDashboard.patientArea.patientsTabTitle")}
                    </NavLink>
                </NavItem>
            </Nav>
            <div>
                <TabContent activeTab={activeTab} className="page-container">
                    <TabPane tabId="1">
                        <MedicalAssistantPatients />
                    </TabPane>
                </TabContent>
            </div>
        </Col>
    );
};


const mapStateToProps = (storeState: IRootState) => ({
    selectedClinic: storeState.medicalAssistant.maClinicId,
    currentLocale: storeState.locale.currentLocale,
    account: storeState.authentication.account
});

const mapDispatchToProps = {
    getPatientsByQuery,
    updateHideCalendar,
    getPatientsOnSearch,
    getClinicByMedicalAssistantUserId,
    getEntityByUser,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantPatientsPage);
