import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './task-catalog-de.reducer';
import { ITaskCatalogDe } from 'app/shared/model/task-catalog-de.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ITaskCatalogDeDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const TaskCatalogDeDetail = (props: ITaskCatalogDeDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { taskCatalogDeEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="diga1App.taskCatalogDe.detail.title">TaskCatalogDe</Translate> [<b>{taskCatalogDeEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="icdCodeId">
              <Translate contentKey="diga1App.taskCatalogDe.icdCodeId">Icd Code Id</Translate>
            </span>
          </dt>
          <dd>{taskCatalogDeEntity.icdCodeId}</dd>
          <dt>
            <span id="task">
              <Translate contentKey="diga1App.taskCatalogDe.task">Task</Translate>
            </span>
          </dt>
          <dd>{taskCatalogDeEntity.task}</dd>
          <dt>
            <span id="action">
              <Translate contentKey="diga1App.taskCatalogDe.action">Action</Translate>
            </span>
          </dt>
          <dd>{taskCatalogDeEntity.action}</dd>
        </dl>
        <Button tag={Link} to="/task-catalog-de" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/task-catalog-de/${taskCatalogDeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ taskCatalogDe }: IRootState) => ({
  taskCatalogDeEntity: taskCatalogDe.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TaskCatalogDeDetail);
