import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './internal-api-logs.reducer';
import { IInternalApiLogs } from 'app/shared/model/internal-api-logs.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IInternalApiLogsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const InternalApiLogsDetail = (props: IInternalApiLogsDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { internalApiLogsEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
        <h2>
          <Translate contentKey="diga1App.internalApiLogs.detail.title">InternalApiLogs</Translate> [<b>{internalApiLogsEntity.id}</b>]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="apiName">
              <Translate contentKey="diga1App.internalApiLogs.apiName">Api Name</Translate>
            </span>
          </dt>
          <dd>{internalApiLogsEntity.apiName}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="diga1App.internalApiLogs.url">Url</Translate>
            </span>
          </dt>
          <dd>{internalApiLogsEntity.url}</dd>
          <dt>
            <span id="request">
              <Translate contentKey="diga1App.internalApiLogs.request">Request</Translate>
            </span>
          </dt>
          <dd>{internalApiLogsEntity.request}</dd>
          <dt>
            <span id="responses">
              <Translate contentKey="diga1App.internalApiLogs.responses">Responses</Translate>
            </span>
          </dt>
          <dd>{internalApiLogsEntity.responses}</dd>
          <dt>
            <span id="error">
              <Translate contentKey="diga1App.internalApiLogs.error">Error</Translate>
            </span>
          </dt>
          <dd>{internalApiLogsEntity.error}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="diga1App.internalApiLogs.status">Status</Translate>
            </span>
          </dt>
          <dd>{internalApiLogsEntity.status}</dd>
          <dt>
            <span id="source">
              <Translate contentKey="diga1App.internalApiLogs.source">Source</Translate>
            </span>
          </dt>
          <dd>{internalApiLogsEntity.source}</dd>
        </dl>
        <Button tag={Link} to="/internal-api-logs" replace className="rounded" color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/internal-api-logs/${internalApiLogsEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ internalApiLogs }: IRootState) => ({
  internalApiLogsEntity: internalApiLogs.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(InternalApiLogsDetail);
