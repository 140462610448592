import { Moment } from 'moment';

export interface IScoreCalculation {
  id?: number;
  userId?: number;
  date?: string;
  scoreType?: string;
  score?: number;
}

export const defaultValue: Readonly<IScoreCalculation> = {};
