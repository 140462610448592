import './home.scss';

import React from 'react';
import { HomeHeaderRow } from './HomeHeaderRow';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';

export const Home = (props: any) => {
  return <HomeHeaderRow currentLocale={props.currentLocale}/>;
};

const mapStateToProps = ({ locale }: IRootState) => ({
  currentLocale: locale.currentLocale,
});
export default connect(mapStateToProps, {})(Home);
