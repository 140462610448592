import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TipCatalogDe from './tip-catalog-de';
import TipCatalogDeDetail from './tip-catalog-de-detail';
import TipCatalogDeUpdate from './tip-catalog-de-update';
import TipCatalogDeDeleteDialog from './tip-catalog-de-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TipCatalogDeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TipCatalogDeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TipCatalogDeDetail} />
      <ErrorBoundaryRoute path={match.url} component={TipCatalogDe} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={TipCatalogDeDeleteDialog} />
  </>
);

export default Routes;
