import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './user-preferences.reducer';
import { IUserPreferences } from 'app/shared/model/user-preferences.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IUserPreferencesDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const UserPreferencesDetail = (props: IUserPreferencesDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { userPreferencesEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
        <h2>
          <Translate contentKey="diga1App.userPreferences.detail.title">UserPreferences</Translate> [<b>{userPreferencesEntity.id}</b>]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="userId">
              <Translate contentKey="diga1App.userPreferences.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.userId}</dd>
          <dt>
            <span id="favoriteLocationLatitude">
              <Translate contentKey="diga1App.userPreferences.favoriteLocationLatitude">Favorite Location Latitude</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.favoriteLocationLatitude}</dd>
          <dt>
            <span id="favoriteLocationLongitude">
              <Translate contentKey="diga1App.userPreferences.favoriteLocationLongitude">Favorite Location Longitude</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.favoriteLocationLongitude}</dd>
          <dt>
            <span id="favoriteLocationName">
              <Translate contentKey="diga1App.userPreferences.favoriteLocationName">Favorite Location Name</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.favoriteLocationName}</dd>
        </dl>
        <Button tag={Link} to="/user-preferences" className="rounded" replace color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-preferences/${userPreferencesEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ userPreferences }: IRootState) => ({
  userPreferencesEntity: userPreferences.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferencesDetail);
