import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, getEntitiesWithFilters } from './healthcare-professional.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getDoctorsByCriteria as getDoctorsOnSearch } from 'app/entities/healthcare-professional/healthcare-professional.reducer'
import SearchInputText from 'app/shared/input-text/search-input-text/search-input-text';


export interface IHealthcareProfessionalProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; }> { }

export const HealthcareProfessional = (props: IHealthcareProfessionalProps) => {
  const [filters, setFilters] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
  });
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const [searchValue, setSearchValue] = useState(null);

  const getAllEntities = () => {
    const query = Object.keys(filters).filter((k) => filters[k] !== '').map((k) => {
      if (k === 'id') {
        return `${k}.equals=${filters[k]}`
      }
      return `${k}.contains=${filters[k]}`
    }).join('&');
    props.getEntitiesWithFilters(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, query);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const onSearch = () => {
    if (searchValue && searchValue !== null && searchValue !== '')
      props.getDoctorsOnSearch(searchValue);
    else getAllEntities();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchValue && searchValue !== '') {
      onSearch()
    }
  }

  useEffect(() => {
    sortEntities();
  }, [filters.firstName, filters.lastName, filters.email, filters.mobileNumber, filters.id]);

  const { healthcareProfessionalList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="healthcare-professional-heading">
        <Translate contentKey="diga1App.healthcareProfessional.home.title">Doctors</Translate>
        <Link to={`${match.url}/new`} className="btn btn-outline-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.healthcareProfessional.home.createLabel">Create new Doctor</Translate>
        </Link>
      </h2>
      <Row style={{ "width": "50%" }}>
        <input className="form-control form-control-sm w-75"
          style={{ borderRadius: '100px' }}
          placeholder={translate("global.form.search")}
          aria-label="Search"
          onChange={(event) => setSearchValue(event.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <Button style={{ "marginLeft": "1rem" }} className="tableButton" onClick={() => onSearch()}>
          <Translate contentKey="maDashboard.searchButton">Open</Translate>
        </Button>
      </Row>
      <br/>
      <div className="table-responsive">
        {(
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        id: text
                      })
                    }} 
                    column
                    type="number"
                  />
                </th>
                <th className="hand" onClick={sort('userId')}>
                  <Translate contentKey="diga1App.healthcareProfessional.userId">User Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('salutation')}>
                  <Translate contentKey="diga1App.healthcareProfessional.salutation">Salutation</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('title')}>
                  <Translate contentKey="diga1App.healthcareProfessional.title">Title</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('firstName')}>
                  <Translate contentKey="diga1App.healthcareProfessional.firstName">First Name</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        firstName: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('lastName')}>
                  <Translate contentKey="diga1App.healthcareProfessional.lastName">Last Name</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        lastName: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('gender')}>
                  <Translate contentKey="diga1App.healthcareProfessional.gender">Gender</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('langKey')}>
                  <Translate contentKey="diga1App.healthcareProfessional.langKey">Lang Key</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('email')}>
                  <Translate contentKey="diga1App.healthcareProfessional.email">Email</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        email: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('mobileNumber')}>
                  <Translate contentKey="diga1App.healthcareProfessional.mobileNumber">Mobile Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        mobileNumber: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('registrationNumber')}>
                  <Translate contentKey="diga1App.healthcareProfessional.registrationNumber">Registration Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('verificationStatus')}>
                  <Translate contentKey="diga1App.healthcareProfessional.verificationStatus">Verification Status</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                !loading && healthcareProfessionalList.length === 0 && (
                  <tr>
                    <td colSpan={13}>
                      <div className="alert alert-warning">
                        <Translate contentKey="diga1App.healthcareProfessional.home.notFound">No Healthcare Professionals found</Translate>
                      </div>
                    </td>
                  </tr>
                )
              }
              {healthcareProfessionalList.map((healthcareProfessional, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${healthcareProfessional.id}`} color="link" size="sm">
                      {healthcareProfessional.id}
                    </Button>
                  </td>
                  <td>{healthcareProfessional.userId}</td>
                  <td>{healthcareProfessional.salutation}</td>
                  <td>{healthcareProfessional.title}</td>
                  <td>{healthcareProfessional.firstName}</td>
                  <td>{healthcareProfessional.lastName}</td>
                  <td>{healthcareProfessional.gender}</td>
                  <td>{healthcareProfessional.langKey}</td>
                  <td>{healthcareProfessional.email}</td>
                  <td>{healthcareProfessional.mobileNumber}</td>
                  <td>{healthcareProfessional.registrationNumber}</td>
                  <td>{healthcareProfessional.verificationStatus}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${healthcareProfessional.id}`}
                        outline
                        className="rounded mr-1"
                        color="success"
                        size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${healthcareProfessional.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${healthcareProfessional.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      {props.totalItems ? (
        <div className={healthcareProfessionalList && healthcareProfessionalList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ healthcareProfessional }: IRootState) => ({
  healthcareProfessionalList: healthcareProfessional.entities,
  loading: healthcareProfessional.loading,
  totalItems: healthcareProfessional.totalItems
});

const mapDispatchToProps = {
  getEntities,
  getEntitiesWithFilters,
  getDoctorsOnSearch
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(HealthcareProfessional);
