import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Col, Row, Button } from 'reactstrap';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { getEntitiesByQuery } from 'app/entities/appointment/appointment.reducer';
import { getEntitiesByClinicId as getPatients } from 'app/pages/medical-assistant-dashboard/ma-patient-section.reducer'
import { getClinicByMedicalAssistantUserId, updateSelectedPatient } from '../ma-dashboard.reducer';
import { URL_PREFIX, URL_SUFFIX } from '../ma-dashboard-layout/pages-list';
import '../ma-pages.scss';
import { generateReport } from '../shard/reportUtil';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getSession } from 'app/shared/reducers/authentication';
import { REALM } from '../../../shared/constants/realm.model';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IMedicalAssistantPatients extends StateProps, DispatchProps { }

const MedicalAssistantPatients = (props: IMedicalAssistantPatients) => {
    const location = useLocation();
    const history = useHistory();

    const [paginationState, setPaginationState] = useState(
        overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE), location.search)
    );

    const getAllEntities = () => {
        props.getPatients(props.selectedClinics[0].id, paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
    };

    const sortEntities = () => {
        getAllEntities();
        const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
        if (location.search !== endURL) {
            history.push(`${location.pathname}${endURL}`);
        }
    };

    useEffect(() => {
        if (props.selectedClinics && props.selectedClinics.length > 0 !== null && props.selectedClinics[0].id) sortEntities();
    }, [paginationState.activePage, paginationState.order, paginationState.sort, props.selectedClinics]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const page = params.get('page');
        const sort = params.get('sort');
        if (page && sort) {
            const sortSplit = sort.split(',');
            setPaginationState({
                ...paginationState,
                activePage: +page,
                sort: sortSplit[0],
                order: sortSplit[1],
            });
        }
    }, [location.search]);

    const sort = p => () => {
        setPaginationState({
            ...paginationState,
            order: paginationState.order === 'asc' ? 'desc' : 'asc',
            sort: p,
        });
    };

    const handlePagination = currentPage =>
        setPaginationState({
            ...paginationState,
            activePage: currentPage,
        });

    const { clinicPatients, totalItems } = props;

    const printReport = (patientId) => {
        generateReport(patientId);
    };

    const navigateToEditPatient = (patientToUpdate) => {
        props.updateSelectedPatient(patientToUpdate);
    };

    return (
        <Row>
            <Col>
                <div className="table-responsive">
                    {clinicPatients && clinicPatients.length > 0 ? (
                        <Table responsive>
                            <thead>
                                <tr style={{ textAlign: "center" }}>
                                    <th>
                                        {''}
                                    </th>
                                    <th className="hand" onClick={sort('lastName')}>
                                        <Translate contentKey="diga1App.patient.lastName">Last Name</Translate>
                                    </th>
                                    <th className="hand" onClick={sort('firstName')}>
                                        <Translate contentKey="diga1App.patient.firstName">First Name</Translate>
                                    </th>
                                    <th className="hand" onClick={sort('realm')}>
                                        <Translate contentKey="diga1App.patient.realm">App</Translate>
                                    </th>
                                    <th className="hand" onClick={sort('mobileAppInstalled')}>
                                        <Translate contentKey="diga1App.patient.mobileAppInstalled">Status</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.patient.patientCard">Patient Card</Translate>
                                    </th>
                                    <th>
                                        <Translate contentKey="diga1App.patient.report">Report</Translate>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {clinicPatients.map((patient, i) => {
                                    return (
                                        <tr key={`entity-${i}`} style={{ textAlign: "center" }}>
                                            <td>
                                                {`${((paginationState.activePage - 1) * 50) + (i + 1)}`}
                                            </td>
                                            <td>
                                                {`${patient.lastName}`}
                                            </td>
                                            <td>
                                                {`${patient.firstName}`}
                                            </td>
                                            <td style={{ color: patient.realm === REALM.DIGA1 ? '#e6b800' : '#1a8cff' }}>
                                                {`${patient?.realm?.toUpperCase()}`}
                                            </td>
                                            {patient.mobileAppInstalled === true ?
                                                <td style={{ color: "green" }}><Translate contentKey="maDashboard.general.active"></Translate></td> :
                                                <td style={{ color: "red" }}><Translate contentKey="maDashboard.general.notInstalled"></Translate></td>}
                                            <td>
                                                <Link to={URL_PREFIX.MA_DASHBOARD + URL_SUFFIX.CHANGE_PATIENT}
                                                    style={{ color: "black", textDecoration: "underline" }}
                                                    onClick={() => navigateToEditPatient(patient)}>
                                                    {`Details`}
                                                </Link>
                                            </td>
                                            <td style={{ cursor: "pointer" }}>
                                                {patient.mobileAppInstalled && (
                                                    <Button className="tableButton" onClick={() => printReport(patient.id)}><Translate contentKey="diga1App.patient.open">Open</Translate></Button>
                                                )}
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </Table>
                    ) : (<Col><div><Translate contentKey="diga1App.patient.home.notFound">No patients found</Translate></div></Col>)}
                </div>
                {props.totalItems ? (
                    <div className={clinicPatients && clinicPatients.length > 0 ? '' : 'd-none'}>
                        <Row className="justify-content-center">
                            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
                        </Row>
                        <Row className="justify-content-center">
                            <JhiPagination
                                activePage={paginationState.activePage}
                                onSelect={handlePagination}
                                maxButtons={5}
                                itemsPerPage={paginationState.itemsPerPage}
                                totalItems={props.totalItems}
                            />
                        </Row>
                    </div>
                ) : (
                    ''
                )}
            </Col >
        </Row>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    selectedClinics: storeState?.medicalAssistant?.entity?.clinics,
    updateSuccess: storeState.maDashboardReducer.updateSuccess,
    currentLocale: storeState.locale.currentLocale,
    account: storeState.authentication.account,
    clinicPatients: storeState.maPatientSection.entities,
    loading: storeState.maPatientSection.loading,
    totalItems: storeState.maPatientSection.totalItems,
    patientsWithoutFilter: storeState.patient.entities
});

const mapDispatchToProps = {
    getEntitiesByQuery,
    updateSelectedPatient,
    getSession,
    getPatients,
    getClinicByMedicalAssistantUserId
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantPatients);
