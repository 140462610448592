import React from 'react';
import { DropdownItem } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IMenuItem {
  icon?: IconProp;
  to: string;
  id?: string;
  className?: string;
  tag?: any;
  onClick?: any;
}

export default class MenuItem extends React.Component<IMenuItem> {
  render() {
    const { to, icon, id, children, tag, onClick, className } = this.props;

    return (
      <DropdownItem
        tag={tag ?? Link}
        to={to}
        id={id}
        onClick={e => {
          e.stopPropagation();
          onClick?.();
        }}
        className={className}
      >
        <FontAwesomeIcon icon={icon} fixedWidth /> {children}
      </DropdownItem>
    );
  }
}
