import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Clinic from './clinic';
import MedicalAssistant from './medical-assistant';
import Patient from './patient';
import HealthcareProfessional from './healthcare-professional';
import Appointment from './appointment';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import ConversationEvent from './conversation-event';
import ConversationSummary from './conversation-summary';
import IcdCode from './icd-code';
import TermsAndConditionsContent from './terms-and-conditions-content';
import ConversationCatalogDe from './conversation-catalog-de';
import ConversationCatalogTr from './conversation-catalog-tr';
import ConversationCatalogEn from './conversation-catalog-en';
import InsuranceCode from './insurance-code';
import ScheduledTask from './scheduled-task';
import ScheduledTaskExecution from './scheduled-task-execution';
import SelfRegistration from './self-registration';

import TipCatalog from './tip-catalog';
import TaskCatalog from './task-catalog';
import UserTip from './user-tip';
import UserTask from './user-task';
import CatalogNotification from './catalog-notification';
import TipCatalogDe from './tip-catalog-de';
import TaskCatalogDe from './task-catalog-de';
import TipCatalogTr from './tip-catalog-tr';
import TaskCatalogTr from './task-catalog-tr';
import NotificationMonitor from './notification-monitor';
import UserActivity from './user-activity';
import ConversationCreation from './conversation-creation';
import ConversationNotification from './conversation-notification';
import TermsAndCondition from './terms-and-condition';
import ApplicationVersion from './application-version';
import ScoreCalculation from './score-calculation';
import UserPreferences from './user-preferences';
import InternalApiLogs from './internal-api-logs';
import ExternalAppInfo from './external-app-info';
import UserDeviceInfo from './user-device-info';
import Documents from './documents';
import UserNotifications from './user-notifications';
import NotificationSettings from './notification-settings';
import NotificationTemplate from './notification-template';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <PrivateRoute path={`${match.url}clinic`} component={Clinic} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute
        path={`${match.url}medical-assistant`}
        component={MedicalAssistant}
        hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.MEDICAL_ASSISTANT, AUTHORITIES.USER]}
      />
      <PrivateRoute path={`${match.url}patient`} component={Patient} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <PrivateRoute
        path={`${match.url}healthcare-professional`}
        component={HealthcareProfessional}
        hasAnyAuthorities={[AUTHORITIES.ADMIN]}
      />
      <PrivateRoute path={`${match.url}appointment`} component={Appointment} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
      <ErrorBoundaryRoute path={`${match.url}conversation-event`} component={ConversationEvent} />
      <ErrorBoundaryRoute path={`${match.url}conversation-summary`} component={ConversationSummary} />
      <ErrorBoundaryRoute path={`${match.url}clinic`} component={Clinic} />
      <ErrorBoundaryRoute path={`${match.url}healthcare-professional`} component={HealthcareProfessional} />
      <ErrorBoundaryRoute path={`${match.url}medical-assistant`} component={MedicalAssistant} />
      <ErrorBoundaryRoute path={`${match.url}patient`} component={Patient} />
      <ErrorBoundaryRoute path={`${match.url}appointment`} component={Appointment} />
      <ErrorBoundaryRoute path={`${match.url}icd-code`} component={IcdCode} />
      <ErrorBoundaryRoute path={`${match.url}terms-and-conditions-content`} component={TermsAndConditionsContent} />
      <ErrorBoundaryRoute path={`${match.url}conversation-catalog-de`} component={ConversationCatalogDe} />
      <ErrorBoundaryRoute path={`${match.url}conversation-catalog-tr`} component={ConversationCatalogTr} />
      <ErrorBoundaryRoute path={`${match.url}conversation-catalog-en`} component={ConversationCatalogEn} />
      <ErrorBoundaryRoute path={`${match.url}insurance-code`} component={InsuranceCode} />
      <ErrorBoundaryRoute path={`${match.url}scheduled-task`} component={ScheduledTask} />
      <ErrorBoundaryRoute path={`${match.url}scheduled-task-execution`} component={ScheduledTaskExecution} />
      <ErrorBoundaryRoute path={`${match.url}self-registration`} component={SelfRegistration} />
      <ErrorBoundaryRoute path={`${match.url}tip-catalog`} component={TipCatalog} />
      <ErrorBoundaryRoute path={`${match.url}task-catalog`} component={TaskCatalog} />
      <ErrorBoundaryRoute path={`${match.url}user-tip`} component={UserTip} />
      <ErrorBoundaryRoute path={`${match.url}user-task`} component={UserTask} />
      {/* <ErrorBoundaryRoute path={`${match.url}catalog-notification`} component={CatalogNotification} /> */}
      <ErrorBoundaryRoute path={`${match.url}tip-catalog-de`} component={TipCatalogDe} />
      <ErrorBoundaryRoute path={`${match.url}task-catalog-de`} component={TaskCatalogDe} />
      <ErrorBoundaryRoute path={`${match.url}tip-catalog-tr`} component={TipCatalogTr} />
      <ErrorBoundaryRoute path={`${match.url}task-catalog-tr`} component={TaskCatalogTr} />
      <ErrorBoundaryRoute path={`${match.url}notification-monitor`} component={NotificationMonitor} />
      <ErrorBoundaryRoute path={`${match.url}user-activity`} component={UserActivity} />
      <ErrorBoundaryRoute path={`${match.url}conversation-creation`} component={ConversationCreation} />
      <ErrorBoundaryRoute path={`${match.url}conversation-notification`} component={ConversationNotification} />
      <ErrorBoundaryRoute path={`${match.url}terms-and-condition`} component={TermsAndCondition} />
      <ErrorBoundaryRoute path={`${match.url}application-version`} component={ApplicationVersion} />
      <ErrorBoundaryRoute path={`${match.url}score-calculation`} component={ScoreCalculation} />
      <ErrorBoundaryRoute path={`${match.url}user-preferences`} component={UserPreferences} />
      <ErrorBoundaryRoute path={`${match.url}internal-api-logs`} component={InternalApiLogs} />
      <ErrorBoundaryRoute path={`${match.url}external-app-info`} component={ExternalAppInfo} />
      <ErrorBoundaryRoute path={`${match.url}user-device-info`} component={UserDeviceInfo} />
      <ErrorBoundaryRoute path={`${match.url}documents`} component={Documents} />
      <ErrorBoundaryRoute path={`${match.url}user-notifications`} component={UserNotifications} />
      <ErrorBoundaryRoute path={`${match.url}notification-settings`} component={NotificationSettings} />
      <ErrorBoundaryRoute path={`${match.url}notification-template`} component={NotificationTemplate} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
