import React, { useState, useEffect } from 'react';
import { Translate, translate, getUrlParameter } from 'react-jhipster';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Button, DropdownItem } from 'reactstrap';
import { IRootState } from 'app/shared/reducers';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import PasswordIndicatorBox from 'app/shared/password-indicator-box/password-indicator-box';
import { UserActivationStatus } from '../models';
import { activateNewPassword, verifyCreateNewPassword, verifyResetPassword, handlePasswordResetFinish, reset } from './activate-choose-password.reducer';
import { logout } from '../../../shared/reducers/authentication';
import { AUTHORITIES } from '../../../config/constants'
import { AxiosResponse } from 'axios';
import _ from 'lodash';
const RESET_URL = "/account/resetPassword";


export interface IActivateChoosePassword extends StateProps, DispatchProps, RouteComponentProps<{ key: any; }> { }

export const ActivateChoosePassword = (props: IActivateChoosePassword) => {

  const [key, setkey] = useState('');
  const [password, setPassword] = useState('');
  const [isFormValidForSubmition, setIsFormValidForSubmition] = useState(false);

  useEffect(() => {
    props.reset();
    const keyFromUrl = getUrlParameter('key', props.location.search);
    setkey(keyFromUrl);
    props.logout();
    props.location.pathname.includes(RESET_URL) ? props.verifyResetPassword(keyFromUrl) : props.verifyCreateNewPassword(keyFromUrl);
  }, []);

  const handleValidSubmit = (event, values) => {
    !isFormValidForSubmition ? null : props.location.pathname.includes(RESET_URL) ? props.handlePasswordResetFinish(values.newPassword, key) : props.activateNewPassword(values.newPassword, key) ;
  };


  const updatePassword = event => {
    const temp = event.target.value;
    setPassword(temp);
  };


  const updateIsValidationPassed = isValidtionPassed => {
    setIsFormValidForSubmition(isValidtionPassed);
  };

  if (props.updateSuccess) {
    let redirectPath = '/patient-welcome';
    if (props.isAuthenticated) {
      if (props.authorities?.includes(AUTHORITIES.MEDICAL_ASSISTANT)) {
        redirectPath = '/ma-dashboard';
      }
      if (props.authorities?.includes(AUTHORITIES.ADMIN)) {
        redirectPath = '/clinic';
      }
    }
    return <Redirect to={redirectPath} />;
  }
  else
    return (
      <Row>
        <Col />

        <Col xs={12} md={6} lg={3} style={{ border: "solid 1px", backgroundColor: "white" }}>

          <Row style={{ border: "solid 1px", backgroundColor: "#fdbb38" }}>
            <Col xs={1} />
            <Col>
              <div style={{ color: "white", fontSize: "90px" }}>3</div>
            </Col>
          </Row>

          <Row style={{ marginTop: "1rem" }}>
            <Col xs={1} />
            <Col>
              <div className="wizard-box-title">
                <Translate contentKey="activationChoosePassword.title" />
              </div>
            </Col>
          </Row>
          {props.pagedSuccessfullyLoaded && (props.userStatus === UserActivationStatus.TERMS_AND_CONDITIONS_VERIFIED || props.userStatus === UserActivationStatus.ACTIVATE_VERIFIED) ? <div>
            <Row style={{ marginTop: "1rem" }}>
              <Col xs={1} />
              <Col>
                <div className="wizard-box-sub-title">
                  <Translate contentKey="activationChoosePassword.subTitle" />
                </div>
              </Col>
              <Col xs={1} />
            </Row>

            <Row>
              <Col xs={1} />
              <Col>
                <AvForm id="activation-choose-password-form" onValidSubmit={handleValidSubmit} >
                  <div className="wizard-input-box">
                    <AvField
                      name="newPassword"
                      label={translate('global.form.newpassword.label')}
                      placeholder={translate('global.form.newpassword.placeholder')}
                      type="password"
                      validate={{
                        required: { value: true, errorMessage: translate('global.messages.validate.newpassword.required') },
                        minLength: { value: 6, errorMessage: translate('global.messages.validate.newpassword.minlength') },
                        maxLength: { value: 50, errorMessage: translate('global.messages.validate.newpassword.maxlength') }
                      }}
                      onChange={updatePassword}
                    />
                  </div>

                  <AvField
                    className="wizard-input"
                    name="confirmPassword"
                    label={translate('global.form.confirmpassword.label')}
                    placeholder={translate('global.form.confirmpassword.placeholder')}
                    type="password"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: translate('global.messages.validate.confirmpassword.required')
                      },
                      minLength: {
                        value: 6,
                        errorMessage: translate('global.messages.validate.confirmpassword.minlength')
                      },
                      maxLength: {
                        value: 50,
                        errorMessage: translate('global.messages.validate.confirmpassword.maxlength')
                      },
                      match: {
                        value: 'newPassword',
                        errorMessage: translate('global.messages.error.dontmatch')
                      }
                    }}
                  />
                  <DropdownItem divider />
                  <PasswordIndicatorBox passwordToTest={password} isValidationPassed={updateIsValidationPassed}/>

                  <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                    <Col />
                    <Col xs={8}>
                      <Button
                        type="submit"
                        className="align-self-center app-button"
                      >
                        <Translate contentKey="password.form.button">Next</Translate>
                      </Button>
                    </Col>
                    <Col />
                  </Row>
                </AvForm>
              </Col>

              <Col xs={1} />
            </Row>

          </div>
            : props.pageIsLoading ?
              <Row style={{ margin: "2rem", marginBottom: "100%" }}>
                <h3 style={{ alignContent: "center", margin: "2rem" }}><Translate contentKey="activationChoosePassword.loading">Loading...</Translate></h3>
              </Row>

              : props.verifyingFailure ? <Row style={{ margin: "2rem", marginBottom: "100%" }}>
                <p style={{ color: "red", margin: "2rem" }}><Translate contentKey="activationChoosePassword.errorMessage">Something went wrong!</Translate></p>
              </Row> : ''
          }

        </Col>

        <Col />
      </Row>
    );

};

const mapStateToProps = ({ activationChoosePassword, authentication, userManagement }: IRootState, action) => ({
  updateSuccess: activationChoosePassword.activateNewPasswordAPI.updateSuccess,
  updateFailure: activationChoosePassword.activateNewPasswordAPI.updateFailure,
  isAuthenticated: authentication.isAuthenticated,
  authorities: authentication.account.authorities,
  pagedSuccessfullyLoaded: activationChoosePassword.verifyNewPasswordAPI.updateSuccess,
  pageIsLoading: activationChoosePassword.verifyNewPasswordAPI.loading,
  userStatus: activationChoosePassword.verifyNewPasswordAPI.payload,
  verifyingFailure: activationChoosePassword.verifyNewPasswordAPI.updateFailure,
});

const mapDispatchToProps = { activateNewPassword, verifyCreateNewPassword, verifyResetPassword, handlePasswordResetFinish, reset, logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActivateChoosePassword);
