import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './notification-template.reducer';
import { INotificationTemplate } from 'app/shared/model/notification-template.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface INotificationTemplateUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const NotificationTemplateUpdate = (props: INotificationTemplateUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { notificationTemplateEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/notification-template' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...notificationTemplateEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
          <h2 id="diga1App.notificationTemplate.home.createOrEditLabel">
            <Translate contentKey="diga1App.notificationTemplate.home.createOrEditLabel">Create or edit a NotificationTemplate</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : notificationTemplateEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="notification-template-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="notification-template-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="nameLabel" for="notification-template-name">
                  <Translate contentKey="diga1App.notificationTemplate.name">Name</Translate>
                </Label>
                <AvField id="notification-template-name" type="text" name="name" />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="notification-template-description">
                  <Translate contentKey="diga1App.notificationTemplate.description">Description</Translate>
                </Label>
                <AvField id="notification-template-description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="headerDeLabel" for="notification-template-headerDe">
                  <Translate contentKey="diga1App.notificationTemplate.headerDe">Header De</Translate>
                </Label>
                <AvField id="notification-template-headerDe" type="text" name="headerDe" />
              </AvGroup>
              <AvGroup>
                <Label id="contentDeLabel" for="notification-template-contentDe">
                  <Translate contentKey="diga1App.notificationTemplate.contentDe">Content De</Translate>
                </Label>
                <AvField id="notification-template-contentDe" type="text" name="contentDe" />
              </AvGroup>
              <AvGroup>
                <Label id="headerEnLabel" for="notification-template-headerEn">
                  <Translate contentKey="diga1App.notificationTemplate.headerEn">Header En</Translate>
                </Label>
                <AvField id="notification-template-headerEn" type="text" name="headerEn" />
              </AvGroup>
              <AvGroup>
                <Label id="contentEnLabel" for="notification-template-contentEn">
                  <Translate contentKey="diga1App.notificationTemplate.contentEn">Content En</Translate>
                </Label>
                <AvField id="notification-template-contentEn" type="text" name="contentEn" />
              </AvGroup>
              <AvGroup>
                <Label id="headerTrLabel" for="notification-template-headerTr">
                  <Translate contentKey="diga1App.notificationTemplate.headerTr">Header Tr</Translate>
                </Label>
                <AvField id="notification-template-headerTr" type="text" name="headerTr" />
              </AvGroup>
              <AvGroup>
                <Label id="contentTrLabel" for="notification-template-contentTr">
                  <Translate contentKey="diga1App.notificationTemplate.contentTr">Content Tr</Translate>
                </Label>
                <AvField id="notification-template-contentTr" type="text" name="contentTr" />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/notification-template" className="rounded" replace color="success">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  notificationTemplateEntity: storeState.notificationTemplate.entity,
  loading: storeState.notificationTemplate.loading,
  updating: storeState.notificationTemplate.updating,
  updateSuccess: storeState.notificationTemplate.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTemplateUpdate);
