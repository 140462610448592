import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './external-app-info.reducer';
import { IExternalAppInfo } from 'app/shared/model/external-app-info.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IExternalAppInfoUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ExternalAppInfoUpdate = (props: IExternalAppInfoUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { externalAppInfoEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/external-app-info' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...externalAppInfoEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
      <Col md={4} sm={8}>
          <h2 id="diga1App.externalAppInfo.home.createOrEditLabel">
            <Translate contentKey="diga1App.externalAppInfo.home.createOrEditLabel">Create or edit a ExternalAppInfo</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
      <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : externalAppInfoEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="external-app-info-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="external-app-info-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="internalEntityIdLabel" for="external-app-info-internalEntityId">
                  <Translate contentKey="diga1App.externalAppInfo.internalEntityId">Internal Entity Id</Translate>
                </Label>
                <AvField id="external-app-info-internalEntityId" type="string" className="form-control" name="internalEntityId" />
              </AvGroup>
              <AvGroup>
                <Label id="internalEntityTypeLabel" for="external-app-info-internalEntityType">
                  <Translate contentKey="diga1App.externalAppInfo.internalEntityType">Internal Entity Type</Translate>
                </Label>
                <AvField id="external-app-info-internalEntityType" type="text" name="internalEntityType" />
              </AvGroup>
              <AvGroup>
                <Label id="externalPatientIdLabel" for="external-app-info-externalPatientId">
                  <Translate contentKey="diga1App.externalAppInfo.externalPatientId">External Patient Id</Translate>
                </Label>
                <AvField id="external-app-info-externalPatientId" type="text" name="externalPatientId" />
              </AvGroup>
              <AvGroup>
                <Label id="externalClinicIdLabel" for="external-app-info-externalClinicId">
                  <Translate contentKey="diga1App.externalAppInfo.externalClinicId">External Clinic Id</Translate>
                </Label>
                <AvField id="external-app-info-externalClinicId" type="text" name="externalClinicId" />
              </AvGroup>
              <AvGroup>
                <Label id="externalPhycianIdLabel" for="external-app-info-externalPhycianId">
                  <Translate contentKey="diga1App.externalAppInfo.externalPhycianId">External Phycian Id</Translate>
                </Label>
                <AvField id="external-app-info-externalPhycianId" type="text" name="externalPhycianId" />
              </AvGroup>
              <AvGroup>
                <Label id="externalAppNameLabel" for="external-app-info-externalAppName">
                  <Translate contentKey="diga1App.externalAppInfo.externalAppName">External App Name</Translate>
                </Label>
                <AvField id="external-app-info-externalAppName" type="text" name="externalAppName" />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/external-app-info" replace className="rounded" color="success">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  externalAppInfoEntity: storeState.externalAppInfo.entity,
  loading: storeState.externalAppInfo.loading,
  updating: storeState.externalAppInfo.updating,
  updateSuccess: storeState.externalAppInfo.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExternalAppInfoUpdate);
