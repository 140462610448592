import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './medical-assistant.reducer';

export interface IMedicalAssistantDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const MedicalAssistantDetail = (props: IMedicalAssistantDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { medicalAssistantEntity } = props;
  return (
    <Row>
      <Col>
        <Row className="justify-content-center">
          <Col md={4} sm={8}>
            <h2>
              <Translate contentKey="diga1App.medicalAssistant.detail.title">MedicalAssistant</Translate> [<b>{medicalAssistantEntity.id}</b>]
        </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} sm={8} className="info-box">
            <dl className="jh-entity-details">
              <dt>
                <span id="userId">
                  <Translate contentKey="diga1App.medicalAssistant.userId">User Id</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.userId}</dd>
              <dt>
                <span id="salutation">
                  <Translate contentKey="diga1App.medicalAssistant.salutation">Salutation</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.salutation}</dd>
              <dt>
                <span id="title">
                  <Translate contentKey="diga1App.medicalAssistant.title">Title</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.title}</dd>
              <dt>
                <span id="firstName">
                  <Translate contentKey="diga1App.medicalAssistant.firstName">First Name</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.firstName}</dd>
              <dt>
                <span id="lastName">
                  <Translate contentKey="diga1App.medicalAssistant.lastName">Last Name</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.lastName}</dd>
              <dt>
                <span id="gender">
                  <Translate contentKey="diga1App.medicalAssistant.gender">Gender</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.gender}</dd>
              <dt>
                <span id="langKey">
                  <Translate contentKey="diga1App.medicalAssistant.langKey">Lang Key</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.langKey}</dd>
              <dt>
                <span id="email">
                  <Translate contentKey="diga1App.medicalAssistant.email">Email</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.email}</dd>
              <dt>
                <span id="mobileNumber">
                  <Translate contentKey="diga1App.medicalAssistant.mobileNumber">Mobile Number</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.mobileNumber}</dd>
              <dt>
                <span id="verificationStatus">
                  <Translate contentKey="diga1App.medicalAssistant.verificationStatus">Verification Status</Translate>
                </span>
              </dt>
              <dd>{medicalAssistantEntity.verificationStatus}</dd>
              <dt>
                <Translate contentKey="diga1App.medicalAssistant.clinic">Clinic</Translate>
              </dt>
              <dd>
                {medicalAssistantEntity.clinics
                  ? medicalAssistantEntity.clinics.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.name}</a>
                      {i === medicalAssistantEntity.clinics.length - 1 ? '' : ', '}
                    </span>
                  ))
                  : null}
              </dd>
            </dl>
            <Button tag={Link} to="/medical-assistant" replace className="rounded" color="success" >
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
        &nbsp;
        <Button tag={Link} to={`/medical-assistant/${medicalAssistantEntity.id}/edit`} replace className="rounded" color="danger">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ medicalAssistant }: IRootState) => ({
  medicalAssistantEntity: medicalAssistant.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantDetail);
