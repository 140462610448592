import { IConversationCreation } from 'app/shared/model/conversation-creation.model';
import { NotificationChannel } from 'app/shared/model/enumerations/notification-channel.model';
import { Recurrence } from 'app/shared/model/enumerations/recurrence.model';

export interface IConversationNotification {
  id?: number;
  channel?: NotificationChannel;
  startAfter?: string;
  startTime?: string;
  recurrence?: Recurrence;
  occurrences?: number;
  conversationCreations?: IConversationCreation[];
}

export const defaultValue: Readonly<IConversationNotification> = {};
