import { Moment } from 'moment';

export interface IUserTip {
  id?: number;
  userId?: number;
  tipId?: number;
  appointmentId?: number;
  icdCodeId?: number;
  startTime?: string;
  endTime?: string;
  status?: number;
}

export const defaultValue: Readonly<IUserTip> = {};
