import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, getEntitiesWithFilters } from './appointment.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import moment from 'moment';
import SearchInputText from 'app/shared/input-text/search-input-text/search-input-text';

export interface IAppointmentProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; }> { }

export const Appointment = (props: IAppointmentProps) => {
  const [filters, setFilters] = useState({
    title: '',
    date: '',
  });
  const [paginationState, setPaginationState] = useState(getSortState(props.location, ITEMS_PER_PAGE));

  const getAllEntities = () => {
    const query = Object.keys(filters).filter((k) => filters[k] !== '').map((k) => {
      if (k === 'id') {
        return `${k}.equals=${filters[k]}`
      }
      return `${k}.contains=${filters[k]}`
    }).join('&');
    props.getEntitiesWithFilters(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, query);
  };

  const sortEntities = () => {
    getAllEntities();
    props.history.push(
      `${props.location.pathname}?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
    );
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    sortEntities();
  }, [filters.title, filters.date]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const { appointmentList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="appointment-heading">
        <Translate contentKey="diga1App.appointment.home.title">Appointments</Translate>
        <Link to={`${match.url}/new`} className="btn btn-outline-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.appointment.home.createLabel">Create new Appointment</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        {(
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('title')}>
                  <Translate contentKey="diga1App.appointment.title">Title</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        title: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('date')}>
                  <Translate contentKey="diga1App.appointment.date">Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('confirmed')}>
                  <Translate contentKey="diga1App.appointment.confirmed">Confirmed</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="diga1App.appointment.clinic">Clinic</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="diga1App.appointment.healthcareProfessional">Healthcare Professional</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="diga1App.appointment.patient">Patient</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="diga1App.appointment.icdCode">Icd Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                !loading && appointmentList?.length === 0 && (
                  <tr>
                    <td colSpan={8}>
                      <div className="alert alert-warning">
                        <Translate contentKey="diga1App.appointment.home.notFound">No Appointments found</Translate>
                      </div>
                    </td>
                  </tr>
                )
              }
              {appointmentList.map((appointment, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${appointment.id}`} color="link" size="sm">
                      {appointment.id}
                    </Button>
                  </td>
                  <td>{appointment.title}</td>
                  <td>
                    <TextFormat type="date" value={moment.tz(moment(appointment.date), 'Europe/Berlin')} format={APP_DATE_FORMAT} />
                  </td>
                  <td>{appointment.confirmed}</td>
                  <td>{appointment.clinic ? <Link to={`clinic/${appointment.clinic.id}`}>{appointment.clinic.name}</Link> : ''}</td>
                  <td>
                    {appointment.healthcareProfessional ? (
                      <Link to={`healthcare-professional/${appointment.healthcareProfessional.id}`}>
                        {appointment.healthcareProfessional.email}
                      </Link>
                    ) : (
                        ''
                      )}
                  </td>
                  <td>{appointment.patient ? <Link to={`patient/${appointment.patient.id}`}>{appointment.patient.email}</Link> : ''}</td>
                  <td>{appointment.icdCode ? <Link to={`icd-code/${appointment.icdCode.id}`}>{appointment.icdCode.id}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${appointment.id}`}
                        outline
                        className="mr-1"
                        color="success"
                        size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${appointment.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${appointment.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1 "
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      <div className={appointmentList && appointmentList.length > 0 ? '' : 'd-none'}>
        <Row className="justify-content-center">
          <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
        </Row>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={paginationState.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={paginationState.itemsPerPage}
            totalItems={props.totalItems}
          />
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ appointment }: IRootState) => ({
  appointmentList: appointment.entities,
  loading: appointment.loading,
  totalItems: appointment.totalItems
});

const mapDispatchToProps = {
  getEntities,
  getEntitiesWithFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
