import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import {
  openFile,
  byteSize,
  Translate,
  ICrudGetAllAction,
  TextFormat,
  getSortState,
  IPaginationBaseState,
  JhiPagination,
  JhiItemCount
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, getEntitiesWithFilters } from './documents.reducer';
import { IDocuments } from 'app/shared/model/documents.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import SearchInputText from 'app/shared/input-text/search-input-text/search-input-text';

export interface IDocumentsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Documents = (props: IDocumentsProps) => {
  const [filters, setFilters] = useState({
    userId: ''
  });
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    const query = Object.keys(filters)
      .filter(k => filters[k] !== '')
      .map(k => {
        return `${k}.equals=${filters[k]}`;
      })
      .join('&');
    props.getEntitiesWithFilters(
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${paginationState.sort},${paginationState.order}`,
      query
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  useEffect(() => {
    sortEntities();
  }, [filters.userId]);

  const { documentsList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="documents-heading">
        <Translate contentKey="diga1App.documents.home.title">Documents</Translate>
        <Link to={`${match.url}/new`} className="btn btn-outline-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.documents.home.createLabel">Create new Documents</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        <Table responsive>
          <thead>
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('userId')}>
                <Translate contentKey="diga1App.documents.userId">User Id</Translate> <FontAwesomeIcon icon="sort" />
                <SearchInputText
                  onSearch={text => {
                    setFilters({
                      ...filters,
                      userId: text
                    });
                  }}
                  column
                />
              </th>
              <th className="hand" onClick={sort('fileName')}>
                <Translate contentKey="diga1App.documents.fileName">File Name</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('file')}>
                <Translate contentKey="diga1App.documents.file">File</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('uploadedDate')}>
                <Translate contentKey="diga1App.documents.uploadedDate">Uploaded Date</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('fileContentType')}>
                <Translate contentKey="diga1App.documents.fileContentType">File Content Type</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!loading && documentsList && documentsList.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <div className="alert alert-warning">
                    <Translate contentKey="diga1App.documents.home.notFound">No User Preferences found</Translate>
                  </div>
                </td>
              </tr>
            )}
            {documentsList.map((documents, i) => (
              <tr key={`entity-${i}`}>
                <td>
                  <Button tag={Link} to={`${match.url}/${documents.id}`} color="link" size="sm">
                    {documents.id}
                  </Button>
                </td>
                <td>{documents.userId}</td>
                <td>{documents.fileName}</td>
                <td>
                  {documents.file ? (
                    <div>
                      {/* {documents.fileContentType ? (
                        <a onClick={openFile(documents.fileContentType, documents.file)}>
                          <Translate contentKey="entity.action.open">Open</Translate>
                          &nbsp;
                        </a>
                      ) : null} */}
                      <span>{byteSize(documents.file)}</span>
                    </div>
                  ) : null}
                </td>
                <td>
                  {documents.uploadedDate ? <TextFormat type="date" value={documents.uploadedDate} format={APP_DATE_FORMAT} /> : null}
                </td>
                <td>{documents.fileContentType}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${documents.id}`} outline className="mr-1" color="success" size="sm">
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.view">View</Translate>
                      </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`${match.url}/${documents.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      outline
                      className="rounded mr-1"
                      color="primary"
                      size="sm"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
                    </Button>
                    {props.isAdmin && (
                      <Button
                        tag={Link}
                        to={`${match.url}/${documents.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {props.totalItems ? (
        <div className={documentsList && documentsList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ documents, authentication }: IRootState) => ({
  documentsList: documents.entities,
  loading: documents.loading,
  totalItems: documents.totalItems,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN])
});

const mapDispatchToProps = {
  getEntities,
  getEntitiesWithFilters
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
