import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import IcdCode from './icd-code';
import IcdCodeDetail from './icd-code-detail';
import IcdCodeUpdate from './icd-code-update';
import IcdCodeDeleteDialog from './icd-code-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={IcdCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={IcdCodeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={IcdCodeDetail} />
      <ErrorBoundaryRoute path={match.url} component={IcdCode} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={IcdCodeDeleteDialog} />
  </>
);

export default Routes;
