export interface IIcdCode {
  id?: number;
  code?: string;
  realm?: string;
  description?: string;
  descriptionDe?: string;
  displayTextDe?: string;
  descriptionEn?: string;
  displayTextEn?: string;
  descriptionTr?: string;
  displayTextTr?: string;
}

export const defaultValue: Readonly<IIcdCode> = {};
