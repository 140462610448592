import { getStudyClinics } from 'app/entities/clinic/clinic.reducer';
import { LanguageCode } from 'app/shared/languages/language-options';
import { IRootState } from 'app/shared/reducers';
import { mapIdList } from 'app/shared/util/entity-utils';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import React, { useEffect, useState } from 'react';
import { getUrlParameter, Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import { Button, Col, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import InputMask from 'react-input-mask';
import { createSelfRegistratinoWithoutGet, reset } from '../../entities/self-registration/self-registration.reducer';
import {
  isSafari
} from "react-device-detect";
import { generatePDF, PDFFILES } from '../medical-assistant-dashboard/shard/pdfUtils';
import moment from 'moment';


export interface IAccountSelfRegistrationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const accountSelfRegistrationUpdate = (props: IAccountSelfRegistrationUpdateProps) => {

  const { accountselfRegistrationEntity, clinics, loading, updating } = props;
  const [clinic, setClinic] = useState(undefined);
  const [key, setKey] = useState('');
  const [modal, setModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [disableClinics, setDisableClinics] = useState(false);
  const [studyParticipation, setStudyParticipationLink] = useState(false);

  const toggle = () => setModal(!modal);

  const onChange = (event) => {
    setClinic(event?.value);
    setKey(event?.value?.urlUuid);
  };

  const assignClinicToItsField = (uuid) => {
    const selectedClinic = clinics.find(entity => entity.urlUuid === `${uuid}`);
    
    selectedClinic !== undefined ?
    setClinic(selectedClinic) : clinics && clinics.length > 0 ?
    (setClinic(clinics[0]), setKey(clinics[0].urlUuid)) : null;
  };

  const activateUUIDToForm = () => {
    const keyFromUrl = getUrlParameter('key', props.location.search);
    setKey(keyFromUrl);
    keyFromUrl !== '' ? (assignClinicToItsField(keyFromUrl), setDisableClinics(true)) : setDisableClinics(false);
  };

  useEffect(() => {
    activateUUIDToForm();
    clinic === undefined && clinics?.length > 0 && key === '' ? setClinic(undefined) : undefined
  }, [clinics]);

  useEffect(() => {
    props.reset();
    props.getStudyClinics();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      props.reset();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    setSubmitted(true);
    if (errors.length === 0) {
      const entity = {
        ...accountselfRegistrationEntity,
        ...values,
        birthdate: moment(values.birthdate, props.currentLanguage === 'de' ? 'DD.MM.YYYY' : 'DD/MM/YYYY').format('YYYY-MM-DD'),
        clinics: mapIdList([`${clinic.id}`]),
        studyParticipation,
      };
      if (entity?.clinics?.length <= 0) {
        return;
      }
      props.createSelfRegistratinoWithoutGet(entity);
    }
  };

  const validate = (value: any, ctx: any, input: any, cb: any) => {
    const date = moment(value, props.currentLanguage === 'de' ? 'DD.MM.YYYY' : 'DD/MM/YYYY').format('YYYY-MM-DD');
    const age = moment().diff(moment(date), 'years');
    console.log({ value, ctx, input, cb, age });
    if (age < 18 || age > 130) {
      cb(translate('global.messages.validate.birthdate.invalidAge'))
      return;
    }
    cb(true);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="6" sm={8} className="info-box-selfRegistration">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={{}} onSubmit={saveEntity}>
              <Label className="info-box-selfRegistration-description">
                <Translate contentKey={"diga1App.selfRegistration.description"}>
                </Translate>
              </Label>
              <div>
                <Col md={8}>
                  <div style={{ margin: "1rem 0rem 1rem 1rem" }}>
                    <Label for="self-registration-clinic" style={{ color: submitted && !clinic && 'red' }}>
                      <Translate contentKey="diga1App.selfRegistration.Empfänger">Clinic</Translate>
                    </Label>
                    <Select
                      onMenuClose={() => setSubmitted(true)}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderColor: submitted && !clinic && 'red'
                        })
                      }}
                      options={[
                        { label: translate('diga1App.selfRegistration.pleaseSelect'), value: undefined },
                        ...clinics.map(entity => ({
                          label: entity.name,
                          value: entity
                        }))
                      ]}
                      onChange={(event) => onChange(event)}
                      value={key !== '' && clinic !== undefined ? ({ label: clinic.name, value: clinic }) : undefined}
                      placeholder={translate('diga1App.selfRegistration.pleaseSelect')}
                      isDisabled={disableClinics}
                    />
                    <div style={{ margin: "2%" }}>
                      {clinic !== undefined ?
                        (clinic.street !== undefined ? clinic.street : '') + ' ' +
                        (clinic.houseNumber !== undefined ? clinic.houseNumber : '') + ', ' +
                        (clinic.zipCode !== undefined ? clinic.zipCode : '') + ' ' +
                        (clinic.city !== undefined ? clinic.city : '') : ''}</div>

                  </div>
                </Col>
              </div>

              <Row md={8} style={{ margin: "1rem" }}>
                <Col>
                  <AvGroup>
                    <Label id="firstNameLabel" for="self-registration-firstName">
                      <Translate contentKey="diga1App.selfRegistration.firstName">First Name</Translate>
                    </Label>
                    <AvField
                      id="self-registration-firstName"
                      type="text"
                      name="firstName"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col>
                  <AvGroup >
                    <Label id="lastNameLabel" for="self-registration-lastName">
                      <Translate contentKey="diga1App.selfRegistration.lastName">Last Name</Translate>
                    </Label>
                    <AvField
                      id="self-registration-lastName"
                      type="text"
                      name="lastName"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) },
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col>
                  <AvGroup>
                    <Label id="langKeyLabel" for="self-registration-langKey">
                      <Translate contentKey="diga1App.selfRegistration.langKey">Lang Key</Translate>
                    </Label>
                    <AvInput
                      id="self-registration-langKey"
                      type="select"
                      name="langKey"
                      value={''}
                      required
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                        maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                      }}
                    >
                      <option value={''}>{translate('diga1App.selfRegistration.pleaseSelect')}</option>
                      <option value={LanguageCode.GERMAN}>{translate('global.form.languageKey.german')}</option>
                      <option value={LanguageCode.ENGLISH}>{translate('global.form.languageKey.english')}</option>
                      <option value={LanguageCode.TURKISH}>{translate('global.form.languageKey.turkish')}</option>
                    </AvInput>
                  </AvGroup>
                </Col>
              </Row>

              <Row style={{ margin: "1rem" }}>
                <Col>
                  <AvGroup>
                    <Label id="genderLabel" for="self-registration-gender">
                      <Translate contentKey="diga1App.selfRegistration.gender">Gender</Translate>
                    </Label>
                    <AvInput
                      id="self-registration-gender"
                      type="select"
                      className="form-control"
                      name="gender"
                      value={translate('diga1App.gender.male')}
                    > 
                      <option value={translate('diga1App.gender.male')}>{translate('diga1App.gender.male')}</option>
                      <option value={translate('diga1App.gender.female')}>{translate('diga1App.gender.female')}</option>
                      <option value={translate('diga1App.gender.other')}>{translate('diga1App.gender.other')}</option>
                    </AvInput>
                  </AvGroup>
                </Col>
                <Col>
                  <AvGroup>
                    <Label id="birthdateLabel" for="self-registration-birthdate">
                      <Translate contentKey="diga1App.selfRegistration.birthdate">Birthdate</Translate>
                    </Label>
                    <AvField
                      id="self-registration-birthdate"
                      mask={props.currentLanguage === 'de' ? '99.99.9999' :"99/99/9999"}
                      placeholder={props.currentLanguage === 'de' ? 'tt.mm.jjjj' :"dd/mm/yyyy"}
                      className="form-control"
                      maskChar="-"
                      name="birthdate"
                      tag={[Input, InputMask]}
                      required
                      validate={{
                          required: { value: true, errorMessage: translate('entity.validation.required') },
                          async: validate
                      }}
                      helpMessage={`Format ${props.currentLanguage === 'de' ? 'tt.mm.jjjj' : "dd/mm/yyyy"}`}
                    />
                    {/* <AvField
                      id="self-registration-birthdate"
                      type="date"
                      className="form-control"
                      name="birthdate"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        dateRange: {
                          start: { value: -130, units: 'years', },
                          end: { value: -18, units: 'years', },
                          errorMessage: translate('global.messages.validate.birthdate.invalidAge')
                        }
                      }}
                    /> */}
                  </AvGroup>
                </Col>
                <Col>
                  <AvGroup>
                    <Label id="genderLabel" for="self-registration-gender">
                      <Translate contentKey="diga1App.selfRegistration.reasonForTreatment">Reason For Treatment</Translate>
                    </Label>
                    <AvInput
                      id="self-registration-gender"
                      type="select"
                      className="form-control"
                      name="reason"
                      required
                      value={''}
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                      }}
                    >
                      <option value={''}>{translate('diga1App.selfRegistration.pleaseSelect')}</option>
                      <option value={translate('diga1App.selfRegistration.reasonForTreatment1')}>{translate('diga1App.selfRegistration.reasonForTreatment1')}</option>
                      <option value={translate('diga1App.selfRegistration.reasonForTreatment2')}>{translate('diga1App.selfRegistration.reasonForTreatment2')}</option>
                      <option value={translate('diga1App.selfRegistration.reasonForTreatment3')}>{translate('diga1App.selfRegistration.reasonForTreatment3')}</option>
                    </AvInput>
                  </AvGroup>
                </Col>
              </Row>
              <Row style={{ margin: "1rem" }}>
                <Col>
                  <AvGroup>
                    <Label id="emailLabel" for="self-registration-email">
                      <Translate contentKey="diga1App.selfRegistration.email">Email</Translate>
                    </Label>
                    <AvField
                      id="self-registration-email"
                      type="email"
                      name="email"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        pattern: {
                          value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          errorMessage: translate('global.messages.validate.email.invalid')
                        }

                      }}
                    />
                  </AvGroup>
                </Col>
                <Col md={7}>
                  <AvGroup>
                    <Label id="mobileNumberLabel" for="self-registration-mobileNumber">
                      <Translate contentKey="diga1App.selfRegistration.mobileNumber">Mobile Number</Translate>
                    </Label>
                    <AvField
                      id="self-registration-mobileNumber"
                      type="text"
                      name="mobileNumber"
                      placeholder={translate('global.messages.validate.mobileNumber.placeHolder')}
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        pattern: {
                          value: '^([0-9]{2,})$',
                          errorMessage: translate('global.messages.validate.mobileNumber.invalidPattern')
                        },
                        maxLength: { value: 20, errorMessage: translate('global.messages.validate.mobileNumber.maxlength', { max: 20 }) }
                      }}
                    />
                  </AvGroup>
                </Col>
              </Row>


              <AvGroup check>
                <Label>
                  <Translate contentKey="diga1App.selfRegistration.DatenschutzEinwilligung"></Translate>
                </Label>
                <AvGroup style={{ margin: "1rem 0rem 1rem 2rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="personalData"
                      required
                    />
                    <Translate contentKey="activationTermsConditions.selfRegistration.consentStart"></Translate>
                    <a onClick={toggle}>
                      <Translate contentKey="activationTermsConditions.selfRegistration.consentLink"></Translate>
                    </a>
                    <Translate contentKey="activationTermsConditions.selfRegistration.consentEnd"></Translate>
                  </Label>
                </AvGroup>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle}>
                    <Translate contentKey="diga1App.selfRegistration.consent.title"></Translate>
                  </ModalHeader>
                  <ModalBody>
                    <Translate contentKey="diga1App.selfRegistration.consent.body"></Translate>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="success" onClick={toggle}>Close</Button>{' '}
                  </ModalFooter>
                </Modal>

                <AvGroup style={{ margin: "0rem 0rem 1rem 2rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="privacyPolicyData"
                      required
                    />
                    <Translate contentKey="activationTermsConditions.agreeTo"></Translate>
                    <a onClick={() => window.open("/account/privacyPolicyDataPage?language=" + props.currentLanguage)}>
                      <Translate contentKey="activationTermsConditions.privacyPolicyData"></Translate>
                    </a>
                  </Label>
                </AvGroup>

                <AvGroup style={{ margin: "0rem 0rem 1rem 2rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="agreeToTermsAndConditions"
                      required
                    />
                    <Translate contentKey="activationTermsConditions.agreeTo"></Translate>
                    <a onClick={() => window.open("/account/termsAndConditionsDataPage?language=" + props.currentLanguage)}>
                      <Translate contentKey="activationTermsConditions.agreeToTermsAndConditions"></Translate>
                    </a>
                  </Label>
                </AvGroup>

                {props.currentLanguage !== 'tr' ? (
                  <AvGroup style={{ margin: "0rem 0rem 1rem 2rem" }}>
                    <Label className="form-check-label">
                      <AvInput
                        type="checkbox"
                        name="agreeToDataProtection"
                        required
                      />
                        <Translate contentKey="activationTermsConditions.agreeTo"></Translate>
                        <a onClick={() => window.open(`/account/dataProcessingDataPage?language=${props.currentLanguage}&dataProcessing=1`)}><Translate contentKey="activationTermsConditions.dataProcessing.title"></Translate></a>
                    </Label>
                  </AvGroup>
                ) : null}

                <AvGroup style={{ margin: "0rem 0rem 1rem 2rem" }}>
                  <Label className="form-check-label">
                    <AvInput
                      type="checkbox"
                      name="studyParticipation"
                      required
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setStudyParticipationLink(event.target.checked)} />
                    { props.currentLanguage !== 'tr' ? <Translate contentKey="activationTermsConditions.studyParticipation"></Translate> : null}
                    <a onClick={() => generatePDF(PDFFILES.STUDY_PARTICIPATION_DE)}><Translate contentKey="activationTermsConditions.studyParticipationLink"></Translate></a>
                    { props.currentLanguage === 'tr' ? <Translate contentKey="activationTermsConditions.studyParticipation"></Translate> : null}
                  </Label>
                </AvGroup>
              </AvGroup>

               &nbsp;
              <Button
                style={{
                  margin: "0rem 0rem 2rem 2rem",
                  borderRadius: "20px",
                  backgroundPosition: "left-top",
                  backgroundRepeat: "repeat",
                  padding: "8px",
                  width: "20%"
                }} color="success" id="save-entity" type="submit" disabled={updating}>
                &nbsp;
                <span style={{ fontSize: "14px" }}><Translate contentKey="diga1App.selfRegistration.send">Send</Translate></span>
              </Button>
              <Col>
                <Row>

                </Row>
                <Row>

                </Row>
              </Col>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  clinics: storeState.clinic.entities,
  accountselfRegistrationEntity: storeState.selfRegistration.entity,
  loading: storeState.selfRegistration.loading,
  updating: storeState.selfRegistration.updating,
  updateSuccess: storeState.selfRegistration.updateSuccess,
  currentLanguage: storeState.locale.currentLocale ? storeState.locale.currentLocale : 'de'
});

const mapDispatchToProps = {
  getStudyClinics,
  createSelfRegistratinoWithoutGet,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(accountSelfRegistrationUpdate);
