import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster'; import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { reset, updateEntity as updatePatient } from 'app/entities/patient/patient.reducer';
import '../ma-pages.scss';
import { LanguageCode } from 'app/shared/languages/language-options';
import { REALM } from '../../../shared/constants/realm.model';
import { useHistory } from 'react-router-dom';

export interface IMaDashPatientCreateUpdateProps extends StateProps, DispatchProps { }

const MaDashUpdatePatient = (props: IMaDashPatientCreateUpdateProps) => {
    const [phone, setPhone] = useState("");
    const history = useHistory();

    useEffect(() => {
        if (props.selectedPatientToUpdate) {
            setPhone(props.selectedPatientToUpdate && props.selectedPatientToUpdate.mobileNumber);
        } else {
            history.push('/ma-dashboard/Patients')
        }
    }, []);

    const saveEntity = (event, errors, values) => {
        let patient = {
            ...props.selectedPatientToUpdate,
            ...values
        };

        props.selectedPatientToUpdate.realm === REALM.DIGA1 ? patient.realm = REALM.DIGA1 : patient.realm = REALM.VELARIA;
        const preferencesObject = JSON.parse(patient.preferences);
        patient = {
            ...patient,
            preferences: preferencesObject
        }
        if (errors.length === 0) {
            patient.clinics = [props.selectedClinic];
            props.updatePatient(patient);
        }
    };

    const defaultValues = {
        ...props.selectedPatientToUpdate,
        appRadio: props.selectedPatientToUpdate && props.selectedPatientToUpdate.realm === REALM.DIGA1 ? REALM.DIGA1_APP : REALM.VELARIA_APP
    }

    return (
        <Col>
            <Row>
                <Col>
                    {props.loading ? (
                        <p>Loading...</p>
                    ) : (
                        <AvForm model={defaultValues} onSubmit={saveEntity}>
                            <Row style={{ width: '100%', margin: '0 0 0 0' }}>
                                <h2 style={{ marginRight: '1rem' }} id="diga1App.patient.home.changeLabel">
                                    <Translate contentKey="diga1App.patient.home.changeLabel">DiGA1 invitation</Translate>
                                </h2>
                                <AvRadioGroup inline
                                    name="appRadio" errorMessage="Pick your app!">
                                    <AvRadio value={REALM.DIGA1_APP} disabled />
                                    <img style={{ width: '9%', margin: '0 1rem 0 -0.2rem', paddingTop: '8px' }} src="../../../content/images/diga1-logo.svg" alt="logo" className="login-box-logo"></img>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <AvRadio value={REALM.VELARIA_APP} disabled />
                                    <img style={{ width: '10%', margin: '0 1rem 0 -0.2rem' }} src="../../../content/images/velaria-logo.svg" alt="logo" className="login-box-logo"></img>
                                </AvRadioGroup>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="salutationLabel" for="patient-salutation">
                                            <Translate contentKey="diga1App.patient.salutation">Salutation</Translate>
                                        </Label>
                                        <AvInput
                                            id="patient-salutation"
                                            type="select"
                                            className="form-control"
                                            name="salutation"
                                        >
                                            <option value={translate('diga1App.salutation.mr')}>{translate('diga1App.salutation.mr')}</option>
                                            <option value={translate('diga1App.salutation.ms')}>{translate('diga1App.salutation.ms')}</option>
                                        </AvInput>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label id="titleLabel" for="patient-title">
                                            <Translate contentKey="diga1App.patient.title">Title</Translate>
                                        </Label>
                                        <AvField
                                            id="patient-title"
                                            type="text"
                                            name="title"
                                            validate={{
                                                maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="firstNameLabel" for="patient-firstName">
                                            <Translate contentKey="diga1App.patient.firstName">First Name</Translate>
                                        </Label>
                                        <AvField
                                            id="patient-firstName"
                                            type="text"
                                            name="firstName"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label id="lastNameLabel" for="patient-lastName">
                                            <Translate contentKey="diga1App.patient.lastName">Last Name</Translate>
                                        </Label>
                                        <AvField
                                            id="patient-lastName"
                                            type="text"
                                            name="lastName"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="genderLabel" for="patient-gender">
                                            <Translate contentKey="diga1App.patient.gender">Gender</Translate>
                                        </Label>
                                        <AvInput
                                            id="patient-gender"
                                            type="select"
                                            className="form-control"
                                            name="gender"
                                        >
                                            <option value={translate('diga1App.gender.male')}>{translate('diga1App.gender.male')}</option>
                                            <option value={translate('diga1App.gender.female')}>{translate('diga1App.gender.female')}</option>
                                            <option value={translate('diga1App.gender.other')}>{translate('diga1App.gender.other')}</option>
                                        </AvInput>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label id="emailLabel" for="patient-email">
                                            <Translate contentKey="diga1App.patient.email">Email</Translate>
                                        </Label>
                                        <AvField
                                            id="patient-email"
                                            type="email"
                                            name="email"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label id="mobileNumberLabel" for="patient-mobileNumber">
                                        <Translate contentKey="diga1App.patient.mobileNumber">Mobile Number</Translate>
                                    </Label>

                                    <AvField
                                        id="patient-mobileNumber"
                                        type="text"
                                        placeholder="Contry Code and mobile number"
                                        name="mobileNumber"
                                        validate={{
                                            required: { value: true, errorMessage: translate('entity.validation.required') },
                                            pattern: {
                                                value: '^([0-9]{2,})$',
                                                errorMessage: translate('global.messages.validate.mobileNumber.invalidPattern')
                                            },
                                            maxLength: { value: 20, errorMessage: translate('global.messages.validate.mobileNumber.maxlength', { max: 20 }) }
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label id="birthdateLabel" for="patient-birthdate">
                                            <Translate contentKey="diga1App.patient.birthdate">Birthdate</Translate>
                                        </Label>
                                        <AvField
                                            id="patient-birthdate"
                                            type="date"
                                            className="form-control"
                                            name="birthdate"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                dateRange: {
                                                    start: { value: -130, units: 'years', }, end: { value: -18, units: 'years', },
                                                    errorMessage: translate('global.messages.validate.birthdate.invalidAge')
                                                }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="langKeyLabel" for="patient-langKey">
                                            <Translate contentKey="diga1App.patient.langKey">Lang Key</Translate>
                                        </Label>
                                        <AvInput
                                            id="patient-langKey"
                                            type="select"
                                            name="langKey"
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                minLength: { value: 2, errorMessage: translate('entity.validation.minlength', { min: 2 }) },
                                                maxLength: { value: 10, errorMessage: translate('entity.validation.maxlength', { max: 10 }) },
                                            }}
                                        >
                                            <option value={LanguageCode.GERMAN}>{translate('global.form.languageKey.german')}</option>
                                            <option value={LanguageCode.ENGLISH}>{translate('global.form.languageKey.english')}</option>
                                            <option value={LanguageCode.TURKISH}>{translate('global.form.languageKey.turkish')}</option>
                                        </AvInput>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} />
                                <Col xs={6}>
                                    <div className="wizard-form-in-button">
                                        <Button color="primary" id="save-entity" type="submit" disabled={props.updating}>
                                            <FontAwesomeIcon icon="save" />
                                      &nbsp;
                                      <Translate contentKey="entity.action.save">Save</Translate>
                                        </Button>
                                    </div>
                                </Col>
                                <Col />
                            </Row>
                        </AvForm>
                    )}
                </Col>
            </Row>
        </Col>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    selectedClinic: storeState.medicalAssistant.maClinic,
    selectedPatientToUpdate: storeState.maDashboardReducer.selectedPatient,
    loading: storeState.patient.loading,
    updating: storeState.patient.updating,
    updateSuccess: storeState.patient.updateSuccess,
    currentLocale: storeState.locale.currentLocale
});

const mapDispatchToProps = {
    updatePatient,
    reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MaDashUpdatePatient);
