import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { Row, Col } from 'reactstrap';
import MaDashboardButton from '../shard/ma-dashboard-button/ma-dashboard-button';
import { updateSelectedClinic } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer';
import { translate } from 'react-jhipster';
import { MA_DASH_PAGE } from './pages-list';

interface IMedicalAssistantDashboardTopArea extends StateProps, DispatchProps { }

const MedicalAssistantDashboardTopArea = (props: IMedicalAssistantDashboardTopArea) => {
    const [greeting, setGreeting] = useState("Hello");

    const updateGreeting = () => {
        const hour = new Date().getHours();
        let calcGreeting = 'maDashboard.topArea.hello';
        if (hour < 12) {
            calcGreeting = 'maDashboard.topArea.morning';
        }
        else if (hour >= 12 && hour <= 17) {
            calcGreeting = 'maDashboard.topArea.afternoon';
        }
        else if (hour >= 17 && hour <= 24) {
            calcGreeting = 'maDashboard.topArea.evening';
        }
        setGreeting(calcGreeting);
    };

    useEffect(() => {
        updateGreeting();
    }, []);



    return (
        <Row>
            <Col>
                <Row>
                    <Col>

                        <h3>{translate(greeting)}, {props.userFirstName}!</h3>
                        <br />
                    </Col>
                    <Col md={4}>
                        <MaDashboardButton
                            svg="content/images/new-appointment.svg"
                            text={translate(MA_DASH_PAGE.NEW_APPOINTMENT)}>
                        </MaDashboardButton>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <h2 style={{ width: "max-content" }}>{new Date(props.selectedDate).toLocaleDateString(props.currentLocale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</h2>
                    </Col>
                    <Col></Col>

                </Row>
            </Col>
        </Row>
    );
};

const mapStateToProps = (storeState: IRootState) => {
    return {
        userFirstName: storeState.medicalAssistant.entity.firstName,
        selectedDate: storeState.maDashboardReducer.selectedDate,
        currentLocale: storeState.locale.currentLocale
    };
};

const mapDispatchToProps = {
    updateSelectedClinic
};


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;



export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantDashboardTopArea);