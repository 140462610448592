import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Translate, translate, getUrlParameter } from 'react-jhipster';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { sendSms, verifySms, reset } from './sms-code-verification.reducer';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import SmsVerificationFailedMessage from './SmsVerificationFailedMessage';
import { logoutUsers } from '../../../shared/reducers/authentication';
import { setLocale } from 'app/shared/reducers/locale';

export interface IVerifySmsAndActivate extends StateProps, DispatchProps, RouteComponentProps<{ key: any; }> { }

const VerifySms = (props: IVerifySmsAndActivate) => {
    const [key, setkey] = useState('');
    const [smsCode, setSmsCode] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [firstSmsSent, setFirstSmsSent] = useState(false);
    const [userKeyNotFound, setUserKeyNotFound] = useState(false);
    const [smsVerificationFailed, setSmsVerificationFailed] = useState(false);
    const [smsVerifiedSuccessfully, setSmsVerifiedSuccessfully] = useState(false);

    useEffect(() => {
        props.logoutUsers();

        const keyFromUrl = getUrlParameter('key', props.location.search);
        setkey(keyFromUrl);


        if (!firstSmsSent) {
            axios.get('api/send-sms?key=' + keyFromUrl)
                .then(result => {

                    if (result.status === 200 && result.data === null) {
                        setUserKeyNotFound(true);
                    }
                    else {
                        setFirstSmsSent(true);
                        setMobileNumber(result.data.mobileNumber);
                        props.setLocale(result.data.langKey);
                    }
                })
                .catch(error => {
                    setSmsVerificationFailed(true);
                });
        }

    }, []);

    const handleValidSubmit = (event, values) => {


        axios.post('api/verify-sms', { key, smsCode })
            .then(result => {
                if (result.status === 200 && result.data === "SUCCESS") {
                    setSmsVerifiedSuccessfully(true);

                }
            })
            .catch(err => {
                setSmsVerificationFailed(true);
            });
    };

    const updateSmsCode = (e) => {
        const smsCodeToSet = e.target.value;
        setSmsCode(smsCodeToSet);
        setSmsVerificationFailed(false);
    };

    if (smsVerifiedSuccessfully) {
        return <Redirect to={`/account/activateInterim1/?key=${key}`} />;
    } else
        return (
            <Row>
                <Col />
                <Col xs={12} md={6} lg={3} style={{ border: "solid 1px", backgroundColor: "white" }}>

                    <Row style={{ border: "solid 1px", backgroundColor: "#fdbb38" }}>
                        <Col xs={1} />
                        <Col>
                            <div style={{ color: "white", fontSize: "90px" }}>1</div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "1rem" }}>
                        <Col xs={1} />
                        <Col>
                            <div className="wizard-box-title">
                                <Translate contentKey="smsCodeVerificationForm.title" />
                            </div>
                        </Col>
                    </Row>

                    {userKeyNotFound ?

                        <Row>
                            <Col xs={1} />
                            <Col>
                                <div>
                                    <p style={{ color: "Red", marginTop: "2rem", marginBottom: "15rem" }}>
                                        <Translate contentKey="smsCodeVerificationForm.messages.userNotFound" />
                                    </p>
                                </div>
                            </Col>
                            <Col xs={1} />
                        </Row>
                        :
                        <Row>
                            <Col>
                                <Row style={{ marginTop: "1rem" }}>
                                    <Col xs={1} />
                                    <Col>
                                        <div className="wizard-box-sub-title">
                                            <Translate contentKey="smsCodeVerificationForm.subTitle" />
                                        </div>
                                    </Col>
                                    <Col xs={1} />
                                </Row>
                                <Row style={{ marginTop: "2rem" }}>
                                    <Col xs={1} />
                                    <Col>
                                        <AvForm id="sms-code-verifiation-form" onValidSubmit={handleValidSubmit} >
                                            <AvField
                                                name="smsCode"
                                                label={translate('global.form.smsVerificationCode.label')}
                                                placeholder={translate('global.form.smsVerificationCode.placeholder')}
                                                type="text"
                                                validate={{
                                                    required: { value: true, errorMessage: translate('global.messages.validate.smsCode.required') },
                                                    pattern: { value: '^[0-9]+$', errorMessage: translate('global.messages.validate.smsCode.invalid') },
                                                    minLength: { value: 6, errorMessage: translate('global.messages.validate.smsCode.minlength') },
                                                    maxLength: { value: 6, errorMessage: translate('global.messages.validate.smsCode.maxlength') }
                                                }}
                                                onChange={updateSmsCode}
                                            />
                                            <SmsVerificationFailedMessage smsVerificationFailed={smsVerificationFailed} mobileNumber={mobileNumber} />

                                            <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                                                <Col />
                                                <Col xs={8}>
                                                    <button
                                                        type="submit"
                                                        className="align-self-center app-button"
                                                    >
                                                        <Translate contentKey="smsCodeVerificationForm.form.button">Next</Translate>
                                                    </button>
                                                </Col>
                                                <Col />
                                            </Row>
                                        </AvForm>
                                    </Col>
                                    <Col xs={1} />
                                </Row>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col />
            </Row>
        );
};

const mapStateToProps = ({ verifySmsReducer, locale }: IRootState) => ({
    verificationSuccess: verifySmsReducer.verificationSuccess,
    verificationFailure: verifySmsReducer.verificationFailure,
    currentLocale: locale.currentLocale,
});

const mapDispatchToProps = { sendSms, verifySms, reset, logoutUsers, setLocale };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VerifySms);