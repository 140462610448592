import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IConversationNotification } from 'app/shared/model/conversation-notification.model';
import { getEntities as getConversationNotifications } from 'app/entities/conversation-notification/conversation-notification.reducer';
import { getEntity, updateEntity, createEntity, reset, getConversationCatalogUniqueTypes } from './conversation-creation.reducer';
import { getEntities } from 'app/entities/icd-code/icd-code.reducer';

import { IConversationCreation } from 'app/shared/model/conversation-creation.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { isArray } from 'lodash';

export interface IConversationCreationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ConversationCreationUpdate = (props: IConversationCreationUpdateProps) => {
  const [idsconversationNotification, setIdsconversationNotification] = useState([]);
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { conversationCreationEntity, conversationNotifications, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/conversation-creation' + props.location.search);
  };

  useEffect(() => {
    props.getEntities();
    props.getConversationCatalogUniqueTypes();
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getConversationNotifications();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...conversationCreationEntity,
        ...values,
        conversationNotifications: mapIdList(values.conversationNotifications),
        icdCodeId: values.icdCodeId || 0
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  console.log('props.conversationTypes ', props.conversationTypes)
  return (
    <div>
      <Row className="justify-content-center">
      <Col md={4} sm={8}>
          <h2 id="diga1App.conversationCreation.home.createOrEditLabel">
            <Translate contentKey="diga1App.conversationCreation.home.createOrEditLabel">Create or edit a ConversationCreation</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
      <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : conversationCreationEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="conversation-creation-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="conversation-creation-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="icdCodeIdLabel" for="conversation-creation-icdCodeId">
                  <Translate contentKey="diga1App.conversationCreation.icdCodeId">Icd Code</Translate>
                </Label>
                <AvInput
                  id="conversation-creation-icdCodeId"
                  type="select"
                  className="form-control"
                  name="icdCodeId"
                  value={!isNew && conversationCreationEntity.icdCodeId}
                >
                  <option value={'0'}>
                    {translate('diga1App.conversationCreation.allIcdCodes')}
                  </option>
                  {isArray(props.icdCodeList)
                    ? props.icdCodeList.map(icdCode => <option key={icdCode.id} value={icdCode.id}>{`${icdCode.code}: ${icdCode.description}`}</option>)
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="conversationTypeIdLabel" for="conversation-creation-conversationTypeId">
                  <Translate contentKey="diga1App.conversationCreation.conversationTypeId">Chatbot</Translate>
                </Label>
                <AvInput
                  id="conversation-creation-conversationTypeId"
                  type="select"
                  className="form-control"
                  name="conversationTypeId"
                  value={(!isNew && conversationCreationEntity.conversationTypeId) || props.conversationTypes?.[0]?.id}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                >
                  {isArray(props.conversationTypes)
                    ? props.conversationTypes.map(conversationType => <option key={conversationType.id} value={conversationType.id}>{conversationType.name}</option>)
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="triggersWhenLabel" for="conversation-creation-triggersWhen">
                  <Translate contentKey="diga1App.conversationCreation.triggersWhen">Triggers When</Translate>
                </Label>
                <AvInput
                  id="conversation-creation-triggersWhen"
                  type="select"
                  className="form-control"
                  name="triggersWhen"
                  value={(!isNew && conversationCreationEntity.triggersWhen) || 'firstLogin'}
                >
                  {[{id: 'firstLogin', label: 'First Login'}].map(icdCode => <option key={icdCode.id} value={icdCode.id}>{`${icdCode.label}`}</option>)}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="startsAfterLabel" for="conversation-creation-startsAfter">
                  <Translate contentKey="diga1App.conversationCreation.startsAfter">Starts After</Translate>
                </Label>
                <AvField
                  id="conversation-creation-startAfter"
                  type="text"
                  name="startAfter"
                  pattern="[0-9]:[hdwmy]{1}"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 5 },
                    pattern: {
                        value: '^([0-9]+)([hdwmy])$',
                        errorMessage: translate('global.messages.validate.appointment.invalidDuration')
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="startTimeLabel" for="conversation-creation-startTime">
                  <Translate contentKey="diga1App.conversationCreation.startTime">Start Time</Translate>
                </Label>
                <AvField
                  id="conversation-creation-startTime"
                  type="time"
                  name="startTime"
                  placeholder="HH:MM"
                  pattern="[0-9]{2}:[0-9]{2}"
                  validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 5 },
                      pattern: {
                          value: '^(20|21|22|23|[01]\\d|\\d)((:[0-5]\\d){1})$',
                          errorMessage: translate('global.messages.validate.appointment.invalidTime')
                      }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label for="conversation-creation-conversationNotification">
                  <Translate contentKey="diga1App.conversationCreation.conversationNotification">Conversation Notification</Translate>
                </Label>
                <AvInput
                  id="conversation-creation-conversationNotification"
                  type="select"
                  multiple
                  className="form-control"
                  name="conversationNotifications"
                  value={
                    conversationCreationEntity.conversationNotifications &&
                    conversationCreationEntity.conversationNotifications.map(e => e.id)
                  }
                >
                  <option value="" key="0" />
                  {conversationNotifications
                    ? conversationNotifications.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {`${otherEntity.id}_${otherEntity.channel}_${otherEntity.startAfter}_${otherEntity.startTime}_${otherEntity.recurrence}_${otherEntity.occurrences}`}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/conversation-creation" replace className="rounded" color="success">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  conversationNotifications: storeState.conversationNotification.entities,
  conversationCreationEntity: storeState.conversationCreation.entity,
  loading: storeState.conversationCreation.loading,
  updating: storeState.conversationCreation.updating,
  updateSuccess: storeState.conversationCreation.updateSuccess,
  icdCodeList: storeState.icdCode.entities,
  conversationTypes: storeState.conversationCreation.uniqueConversationType,
});

const mapDispatchToProps = {
  getConversationNotifications,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  getEntities,
  getConversationCatalogUniqueTypes,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationCreationUpdate);
