import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IConversationCreation } from 'app/shared/model/conversation-creation.model';
import { getEntities as getConversationCreations } from 'app/entities/conversation-creation/conversation-creation.reducer';
import { getEntity, updateEntity, createEntity, reset } from './conversation-notification.reducer';
import { IConversationNotification } from 'app/shared/model/conversation-notification.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IConversationNotificationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ConversationNotificationUpdate = (props: IConversationNotificationUpdateProps) => {
  const [conversationCreationId, setConversationCreationId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { conversationNotificationEntity, conversationCreations, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/conversation-notification' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getConversationCreations();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...conversationNotificationEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
      <Col md={4} sm={8}>
          <h2 id="diga1App.conversationNotification.home.createOrEditLabel">
            <Translate contentKey="diga1App.conversationNotification.home.createOrEditLabel">
              Create or edit a ConversationNotification
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
      <Col md={4} sm={8} className="info-box">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : conversationNotificationEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="conversation-notification-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="conversation-notification-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="channelLabel" for="conversation-notification-channel">
                  <Translate contentKey="diga1App.conversationNotification.channel">Channel</Translate>
                </Label>
                <AvInput
                  id="conversation-notification-channel"
                  type="select"
                  className="form-control"
                  name="channel"
                  value={(!isNew && conversationNotificationEntity.channel) || 'EMAIL'}
                >
                  <option value="EMAIL">{translate('diga1App.NotificationChannel.EMAIL')}</option>
                  <option value="SMS">{translate('diga1App.NotificationChannel.SMS')}</option>
                  <option value="PUSH">{translate('diga1App.NotificationChannel.PUSH')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="starstAfterLabel" for="conversation-notification-starstAfter">
                  <Translate contentKey="diga1App.conversationNotification.starstAfter">Starts After</Translate>
                </Label>
                <AvField id="conversation-notification-starstAfter" type="text" name="startAfter" />
              </AvGroup>
              <AvGroup>
                <Label id="startTimeLabel" for="conversation-notification-startTime">
                  <Translate contentKey="diga1App.conversationNotification.startTime">Start Time</Translate>
                </Label>
                <AvField
                  id="conversation-notification-startTime"
                  type="time"
                  name="startTime"
                  placeholder="HH:MM"
                  pattern="[0-9]{2}:[0-9]{2}"
                  validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 5 },
                      pattern: {
                          value: '^(20|21|22|23|[01]\\d|\\d)((:[0-5]\\d){1})$',
                          errorMessage: translate('global.messages.validate.appointment.invalidTime')
                      }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="recurrenceLabel" for="conversation-notification-recurrence">
                  <Translate contentKey="diga1App.conversationNotification.recurrence">Recurrence</Translate>
                </Label>
                <AvInput
                  id="conversation-notification-recurrence"
                  type="select"
                  className="form-control"
                  name="recurrence"
                  value={(!isNew && conversationNotificationEntity.recurrence) || 'NONE'}
                >
                  <option value="NONE">{translate('diga1App.Recurrence.NONE')}</option>
                  <option value="HOURLY">{translate('diga1App.Recurrence.HOURLY')}</option>
                  <option value="DAILY">{translate('diga1App.Recurrence.DAILY')}</option>
                  <option value="WEEKLY">{translate('diga1App.Recurrence.WEEKLY')}</option>
                  <option value="MONTHLY">{translate('diga1App.Recurrence.MONTHLY')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="occurrencesLabel" for="conversation-notification-occurrences">
                  <Translate contentKey="diga1App.conversationNotification.occurrences">Occurrences</Translate>
                </Label>
                <AvField id="conversation-notification-occurrences" type="string" className="form-control" name="occurrences" />
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/conversation-notification" replace className="rounded" color="success">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button className="rounded" color="danger" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  conversationCreations: storeState.conversationCreation.entities,
  conversationNotificationEntity: storeState.conversationNotification.entity,
  loading: storeState.conversationNotification.loading,
  updating: storeState.conversationNotification.updating,
  updateSuccess: storeState.conversationNotification.updateSuccess,
});

const mapDispatchToProps = {
  getConversationCreations,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationNotificationUpdate);
