import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TaskCatalogDe from './task-catalog-de';
import TaskCatalogDeDetail from './task-catalog-de-detail';
import TaskCatalogDeUpdate from './task-catalog-de-update';
import TaskCatalogDeDeleteDialog from './task-catalog-de-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TaskCatalogDeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TaskCatalogDeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TaskCatalogDeDetail} />
      <ErrorBoundaryRoute path={match.url} component={TaskCatalogDe} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={TaskCatalogDeDeleteDialog} />
  </>
);

export default Routes;
