export interface IApplicationVersion {
  id?: number;
  androidSoftVersion?: string;
  androidHardVersion?: string;
  iosSoftVersion?: string;
  iosHardVersion?: string;
  realm?: string;
}

export const defaultValue: Readonly<IApplicationVersion> = {};
