import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './score-calculation.reducer';
import { IScoreCalculation } from 'app/shared/model/score-calculation.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IScoreCalculationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ScoreCalculationDetail = (props: IScoreCalculationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { scoreCalculationEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
        <h2>
          <Translate contentKey="diga1App.scoreCalculation.detail.title">ScoreCalculation</Translate> [<b>{scoreCalculationEntity.id}</b>]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="userId">
              <Translate contentKey="diga1App.scoreCalculation.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{scoreCalculationEntity.userId}</dd>
          <dt>
            <span id="date">
              <Translate contentKey="diga1App.scoreCalculation.date">Date</Translate>
            </span>
          </dt>
          <dd>
            {scoreCalculationEntity.date ? <TextFormat value={scoreCalculationEntity.date} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="scoreType">
              <Translate contentKey="diga1App.scoreCalculation.scoreType">Score Type</Translate>
            </span>
          </dt>
          <dd>{scoreCalculationEntity.scoreType}</dd>
          <dt>
            <span id="score">
              <Translate contentKey="diga1App.scoreCalculation.score">Score</Translate>
            </span>
          </dt>
          <dd>{scoreCalculationEntity.score}</dd>
        </dl>
        <Button tag={Link} to="/score-calculation" replace className="rounded" color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/score-calculation/${scoreCalculationEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ scoreCalculation }: IRootState) => ({
  scoreCalculationEntity: scoreCalculation.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ScoreCalculationDetail);
