import { IConversationNotification } from 'app/shared/model/conversation-notification.model';

export interface IConversationCreation {
  id?: number;
  icdCodeId?: number;
  conversationTypeId?: number;
  triggersWhen?: string;
  startAfter?: string;
  startTime?: string;
  conversationNotifications?: IConversationNotification[];
}

export const defaultValue: Readonly<IConversationCreation> = {};
