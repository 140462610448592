import React from 'react';
import { Translate } from 'react-jhipster';
import { Row, Col, Button } from 'reactstrap';

import './patient-welcome-page.css';
import { generatePDF, PDFFILES, privacyPolicyDataDigaPageFiles, privacyPolicyDataPageFiles } from 'app/pages/medical-assistant-dashboard/shard/pdfUtils';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

export interface IPatientWelcomePageProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

const PatientWelcomePage = (props: IPatientWelcomePageProps) => {

    const openInstallationGuide = () => {
        const url = props.locale === 'de'
            ? PDFFILES.INSTALLATION_GUIDE_DE
            : props.locale === 'en'
                ? PDFFILES.INSTALLATION_GUIDE_EN
                : props.locale === 'tr'
                    ? PDFFILES.INSTALLATION_GUIDE_TR
                    : undefined;
        if (url) {
            generatePDF(url);
        }
    };

    return (
        <Row className="home__body">
            <Row className="home__body--content">
                <Col md="12" lg="5">
                    <div className="home__picture"><img src="../../../content/images/home-page-pic.jpg" /></div>
                </Col>
                <Col>
                    <h1 className="home__title">
                        <Translate contentKey="home.header.title">Activated</Translate>
                    </h1>
                    <h2 className="home__subtitle">
                        <Translate contentKey="home.header.subtitle">Activated</Translate>
                    </h2>
                    <div className="home__description">
                        <p>
                            <Translate contentKey="diga1App.patient.welcomePage.text1">Activated</Translate>
                        </p>
                        <p>
                            <Translate contentKey="diga1App.patient.welcomePage.text2">Activated</Translate>
                        </p>
                        <p>
                            <Translate contentKey="diga1App.patient.welcomePage.text3">Activated</Translate>
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="home__body--content">
                <Col xs={{ size: 12, order: 1, offset: 0 }} sm={{ size: 8, order: 1, offset: 3 }}>
                    <div className="home__bottom-area">
                        <p>
                            <Translate contentKey="diga1App.patient.welcomePage.text4">Mobile Versions</Translate>
                        </p>
                        <p>
                            <Translate contentKey="home.header.mobile-versions">Mobile Versions</Translate>
                        </p>

                        <a href="https://play.google.com/store/apps/details?id=de.diga1" target="_blank" rel="noopener noreferrer">
                            <img id="android-logo" src="../../../content/images/google-play-badge.png" />
                        </a>
                        <a href="https://apps.apple.com/de/app/diga1/id1542082111" target="_blank" rel="noopener noreferrer">
                            <img id="apple-logo" src="../../../content/images/apple-logo.svg" />
                        </a>
                        <br />
                        <br />
                        <p>
                            <Translate contentKey="diga1App.patient.welcomePage.text5">Mobile Versions</Translate>
                        </p>
                        <p>
                            <Translate contentKey="diga1App.patient.welcomePage.text6">Mobile Versions</Translate>
                        </p>
                        <Row className="home__toggler">
                            <Col>
                                <Button
                                    color="#fdbb38"
                                    id="installationGuideToggler"
                                    style={{ marginBottom: '1rem' }}
                                    onClick={openInstallationGuide}
                                >
                                    <Translate contentKey='fileNames.installationGuide' />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>


            <Row className="home__documents">
                <Col>
                    <a onClick={() => generatePDF(privacyPolicyDataPageFiles?.[props.locale])} style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" ><Translate contentKey='home.link.dataProtection' /></a>
                </Col>
            </Row>
            <Row className="home__documents">
                <Col>
                    <a onClick={() => generatePDF(privacyPolicyDataDigaPageFiles?.[props.locale])} style={{ textDecoration: "underline" }} target="_blank" rel="noopener noreferrer" ><Translate contentKey='home.link.dataProtectionDiga' /></a>
                </Col>
            </Row>
            <Row className="home__documents">
                <Col />
                <Col sm={{ span: 6 }}>
                    <p>
                        <div>docjo GmbH</div>
                        <div> Lichtstrasse 35 </div>
                        <div> CH-4056 Basel, Schweiz </div>
                        <div> Handelsregisteramt: Kanton Basel-Stadt </div>
                        <div> UID: CHE-295.966.793 </div>
                        <div> Geschäftsführung: Dr. Ulrich Mühlner </div>
                        <div> Kontakt: support@docjo.de </div>
                    </p>
                </Col>
                <Col />
            </Row>

            <span className="home__copyright">© 2022 by docjo GmbH</span>
        </Row>
    );
};


const mapStateToProps = ({ locale }: IRootState) => ({
    locale: locale.currentLocale
});

const mapDispatchToProps = {};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientWelcomePage);
