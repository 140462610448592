import { Moment } from 'moment';

export interface IUserTask {
  id?: number;
  userId?: number;
  taskId?: number;
  appointmentId?: number;
  icdCodeId?: number;
  startTime?: string;
  endTime?: string;
  status?: number;
}

export const defaultValue: Readonly<IUserTask> = {};
