import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, getSortState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClinicsByCriteria as getClinicsOnSearch } from 'app/entities/clinic/clinic.reducer'

import { IRootState } from 'app/shared/reducers';
import { getEntities, getEntitiesWithFilters } from './clinic.reducer';
import { IClinic } from 'app/shared/model/clinic.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import SearchInputText from 'app/shared/input-text/search-input-text/search-input-text';

export interface IClinicProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const Clinic = (props: IClinicProps) => {
  const [filters, setFilters] = useState({
    name: '',
    email: '',
    phoneNumber: ''
  });
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const [searchValue, setSearchValue] = useState(null);

  const getAllEntities = () => {
    const query = Object.keys(filters).filter((k) => filters[k] !== '').map((k) => `${k}.contains=${filters[k]}`).join('&');
    props.getEntitiesWithFilters(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, query);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const onSearch = () => {
    if (searchValue && searchValue !== null && searchValue !== '')
      props.getClinicsOnSearch(searchValue);
    else getAllEntities();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchValue && searchValue !== '') {
      onSearch()
    }
  }

  useEffect(() => {
    sortEntities();
  }, [filters.name, filters.email, filters.phoneNumber]);

  const { clinicList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="clinic-heading">
        <Translate contentKey="diga1App.clinic.home.title">Clinics</Translate>
        <Link to={`${match.url}/new`} className="btn btn-outline-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.clinic.home.createLabel">Create new Clinic</Translate>
        </Link>
      </h2>
      <Row style={{ "width": "50%" }}>
        <input className="form-control form-control-sm w-75"
          style={{ borderRadius: '100px' }}
          placeholder={translate("global.form.search")}
          aria-label="Search"
          onChange={(event) => setSearchValue(event.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <Button style={{ "marginLeft": "1rem" }} className="tableButton" onClick={() => onSearch()}>
          <Translate contentKey="maDashboard.searchButton">Open</Translate>
        </Button>
      </Row>
      <br />
      <div className="table-responsive">
        {(
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="diga1App.clinic.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        name: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('street')}>
                  <Translate contentKey="diga1App.clinic.street">Street</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('houseNumber')}>
                  <Translate contentKey="diga1App.clinic.houseNumber">House Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('city')}>
                  <Translate contentKey="diga1App.clinic.city">City</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('zipCode')}>
                  <Translate contentKey="diga1App.clinic.zipCode">Zip Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('registrationNumber')}>
                  <Translate contentKey="diga1App.clinic.registrationNumber">Registration Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('email')}>
                  <Translate contentKey="diga1App.clinic.email">Email</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        email: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('phoneNumber')}>
                  <Translate contentKey="diga1App.clinic.phoneNumber">Phone Number</Translate> <FontAwesomeIcon icon="sort" />
                  <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        phoneNumber: text
                      })
                    }} 
                    column
                  />
                </th>
                <th className="hand" onClick={sort('preferredCommunicationChannel')}>
                  <Translate contentKey="diga1App.clinic.preferredCommunicationChannel">Preferred Communication Channel</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('mainContactEmail')}>
                  <Translate contentKey="diga1App.clinic.mainContactEmail">Main Contact Email</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('verificationStatus')}>
                  <Translate contentKey="diga1App.clinic.verificationStatus">Verification Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('urlUuid')}>
                  <Translate contentKey="diga1App.clinic.urlUuid">Url Uuid</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('authorization')}>
                  <Translate contentKey="diga1App.clinic.authorization">Authorization</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                !loading && clinicList.length === 0 && (
                  <tr>
                    <td colSpan={15}>
                      <div className="alert alert-warning">
                        <Translate contentKey="diga1App.clinic.home.notFound">No Clinics found</Translate>
                      </div>
                    </td>
                  </tr>
                )
              }
              {clinicList.map((clinic, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${clinic.id}`} color="link" size="sm">
                      {clinic.id}
                    </Button>
                  </td>
                  <td>{clinic.name}</td>
                  <td>{clinic.street}</td>
                  <td>{clinic.houseNumber}</td>
                  <td>{clinic.city}</td>
                  <td>{clinic.zipCode}</td>
                  <td>{clinic.registrationNumber}</td>
                  <td>{clinic.email}</td>
                  <td>{clinic.phoneNumber}</td>
                  <td>{clinic.preferredCommunicationChannel}</td>
                  <td>{clinic.mainContactEmail}</td>
                  <td>{clinic.verificationStatus}</td>
                  <td>{clinic.urlUuid}</td>
                  <td>{clinic.authorization}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${clinic.id}`}
                        outline
                        className="mr-1"
                        color="success"
                        size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${clinic.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        outline
                        className="rounded mr-1"
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      {props.isAdmin && (
                        <Button
                          tag={Link}
                          to={`${match.url}/${clinic.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          outline
                          className="rounded mr-1 "
                          color="danger"
                          size="sm"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      {props.totalItems ? (
        <div className={clinicList && clinicList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ authentication, clinic }: IRootState) => ({
  clinicList: clinic.entities,
  loading: clinic.loading,
  totalItems: clinic.totalItems,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN])
});

const mapDispatchToProps = {
  getEntities,
  getClinicsOnSearch,
  getEntitiesWithFilters
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Clinic);
