import { Moment } from 'moment';
import { IClinic } from 'app/shared/model/clinic.model';
import { IHealthcareProfessional } from 'app/shared/model/healthcare-professional.model';
import { IPatient } from 'app/shared/model/patient.model';
import { IIcdCode } from 'app/shared/model/icd-code.model';

export interface IAppointment {
  id?: number;
  title?: string;
  date?: string;
  time?: string;
  confirmed?: string;
  clinic?: IClinic;
  healthcareProfessional?: IHealthcareProfessional;
  patient?: IPatient;
  icdCode?: IIcdCode;
  realm?: string;
}

export const defaultValue: Readonly<IAppointment> = {};
