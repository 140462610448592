import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, getEntitiesWithFilters } from './user-task.reducer';
import { IUserTask } from 'app/shared/model/user-task.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import SearchInputText from 'app/shared/input-text/search-input-text/search-input-text';

export interface IUserTaskProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const UserTask = (props: IUserTaskProps) => {
  const [filters, setFilters] = useState({
    userId: '',
  });
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    const query = Object.keys(filters).filter((k) => filters[k] !== '').map((k) => {
      if (k === 'userId') {
        return `${k}.equals=${filters[k]}`
      }
      return `${k}.contains=${filters[k]}`
    }).join('&');
    props.getEntitiesWithFilters(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, query);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  useEffect(() => {
    sortEntities();
  }, [filters.userId]);

  const { userTaskList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="user-task-heading">
        <Translate contentKey="diga1App.userTask.home.title">User Tasks</Translate>
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="diga1App.userTask.home.createLabel">Create new User Task</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        <Table responsive>
          <thead>
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('userId')}>
                <Translate contentKey="diga1App.userTask.userId">User Id</Translate> <FontAwesomeIcon icon="sort" />
                <SearchInputText
                    onSearch={(text) => {
                      setFilters({
                        ...filters,
                        userId: text
                      })
                    }} 
                    column
                    type="number"
                  />
              </th>
              <th className="hand" onClick={sort('taskId')}>
                <Translate contentKey="diga1App.userTask.taskId">Task Id</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('appointmentId')}>
                <Translate contentKey="diga1App.userTask.appointmentId">Appointment Id</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('icdCodeId')}>
                <Translate contentKey="diga1App.userTask.icdCodeId">Icd Code Id</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('startTime')}>
                <Translate contentKey="diga1App.userTask.startTime">Start Time</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('endTime')}>
                <Translate contentKey="diga1App.userTask.endTime">End Time</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('status')}>
                <Translate contentKey="diga1App.userTask.status">Status</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {  
              !loading && userTaskList.length === 0 && (
                <tr>
                  <td colSpan={9}>
                  <div className="alert alert-warning">
                    <Translate contentKey="diga1App.userTask.home.notFound">No User Tasks found</Translate>
                  </div>
                  </td>
                </tr>
              )
            }
            {userTaskList.map((userTask, i) => (
              <tr key={`entity-${i}`}>
                <td>
                  <Button tag={Link} to={`${match.url}/${userTask.id}`} color="link" size="sm">
                    {userTask.id}
                  </Button>
                </td>
                <td>{userTask.userId}</td>
                <td>{userTask.taskId}</td>
                <td>{userTask.appointmentId}</td>
                <td>{userTask.icdCodeId}</td>
                <td>{userTask.startTime ? <TextFormat type="date" value={userTask.startTime} format={APP_DATE_FORMAT} /> : null}</td>
                <td>{userTask.endTime ? <TextFormat type="date" value={userTask.endTime} format={APP_DATE_FORMAT} /> : null}</td>
                <td>{userTask.status === 1 ? translate('diga1App.userTask.statusList.new') :
                      userTask.status === 2 ? translate('diga1App.userTask.statusList.cancelled') :
                      userTask.status === 3 ? translate('diga1App.userTask.statusList.done') : ''}</td>
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/${userTask.id}`} color="info" size="sm">
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.view">View</Translate>
                      </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`${match.url}/${userTask.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`${match.url}/${userTask.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="danger"
                      size="sm"
                    >
                      <FontAwesomeIcon icon="trash" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {props.totalItems ? (
        <div className={userTaskList && userTaskList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ userTask }: IRootState) => ({
  userTaskList: userTask.entities,
  loading: userTask.loading,
  totalItems: userTask.totalItems,
});

const mapDispatchToProps = {
  getEntities,
  getEntitiesWithFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserTask);
