import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserTip from './user-tip';
import UserTipDetail from './user-tip-detail';
import UserTipUpdate from './user-tip-update';
import UserTipDeleteDialog from './user-tip-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserTipUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserTipUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserTipDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserTip} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UserTipDeleteDialog} />
  </>
);

export default Routes;
