import { Moment } from 'moment';
import { IClinic } from 'app/shared/model/clinic.model';

export interface ISelfRegistration {
  id?: number;
  firstName?: string;
  lastName?: string;
  gender?: string;
  email?: string;
  mobileNumber?: string;
  langKey?: string;
  birthdate?: string;
  reason?: string;
  clinics?: IClinic[];
}

export const defaultValue: Readonly<ISelfRegistration> = {};
