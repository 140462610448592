import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity, getConversationCatalogUniqueTypes } from './conversation-creation.reducer';
import { getEntities as getEntitiesForICDCodes } from '../icd-code/icd-code.reducer';
import { IConversationCreation } from 'app/shared/model/conversation-creation.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { isArray } from 'lodash';

export interface IConversationCreationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ConversationCreationDetail = (props: IConversationCreationDetailProps) => {
  useEffect(() => {
    props.getConversationCatalogUniqueTypes();
    props.getEntitiesForICDCodes();
    props.getEntity(props.match.params.id);
  }, []);

  const { conversationCreationEntity, conversationTypes, icdCodes } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
        <h2>
          <Translate contentKey="diga1App.conversationCreation.detail.title">ConversationCreation</Translate> [
          <b>{conversationCreationEntity.id}</b>]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="icdCodeId">
              <Translate contentKey="diga1App.conversationCreation.icdCodeId">Icd Code Id</Translate>
            </span>
          </dt>
          <dd>
            {isArray(icdCodes) ? (
              icdCodes.find(c => c.id === conversationCreationEntity.icdCodeId)?.code ?? (
                <Translate contentKey="diga1App.conversationCreation.allIcdCodes">All ICD codes</Translate>
              )
            ) : (
              <Translate contentKey="diga1App.conversationCreation.allIcdCodes">All ICD codes</Translate>
            )}
          </dd>
          <dt>
            <span id="conversationTypeId">
              <Translate contentKey="diga1App.conversationCreation.conversationTypeId">Conversation Type Id</Translate>
            </span>
          </dt>
          <dd>{conversationTypes?.[conversationCreationEntity.conversationTypeId] ?? '-'}</dd>
          <dt>
            <span id="triggersWhen">
              <Translate contentKey="diga1App.conversationCreation.triggersWhen">Triggers When</Translate>
            </span>
          </dt>
          <dd>{conversationCreationEntity.triggersWhen}</dd>
          <dt>
            <span id="startsAfter">
              <Translate contentKey="diga1App.conversationCreation.startsAfter">Starts After</Translate>
            </span>
          </dt>
          <dd>{conversationCreationEntity.startAfter}</dd>
          <dt>
            <Translate contentKey="diga1App.conversationCreation.conversationNotification">Conversation Notification</Translate>
          </dt>
          <dd>
            {conversationCreationEntity.conversationNotifications
              ? conversationCreationEntity.conversationNotifications.map((val, i) => (
                  <span key={val.id}>
                    <a>
                      {`${val.id}_${val.channel}_${val.startAfter}_${val.startTime}_${val.recurrence}_${val.occurrences}`}
                    </a>
                    {conversationCreationEntity.conversationNotifications &&
                    i === conversationCreationEntity.conversationNotifications.length - 1
                      ? ''
                      : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <span id="startTime">
              <Translate contentKey="diga1App.conversationCreation.startTime">Start Time</Translate>
            </span>
          </dt>
          <dd>{conversationCreationEntity.startTime}</dd>
        </dl>
        <Button tag={Link} to="/conversation-creation" replace className="rounded" color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/conversation-creation/${conversationCreationEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ conversationCreation, icdCode }: IRootState) => ({
  conversationCreationEntity: conversationCreation.entity,
  conversationTypes: conversationCreation.uniqueConversationTypeObject,
  icdCodes: icdCode.entities,
});

const mapDispatchToProps = {
  getEntity,
  getConversationCatalogUniqueTypes,
  getEntitiesForICDCodes,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConversationCreationDetail);
