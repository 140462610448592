import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';
import PatientAppointmentDetailsRow from './ma-appointment-row';
import { IRootState } from 'app/shared/reducers';
import { getAppointmentsByQuery } from '../ma-dashboard.reducer';
import { Translate } from 'react-jhipster';
import '../ma-pages.scss';
import moment from 'moment';

interface IMedicalAssistantAppointmentsTable extends StateProps, DispatchProps {
    selectedDoctorId: number;
}

const MedicalAssistantAppointmentsTable = (props: IMedicalAssistantAppointmentsTable) => {
    const [doctorsAppointments, setDoctorsAppointments] = useState(null);

    const datesAreOnSameDay = (first: Date, second: Date) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();

    useEffect(() => {

        let newDoctorsAppointment = null;
        if (props.maSelectedClinicAppointments && props.maSelectedClinicAppointments.length > 0) {
            newDoctorsAppointment =
                props.maSelectedClinicAppointments
                    .filter(
                        appointment => {
                            moment(appointment.date, 'DD-MM-YYYY');
                            const date = new Date(moment.tz(appointment.date, 'Europe/Berlin').toDate());
                            return appointment.healthcareProfessional
                                &&
                                appointment.healthcareProfessional.id === props.selectedDoctorId
                                &&
                                datesAreOnSameDay(new Date(appointment.date), props.selectedDate);
                        }
                    );

        }
        setDoctorsAppointments(newDoctorsAppointment);

    }, [props.selectedDoctorId, props.selectedDate, props.selectedClinicId, props.maSelectedClinicAppointments]);


    return (
        <div className="page-container">
            {
                doctorsAppointments && doctorsAppointments.length > 0
                    ?
                    <Table responsive>
                        <thead>
                            <tr style={{ textAlign: "center" }}>
                                <th>
                                    <Translate contentKey="diga1App.patient.firstName">First Name</Translate>
                                </th>
                                <th>
                                    <Translate contentKey="diga1App.patient.lastName">Last Name</Translate>
                                </th>
                                <th>
                                    <Translate contentKey="diga1App.appointment.time">Appointment Time</Translate>
                                </th>
                                <th>
                                    <Translate contentKey="diga1App.appointment.status">Confirmed</Translate>
                                </th>
                                <th>
                                    <Translate contentKey="diga1App.patient.realm">App</Translate>
                                </th>
                                <th>
                                    <Translate contentKey="diga1App.patient.mobileAppInstalled">App Status</Translate>
                                </th>
                                <th>
                                    <Translate contentKey="diga1App.patient.report">Report</Translate>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                doctorsAppointments
                                    .map((appointment) =>
                                        <PatientAppointmentDetailsRow key={appointment.id} isShowDate={false} patientAppointmentDetails={appointment}></PatientAppointmentDetailsRow>)
                            }
                        </tbody>

                        
                    </Table >

                    :
                    (<div><Translate contentKey="diga1App.appointment.maDashboard.notFound">No appointments found</Translate></div>)
            }
        </div>);

};


const mapStateToProps = (storeState: IRootState) => ({
    selectedDate: storeState.maDashboardReducer.selectedDate,
    selectedClinicId: storeState.medicalAssistant.maClinicId,
    maSelectedClinicAppointments: storeState.maDashboardReducer.maSelectedClinicAppointments,
    currentLocale: storeState.locale.currentLocale
});

const mapDispatchToProps = {
    getAppointmentsByQuery
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;


export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantAppointmentsTable);