import React, { useState } from 'react';
import { Button, Row } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';

const SearchInputText = ({ onSearch, type='text', column = false }) => {
    const [search, setSearch] = useState('');
    return (
        <Row style={{ "width": column ? "100%" : "50%", paddingBottom: 12 }}>
            <input
                className={`form-control form-control-sm ${column ? 'w-100' : 'w-75'}`}
                style={{ borderRadius: '100px', minWidth: 150, marginTop: 4 }}
                placeholder={translate("global.form.search")}
                aria-label="Search"
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => {
                    event.stopPropagation();
                    setSearch(event.target.value)
                }}
                onKeyUp={(event: any) => {
                    event.stopPropagation();
                    setSearch(event.target.value)
                    onSearch(event.target.value)
                }}
                value={search}
                type={type}
            />
            {!column && (
                <Button style={{ "marginLeft": "1rem" }} className="tableButton" onClick={() => onSearch(search)}>
                    <Translate contentKey="maDashboard.searchButton">Open</Translate>
                </Button>
            )}
        </Row>
    )
}

export default SearchInputText;