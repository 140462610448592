export interface INotificationSettings {
  id?: number;
  name?: string;
  notificationTemplateId?: number;
  channel?: string;
  rule?: string;
  notificationCategory?: string;
}

export const defaultValue: Readonly<INotificationSettings> = {};
