import { Moment } from 'moment';
import { ActivityTypeEnum } from 'app/shared/model/enumerations/activity-type-enum.model';

export interface IUserActivity {
  id?: number;
  userId?: number;
  activityTypeId?: ActivityTypeEnum;
  activityTime?: string;
  activitySource?: string;
}

export const defaultValue: Readonly<IUserActivity> = {};
