import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './task-catalog.reducer';
import { ITaskCatalog } from 'app/shared/model/task-catalog.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ITaskCatalogDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const TaskCatalogDetail = (props: ITaskCatalogDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { taskCatalogEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="diga1App.taskCatalog.detail.title">TaskCatalog</Translate> [<b>{taskCatalogEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="icdCodeId">
              <Translate contentKey="diga1App.taskCatalog.icdCodeId">Icd Code Id</Translate>
            </span>
          </dt>
          <dd>{taskCatalogEntity.icdCodeId}</dd>
          <dt>
            <span id="task">
              <Translate contentKey="diga1App.taskCatalog.task">Task</Translate>
            </span>
          </dt>
          <dd>{taskCatalogEntity.task}</dd>
          <dt>
            <span id="action">
              <Translate contentKey="diga1App.taskCatalog.action">Action</Translate>
            </span>
          </dt>
          <dd>{taskCatalogEntity.action}</dd>
        </dl>
        <Button tag={Link} to="/task-catalog" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/task-catalog/${taskCatalogEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ taskCatalog }: IRootState) => ({
  taskCatalogEntity: taskCatalog.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TaskCatalogDetail);
