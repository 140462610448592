import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './user-device-info.reducer';
import { IUserDeviceInfo } from 'app/shared/model/user-device-info.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IUserDeviceInfoDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const UserDeviceInfoDetail = (props: IUserDeviceInfoDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { userDeviceInfoEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>
        <h2>
          <Translate contentKey="diga1App.userDeviceInfo.detail.title">UserDeviceInfo</Translate> [<b>{userDeviceInfoEntity.id}</b>]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="userId">
              <Translate contentKey="diga1App.userDeviceInfo.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{userDeviceInfoEntity.userId}</dd>
          <dt>
            <span id="deviceId">
              <Translate contentKey="diga1App.userDeviceInfo.deviceId">Device Id</Translate>
            </span>
          </dt>
          <dd>{userDeviceInfoEntity.deviceId}</dd>
          <dt>
            <span id="token">
              <Translate contentKey="diga1App.userDeviceInfo.token">Token</Translate>
            </span>
          </dt>
          <dd>{userDeviceInfoEntity.token}</dd>
        </dl>
        <Button tag={Link} to="/user-device-info" className="rounded" replace color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-device-info/${userDeviceInfoEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ userDeviceInfo }: IRootState) => ({
  userDeviceInfoEntity: userDeviceInfo.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserDeviceInfoDetail);
