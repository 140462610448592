import React, { useEffect, useState } from 'react';
import { Button, Col, Alert, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { Translate, translate } from 'react-jhipster';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { saveAccountSettings, reset } from '../../../modules/account/settings/settings.reducer';
import { getEntity, updateEntity, getMedicalAssistantByAccountId } from '../../../entities/medical-assistant/medical-assistant.reducer';
import { RouteComponentProps } from 'react-router-dom';
import { IMedicalAssistant } from 'app/shared/model/medical-assistant.model';

export interface IMedicalAssistantEditPersonalDetailsProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string; }> { }

export const MedicalAssistantEditPersonalDetails = (props: IMedicalAssistantEditPersonalDetailsProps) => {
    useEffect(() => {
        props.getSession();
        
        props.account.id && props.getMedicalAssistantByAccountId(props.account.id);

        return () => {
            props.reset();
        };
    }, [props.account.id]);

    const isFormNotChanged = (newValues) => {
        const existing = props.medicalAssistantEntity;
        return existing.firstName === newValues.firstName && existing.lastName === newValues.lastName && existing.email === newValues.email;
    };

    const handleValidSubmit = (event, values) => {
        const entity = {
            ...props.medicalAssistantEntity,
            ...values
        }
        if (isFormNotChanged(values)) {
            return;
        };
        props.updateEntity(entity);
        event.persist();      
    };

    return (
        <div>
            <Row className="justify-content-center">
                <Col md="8">
                    <h2 id="settings-title">
                        <Translate contentKey={"diga1App.medicalAssistant.editPersonalDetails"} interpolate={{ firstName: props.medicalAssistantEntity.firstName }}></Translate>
                    </h2>
                    <AvForm id="settings-form" onValidSubmit={handleValidSubmit}>
                        {/* First name */}
                        <AvField
                            id="medical-assistant-firstName"
                            type="text"
                            name="firstName"
                            label={translate('settings.form.firstname')}
                            validate={{
                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                                
                            }}
                            value={props.medicalAssistantEntity.firstName}
                        />
                        {/* Last name */}
                        <AvField
                            id="medical-assistant-lastName"
                            type="text"
                            name="lastName"
                            label={translate('settings.form.lastname')}
                            validate={{
                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                            }}
                            value={props.medicalAssistantEntity.lastName}
                        />
                        {/* Email */}
                        <AvField
                            id="medical-assistant-email"
                            type="email"
                            name="email"
                            label={translate('global.form.email.label')}
                            validate={{
                                required: { value: true, errorMessage: translate('entity.validation.required') }
                            }}
                            value={props.medicalAssistantEntity.email}
                        />
                        <Button color="success" type="submit">
                            <Translate contentKey="settings.form.button">Save</Translate>
                        </Button>
                    </AvForm>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    account: storeState.authentication.account,
    isAuthenticated: storeState.authentication.isAuthenticated,
    medicalAssistantEntity: storeState.medicalAssistant.entity
});

const mapDispatchToProps = { getSession, saveAccountSettings, reset, getEntity, updateEntity, getMedicalAssistantByAccountId };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MedicalAssistantEditPersonalDetails);
