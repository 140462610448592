import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import "../ma-pages.scss";
import Select from 'react-select';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField, AvFeedback } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster'; import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { createEntity as createAppointment, reset } from 'app/entities/appointment/appointment.reducer'
import { updateHideCalendar, getPatientsByQuery } from '../ma-dashboard.reducer';
import { isSafari } from 'react-device-detect';
import { IAppointment } from 'app/shared/model/appointment.model';
import { useHistory } from 'react-router-dom';
import { ROUTING } from 'app/shared/constants/routing';
import { IPatient } from 'app/shared/model/patient.model';
import { getDoctorsByQuery as getDoctorsByClinicId } from 'app/pages/medical-assistant-dashboard/ma-dashboard.reducer'
import { getEntityByUser } from 'app/entities/medical-assistant/medical-assistant.reducer';
import { reset as resetICDCodes, getEntitiesByRealm as getIcdCodesByApp } from 'app/entities/icd-code/icd-code.reducer';
import { REALM } from 'app/shared/constants/realm.model';
import { getMedicalAssistantByAccountId } from 'app/entities/medical-assistant/medical-assistant.reducer'
import { resetPatients, getAllPatientsByClinicId as getPatients } from 'app/pages/medical-assistant-dashboard/ma-patient-section.reducer'

export interface IMaDashAppointmentCreateUpdateProps extends StateProps, DispatchProps { }

const NOW = new Date().toJSON().split('T')[0];
const MAX_TIME_SINCE_TODAY = 10;

const MaDashCreateAppointment = (props: IMaDashAppointmentCreateUpdateProps) => {
    const history = useHistory();

    const [appointmentsPatient, setPatient] = useState({} as IPatient);
    const [notification, setNotification] = useState(false);

    const handleClose = () => {
        if (props.updateSuccess) {
            props.reset();
            history.push(ROUTING.MFA_HOME_PAGE)
        }
    };

    useEffect(() => {
        props.reset();
        props.resetPatients();
        props.resetICDCodes();
        props.updateHideCalendar(true);
    }, []);

    useEffect(() => {
        if (props.account !== null)
            props.getMedicalAssistantByAccountId(props.account.id);
    }, [props.account])

    useEffect(() => {
        if (props.selectedClinic && props.selectedClinic.length > 0 !== null) {
            props.getDoctorsByClinicId(`clinicId.equals=${props.selectedClinic[0].id}`);
            props.getPatients(props.selectedClinic[0].id);
        }
    }, [props.selectedClinic && props.selectedClinic.length > 0])

    useEffect(() => {
        if (appointmentsPatient.realm === REALM.DIGA1)
            props.getIcdCodesByApp(REALM.DIGA1_APP)
        if (appointmentsPatient.realm === REALM.VELARIA)
            props.getIcdCodesByApp(REALM.VELARIA_APP)
    }, [appointmentsPatient.realm]);

    useEffect(() => {
        if (props.updateSuccess) {
            handleClose();
        }
    }, [props.updateSuccess]);

    const getIcdCodesByAppOptions = () => {
        return props.icdCodes
            ? props.icdCodes.map(otherEntity => (
                <option value={otherEntity.id} key={otherEntity.id}>
                    {`${otherEntity.code}  -   ${otherEntity.description}`}
                </option>
            )).reverse()
            : ''
    }

    const saveEntity = (event, errors, { appointment, selectedDoctorId, selectedPatientId }) => {
        if (errors.length === 0) {
            let appointmentToCreate: IAppointment = {}
            const invitationFromDoctor = props.maSelectedClinicDoctors.filter(doctor => doctor.id.toString() === selectedDoctorId)[0];

            const momentObj = moment(appointment.date + appointment.time, 'YYYY-MM-DDLT');

            if (appointment.icdCode?.id !== undefined) {
                appointmentToCreate = {
                    ...appointmentToCreate,
                    icdCode: { id: appointment.icdCode.id }
                }
            }

            appointment.date = moment.tz(momentObj.format('YYYY-MM-DDTHH:mm:ss'), 'Europe/Berlin');
            appointmentToCreate.date = appointment.date

            appointmentToCreate.clinic = props.selectedClinic[0];
            appointmentToCreate.confirmed = "Not Confirmed";
            appointmentToCreate.healthcareProfessional = invitationFromDoctor;
            appointmentToCreate.patient = appointmentsPatient;
            if (!_.isEmpty(appointmentToCreate.patient)) {
                setNotification(false)
                props.createAppointment(appointmentToCreate);
            } else {
                setNotification(true)
            }
        }
    };

    const getMaxDateForAppointment = () => {
        const fiveYearsAgoFromNow = new Date();
        fiveYearsAgoFromNow.setFullYear(fiveYearsAgoFromNow.getFullYear() + MAX_TIME_SINCE_TODAY);

        return fiveYearsAgoFromNow.toJSON().split('T')[0];
    };

    const onSelectPatient =
        (event) => {
            setPatient(event.value)
        };

    return (
        <Col md={5} className="page-container">
            <Row>
                <Col>
                    <Row style={{ margin: "1rem" }}>
                        <h4><Translate contentKey={"diga1App.appointment.maDashboard.scheduleAppointment"}>Schedule Appointment</Translate></h4>
                    </Row>
                    {props.loading ? (
                        <p>Loading...</p>
                    ) : (
                        <AvForm onSubmit={saveEntity}>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label style={notification ? { color: '#FF4136' } : undefined} for="appointment-patient">
                                            <Translate contentKey="diga1App.patient.home.title">Patient</Translate>
                                        </Label>
                                        <Select
                                            options={props.maSelectedClinicPatients ? props.maSelectedClinicPatients.map(entity => ({
                                                label: `${entity.firstName} ${entity.lastName}`,
                                                value: entity
                                            })
                                            ) : undefined}
                                            noOptionsMessage={() => translate('maDashboard.general.notfound')}
                                            onChange={(e) => onSelectPatient(e)}
                                            placeholder={translate('maDashboard.appointmentArea.patientplaceholder')}
                                            required
                                        >
                                        </Select>
                                        {notification ?
                                            <div style={{ color: '#FF4136', fontSize: '80%' }}>
                                                <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                            </div> : undefined
                                        }
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="appointment-healthcareProfessional">
                                            <Translate contentKey="diga1App.appointment.healthcareProfessional">Healthcare Professional</Translate>
                                        </Label>
                                        <AvInput
                                            id="appointment-healthcareProfessional"
                                            type="select"
                                            className="form-control"
                                            name="selectedDoctorId"
                                            value={
                                                props.maSelectedClinicDoctors &&
                                                props.maSelectedClinicDoctors[0] &&
                                                props.maSelectedClinicDoctors[0].id &&
                                                props.maSelectedClinicDoctors[0].id.toString()
                                            }
                                            required
                                        >
                                            {props.maSelectedClinicDoctors
                                                ? props.maSelectedClinicDoctors.map(otherEntity => (
                                                    <option value={`${otherEntity.id}`} key={otherEntity.id}>
                                                        {`${otherEntity.salutation} ${otherEntity.title} ${otherEntity.firstName} ${otherEntity.lastName}`}
                                                    </option>
                                                ))
                                                : ''}
                                        </AvInput>
                                        <AvFeedback>
                                            <Translate contentKey="entity.validation.required">This field is required.</Translate>
                                        </AvFeedback>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <AvGroup>
                                                <Label for="appointment-icdCode">
                                                    <Translate contentKey="diga1App.appointment.icdCode">Icd Code</Translate>
                                                </Label>
                                                <AvInput id="appointment-icdCode" type="select" className="form-control" name="appointment.icdCode.id"
                                                    value={props.icdCodes ? props.icdCodes[props.icdCodes.length - 1]?.id : ''}>
                                                    {getIcdCodesByAppOptions()}
                                                </AvInput>
                                            </AvGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label id="dateLabel" for="appointment-date">
                                            {!isSafari ?
                                                <Translate contentKey="diga1App.patient.appointmentDate">Appointment Date</Translate> :
                                                <Translate contentKey="diga1App.patient.appointmentWithFormat">Appointment Date (yyyy-mm-dd)</Translate>
                                            }
                                        </Label>
                                        <AvField
                                            id="appointment-date"
                                            type="date"
                                            className="form-control"
                                            name="appointment.date"
                                            min={NOW}
                                            max={getMaxDateForAppointment()}
                                            validate={{
                                                required: { value: true, errorMessage: translate('entity.validation.required') },
                                                dateRange: { start: { value: -1, units: 'days' }, end: { value: 10, units: 'years' } }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col xs={7}>
                                            <AvGroup>
                                                <Label id="timeLabel" for="appointment-time">
                                                    <Translate contentKey="diga1App.appointment.time">Time</Translate>
                                                </Label>
                                                <AvField
                                                    id="appointment-time"
                                                    type="time"
                                                    name="appointment.time"
                                                    placeholder="HH:MM"
                                                    pattern="[0-9]{2}:[0-9]{2}"
                                                    validate={{
                                                        required: { value: true, errorMessage: translate('entity.validation.required') },
                                                        maxLength: { value: 5 },
                                                        pattern: {
                                                            value: '^(20|21|22|23|[01]\\d|\\d)((:[0-5]\\d){1})$',
                                                            errorMessage: translate('global.messages.validate.appointment.invalidTime')
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} />
                                <Col xs={5}>
                                    <div className="wizard-form-in-button">
                                        <Button color="primary" id="save-entity" type="submit" disabled={props.updating}>
                                            <FontAwesomeIcon icon="save" />
                                            &nbsp;
                                            <Translate contentKey="entity.action.send">Send</Translate>
                                        </Button>
                                    </div>
                                </Col>
                                <Col />
                            </Row>

                        </AvForm>
                    )}
                </Col>
            </Row>
        </Col>
    );
};

const mapStateToProps = (storeState: IRootState) => ({
    selectedClinic: storeState.medicalAssistant.entity?.clinics,
    maSelectedClinicDoctors: storeState.maDashboardReducer.maSelectedClinicDoctors,
    maSelectedClinicPatients: storeState.maPatientSection.entities,
    loading: storeState.appointment.loading,
    updating: storeState.appointment.updating,
    updateSuccess: storeState.appointment.updateSuccess,
    currentLocale: storeState.locale.currentLocale,
    icdCodes: storeState.icdCode.entitiesByApp,
    account: storeState.authentication.account
});

const mapDispatchToProps = {
    createAppointment,
    reset,
    updateHideCalendar,
    getPatientsByQuery,
    getEntityByUser,
    getIcdCodesByApp,
    getDoctorsByClinicId,
    getMedicalAssistantByAccountId,
    getPatients,
    resetPatients,
    resetICDCodes
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MaDashCreateAppointment);