import { Moment } from 'moment';
import { IScheduledTaskExecution } from 'app/shared/model/scheduled-task-execution.model';

export interface IScheduledTask {
  id?: number;
  type?: number;
  runTime?: string;
  request?: string;
  state?: number;
  messages?: string;
  scheduledTaskExecutions?: IScheduledTaskExecution[];
}

export const defaultValue: Readonly<IScheduledTask> = {};
