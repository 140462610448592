import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IUserNotifications, defaultValue } from 'app/shared/model/user-notifications.model';

export const ACTION_TYPES = {
  FETCH_USERNOTIFICATIONS_LIST: 'userNotifications/FETCH_USERNOTIFICATIONS_LIST',
  FETCH_USERNOTIFICATIONS: 'userNotifications/FETCH_USERNOTIFICATIONS',
  CREATE_USERNOTIFICATIONS: 'userNotifications/CREATE_USERNOTIFICATIONS',
  UPDATE_USERNOTIFICATIONS: 'userNotifications/UPDATE_USERNOTIFICATIONS',
  DELETE_USERNOTIFICATIONS: 'userNotifications/DELETE_USERNOTIFICATIONS',
  RESET: 'userNotifications/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IUserNotifications>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type UserNotificationsState = Readonly<typeof initialState>;

// Reducer

export default (state: UserNotificationsState = initialState, action): UserNotificationsState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USERNOTIFICATIONS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_USERNOTIFICATIONS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_USERNOTIFICATIONS):
    case REQUEST(ACTION_TYPES.UPDATE_USERNOTIFICATIONS):
    case REQUEST(ACTION_TYPES.DELETE_USERNOTIFICATIONS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_USERNOTIFICATIONS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_USERNOTIFICATIONS):
    case FAILURE(ACTION_TYPES.CREATE_USERNOTIFICATIONS):
    case FAILURE(ACTION_TYPES.UPDATE_USERNOTIFICATIONS):
    case FAILURE(ACTION_TYPES.DELETE_USERNOTIFICATIONS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERNOTIFICATIONS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERNOTIFICATIONS):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_USERNOTIFICATIONS):
    case SUCCESS(ACTION_TYPES.UPDATE_USERNOTIFICATIONS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_USERNOTIFICATIONS):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-notifications';

// Actions

export const getEntities: ICrudGetAllAction<IUserNotifications> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERNOTIFICATIONS_LIST,
    payload: axios.get<IUserNotifications>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntitiesWithFilters = (page?, size?, sort?, filters = '') => {
  let requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  if (filters) {
    requestUrl += `&${filters}`;
  }
  return {
    type: ACTION_TYPES.FETCH_USERNOTIFICATIONS_LIST,
    payload: axios.get<IUserNotifications>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IUserNotifications> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERNOTIFICATIONS,
    payload: axios.get<IUserNotifications>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IUserNotifications> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERNOTIFICATIONS,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IUserNotifications> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USERNOTIFICATIONS,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IUserNotifications> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USERNOTIFICATIONS,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
