import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './user-notifications.reducer';
import { IUserNotifications } from 'app/shared/model/user-notifications.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IUserNotificationsDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const UserNotificationsDetail = (props: IUserNotificationsDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { userNotificationsEntity } = props;
  return (
    <Row>
    <Col>
      <Row className="justify-content-center">
        <Col md={4} sm={8}>

        <h2>
          <Translate contentKey="diga1App.userNotifications.detail.title">UserNotifications</Translate> [<b>{userNotificationsEntity.id}</b>
          ]
        </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={4} sm={8} className="info-box">
        <dl className="jh-entity-details">
          <dt>
            <span id="type">
              <Translate contentKey="diga1App.userNotifications.type">Type</Translate>
            </span>
          </dt>
          <dd>{userNotificationsEntity.type}</dd>
          <dt>
            <span id="date">
              <Translate contentKey="diga1App.userNotifications.date">Date</Translate>
            </span>
          </dt>
          <dd>
            {userNotificationsEntity.date ? <TextFormat value={userNotificationsEntity.date} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="entityType">
              <Translate contentKey="diga1App.userNotifications.entityType">Entity Type</Translate>
            </span>
          </dt>
          <dd>{userNotificationsEntity.entityType}</dd>
          <dt>
            <span id="entityId">
              <Translate contentKey="diga1App.userNotifications.entityId">Entity Id</Translate>
            </span>
          </dt>
          <dd>{userNotificationsEntity.entityId}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="diga1App.userNotifications.status">Status</Translate>
            </span>
          </dt>
          <dd>{userNotificationsEntity.status}</dd>
          <dt>
            <span id="userId">
              <Translate contentKey="diga1App.userNotifications.userId">User Id</Translate>
            </span>
          </dt>
          <dd>{userNotificationsEntity.userId}</dd>
        </dl>
        <Button tag={Link} to="/user-notifications" replace className="rounded" color="success">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-notifications/${userNotificationsEntity.id}/edit`} replace className="rounded" color="danger">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
    </Col>
    </Row>
  );
};

const mapStateToProps = ({ userNotifications }: IRootState) => ({
  userNotificationsEntity: userNotifications.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationsDetail);
